// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { createQuery } from '@platform360/libs/shared-web/query';
import { managedUserIdentify } from '@platform360/security-dashboard/web/api/security';

export type ManagedUserIdentifierQueryVariables = {
    hash: string;
};

export type ManagedUserIdentifierQueryData = PromiseValue<ReturnType<typeof managedUserIdentify>>;

const useManagedUserIdentifierQuery = createQuery<
    ManagedUserIdentifierQueryVariables,
    ManagedUserIdentifierQueryData
>({
    queryName: 'security-dashboard/useManagedUserIdentifierQuery',
    fetcher: managedUserIdentify,
    useQuery,
});

export default useManagedUserIdentifierQuery;
