// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useConsentItems from '@platform360/libs/shared-web/helpers/useConsentItems';
import SignUpForm, { FormValues } from './SignUpForm';
import { getConsentsToSend } from './getConsentsToSend';
import { OperationStatus } from '@platform360/auth/web/components/useOperationStatus';
import { toGeneralError } from '@platform360/libs/shared-web/helpers/apiErrors';
import { useSignUpByInvitation } from '@platform360/auth/web/mutations';

type SignUpByInvitationProps = {
    email: string;
    setOperationStatus: (s: OperationStatus | null) => void;
};

const SignUpByInvitation = ({ email, setOperationStatus }: SignUpByInvitationProps) => {
    const translate = useTranslate('Auth.SignUp.SignUpByInvitation');
    const knownConsents = useConsentItems();

    const handleSuccess = () => {
        const message = translate('invitationSucceed');
        setOperationStatus({ message, isError: false });
    };

    const handleClientError = useCallback(
        (error: unknown) => {
            const message = toGeneralError(error);
            if (message) {
                setOperationStatus({ message, isError: true });
            }
        },
        [setOperationStatus],
    );

    const {
        mutate: signUpUserByInvitation,
        errors,
        isPending: isLoading,
        isSuccess,
    } = useSignUpByInvitation({
        onMutate: () => setOperationStatus(null),
        onError: handleClientError,
        onSuccess: handleSuccess,
    });

    const handleSignUp = ({ privacyPolicyAgreement, email: _, consents, ...rest }: FormValues) => {
        if (!privacyPolicyAgreement) {
            return;
        }

        signUpUserByInvitation({
            ...rest,
            consents: getConsentsToSend(knownConsents, consents),
        });
    };

    // Sign-up success means invitation cannot be retried,
    // so we remove the URL fragment and navigate to the login page.
    if (isSuccess) {
        return <Navigate to={'/auth/login'} />;
    }

    return (
        <SignUpForm
            email={email}
            emailReadOnly
            emailFieldDescription={translate('emailDescription')}
            consentItems={knownConsents}
            onSubmit={handleSignUp}
            isLoading={isLoading}
            errors={errors}
        />
    );
};

export default SignUpByInvitation;
