// Copyright 2024. WebPros International GmbH. All rights reserved.
import { Fragment } from 'react';

const formatInstallationSnippet = (snippet: string) => (
    <>
        {/* Break urls by '/' with <wbr> */}
        {snippet.split(/\//).map((part, index, array) => (
            // eslint-disable-next-line react/no-array-index-key,react/no-array-index-key
            <Fragment key={index}>
                {index > 0 && (
                    <>
                        {'/'}
                        {part !== '' && array[index - 1] !== '' && <wbr />}
                    </>
                )}
                {part}
            </Fragment>
        ))}
    </>
);

export default formatInstallationSnippet;
