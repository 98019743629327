// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ComponentType, createContext, ReactElement, ReactNode } from 'react';
import { Application } from '@platform360/libs/common/application';
import { FormErrors } from '@plesk/ui-library';
import { UseMutationResult } from '@tanstack/react-query/src/types';
import { Branding } from '@platform360/libs/common/branding';
import { Consent } from '@platform360/libs/common/consents';
import { NavigateFunction } from 'react-router-dom';

type LayoutProps = {
    sidebar: ReactElement;
    footer: ReactElement;
    children: ReactElement;
};

type SidebarProps = {
    children: ReactNode;
    logo: ReactNode;
};

type DarkThemeDataAttribute = 'dark' | 'legacy-dark';

/**
 * Copy of {@link import('@platform360/auth/shared/api-contract').SignUpRequest} to manage dependency issues.
 */
type SignUpRequest = {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    consents: Consent[];
    // Auth0 application clientId which callback url redirect to
    clientId?: string;
    redirectUrl?: string;
    acquisitionSource?: string;
    branding?: Branding;
};

type SignUpMutation = (onSuccess: () => void) => Omit<
    UseMutationResult<unknown, unknown, SignUpRequest>,
    'error'
> & {
    errors?: FormErrors;
};

export type FailedLoginHandler = (params: {
    redirectUrl?: string;
    navigate: NavigateFunction;
}) => void;

export type ZendeskChatOptions = {
    apiKey: string;
    department: string;
    title: string;
    welcomeMessage: (name: string) => string;
    concierge: {
        avatar: string;
        title: string;
    };
};

/**
 * Represents the settings for a given Application type,
 * which includes customizations such as layout and UI components.
 */
export type ApplicationSettings = {
    logo: ReactElement;
    logoRightAddon?: ReactElement;
    homePage: {
        isPrivate: boolean;
        component: ReactElement;
    };
    notFoundPage: ReactElement;
    document: {
        darkThemeDataAttribute: DarkThemeDataAttribute;
        title: string;
        favicon: string;
    };
    header: {
        hideDarkThemeSwitcher?: boolean;
        apiTokensEnabled: boolean;
        businessProfileEnabled: boolean;
        globalSearchEnabled: boolean;
        dotsMenuEnabled: boolean;
        mainMenuEnabled: boolean;
        userMenuAddons?: ReactNode[];
        addon?: ReactElement;
        baseUrl: string;
    };
    footer: ReactElement;
    authLayout: ComponentType<LayoutProps>;
    authSidebar: ComponentType<SidebarProps>;
    auth: {
        signUpMutation: SignUpMutation;
        branding: Branding;

        /** Used as an identifier for the marketing companies. */
        acquisitionSource?: string;

        /** SignUp form terms of use checkbox link. */
        termsOfUseLink: ReactElement;
        preferredSignup: boolean;
        areConsentsShown: boolean;
        signUpEmailFieldDescriptionShown: boolean;
        signUpVerificationDrawerAddon?: ReactElement;
        shouldNotifyLogout: boolean;
    };
    defaultLoginRedirect: string;
    failedLoginHandler?: FailedLoginHandler;
    zendeskChat?: ZendeskChatOptions;
    legalPage: {
        eula?: ReactElement;
        pleskEula?: ReactElement;
        cPanelEula?: ReactElement;
        terms: ReactElement;
        privacy: ReactElement;
        dpa: ReactElement;
        cookiesContent: ReactElement;
    };
    cookieManager: {
        cookieModify: () => void;
        cookieWithdraw: () => Promise<void>;
    };
    licencesModule: {
        /** Hide all functionality that is not related to license management */
        simpleView?: boolean;
        hideDocumentationLinks?: boolean;
        homePageLink?: string;
    };
    usePlatform360LocaleCode: boolean;
};

export const ApplicationSettingsContext = createContext<
    Record<Application, ApplicationSettings> | undefined
>(undefined);
