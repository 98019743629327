// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    CopyText: {
        copied: 'In Zwischenablage kopiert.',
    },
    Page: {
        ImpersonationBanner: {
            title: 'Sie haben die Identität eines anderen Kontos übernommen.',
            depersonateButton: 'Identitätsübernahme beenden',
            auth0IdLabel: 'auth0Id:',
            teamGuidLabel: 'teamGuid:',
            successToast: 'Sie übernehmen nicht mehr die Identität eines anderen Kontos.',
        },
        MainMenu: {
            betaVersion: 'Betaversion',
            servers: 'Server',
            websites: 'Websites',
            licenses: 'Lizenzen',
            monitoring: 'Monitoring',
        },
        DotsMenu: {
            platform: 'Plesk 360',
            wpsd: 'WP Guardian',
            monitoring: 'Monitoring',
            feedback: 'Ihr Feedback',
            services: 'Dienste in Plesk 360',
        },
        GlobalSearch: {
            SearchButton: {
                search: 'Suchen...',
            },
            ClientResultGroup: {
                clients: 'Kunden',
                clientsShowMore: '{showMore, plural, one{# weiterer Client...} other{# weitere Clients...}}',
            },
            DomainResultGroup: {
                domains: 'Websites',
                domainsShowMore: '{showMore, plural, one{# weitere Website...} other{# weitere Websites...}}',
            },
            ServersResultGroup: {
                servers: 'Server',
                serversShowMore: '{showMore, plural, one{# weiterer Server...} other{# weitere Server...}}',
            },
            search: 'Suchen...',
            noResults: 'Keine Ergebnisse...',
        },
        UserMenu: {
            team: 'Unternehmen',
            logout: 'Abmelden',
            changePassword: 'Passwort ändern',
            viewProfile: 'Persönliches Profil',
            businessProfile: 'Unternehmensprofil',
            apiTokens: 'API-Tokens',
        },
    },
    limit: 'Grenzwert:',
    upgradePropertiesButton: 'Anpassen',

    common: {
        errors: {
            isNotEmpty: 'Dieses erforderliche Feld ist leer.',
            required: 'Dieses erforderliche Feld ist leer.',
            url: 'Der Wert ist keine gültige URL.',
            isUrl: 'Der Wert ist keine gültige URL.',
            email: 'Der Wert muss eine gültige E-Mail-Adresse sein.',
            isEmail: 'Der Wert muss eine gültige E-Mail-Adresse sein.',
            json: 'Es muss ein gültiger Wert des Typs JSON sein.',
            min: '{min, plural, one{Der Wert muss mindestens # Zeichen enthalten.} other{Der Wert muss mindestens # Zeichen enthalten.}}',
            maxLength: '{max, plural, one{Der Wert darf höchstens # Zeichen enthalten.} other{Der Wert darf höchstens # Zeichen enthalten.}}',
            isHttps: 'Der Hostname des Servers muss mit https:// beginnen.',
            unique: 'Der Wert muss eindeutig sein.',
            isPublicIp: 'Der Hostname des Servers sollte in eine öffentliche IP-Adresse umgewandelt werden.',
            unknown: 'Unbekannter Fehler: {message}',
            externalRedirect: 'Ein Fehler ist aufgetreten.',
        },
    },

    auth0: {
        errors: {
            errorPasswordIsEmpty: 'Bitte geben Sie ein Passwort ein.',
            errorPasswordIsWrong: 'Falsches Passwort.',
            errorPasswordIsTooWeak: 'Das Passwort ist zu schwach.',
            errorPasswordContainsPersonalInfo: 'Das Passwort darf keine persönlichen Informationen enthalten.',
            errorPasswordTooCommon: 'Das Passwort ist zu gewöhnlich.',
            errorPasswordPreviouslyUsed: 'Das Passwort wurde zuvor schon verwendet.',
            errorInvalidOtp: 'Der eingegebene Code ist nicht gültig.',
        },
    },

    Keys: {
        headers: {
            confirmSubscription: 'Abonnement bestätigen',
            payment: 'Zahlung',
        },
        general: {
            buyNow: 'Jetzt kaufen',
            confirm: 'Bestätigen',
            confirmAndFeedback: 'Bestätigen und Feedback senden',
            cancel: 'Abbrechen',
        },
        tabTitle: 'Alle Lizenzen',
        LinkedEmails: {
            tabTitle: 'Verknüpfte E-Mail-Adressen',
            emailColumn: 'E-Mail-Adresse',
            statusColumn: 'Status',
            totalRows: '{totalRows, plural, one{# E-Mail-Adresse insgesamt} other{# E-Mail-Adressen insgesamt}}',
            verifiedStatus: 'Bestätigt',
            addButton: 'Weitere E-Mail-Adressen verknüpfen',
            deleteButton: 'Verknüpfung der E-Mail-Adresse aufheben',
            AddEmails: {
                stepOne: {
                    title: 'E-Mail-Adressen mit Lizenzen verknüpfen',
                    emailsLabel: 'E-Mail-Adressen, die beim Kauf der Lizenzen angegeben wurden',
                    submitButton: 'Bestätigung senden',
                },
                stepTwo: {
                    title: 'Überprüfungsschreiben wurden versendet',
                    description: 'Überprüfen Sie die Postfächer auf Nachrichten und klicken Sie auf den Verifizierungslink. Sie müssen sich möglicherweise erneut in diesem Konto anmelden, um das Hinzufügen neuer verknüpfter E-Mail-Adressen abzuschließen. Die Links sind 1 Stunde lang gültig.',
                    emailColumn: 'E-Mail-Adresse',
                    submitButton: 'Fertig',
                    resendButton: 'Erneut senden',
                },
            },
            Onboarding: {
                title: 'E-Mail-Adressen mit diesem Konto verknüpfen, um mehr Lizenzen anzuzeigen',
                description: 'Um mehrere Ihrer Lizenzen zu verwalten, geben Sie die E-Mail-Adressen an, mit denen Sie die Lizenzen gekauft haben.',
                docsLink: 'Weitere Informationen',
                addEmailsButton: 'E-Mail-Adressen hinzufügen',
            },
        },
        Licenses: {
            MyLicenses: {
                buyButtons: {
                    plesk: 'Plesk kaufen',
                    monitoring: '360 Monitoring kaufen',
                },
            },
            Onboarding: {
                DefaultOnboarding: {
                    title: 'Hier können Sie Ihre Lizenzen aufrufen und verwalten',
                    description1: 'Klicken Sie auf "Neue Lizenz kaufen", falls Sie noch keine Lizenz haben.',
                    description2: 'Falls Sie Lizenzen über andere E-Mail-Adressen gekauft haben und nun hinzufügen möchten, klicken Sie auf "Bestehende Lizenzen verknüpfen".',
                    docsLink: 'Weitere Informationen',
                    buyButton: 'Neue Lizenz kaufen',
                    addEmailsButton: 'Bestehende Lizenzen verknüpfen',
                },
            },
        },
        CB: {
            paymentInfo: 'Der von {companyName} betriebene Plesk-Online-Shop wird diese Bestellung unter Verwendung der Zahlungsmethode bearbeiten, die dem ausgewählten Abonnement zugeordnet ist',
            confirmSubscription: 'Mit der Aufgabe dieser Bestellung erklären Sie sich damit einverstanden, dass Sie sich für ein Abonnementprodukt anmelden, das Ihnen in regelmäßigen Abständen in Rechnung gestellt wird. Die weitere Nutzung des gekauften Produkts ist von der Begleichung der Abonnementskosten abhängig. Details zum Preis und zur Abrechnungsfrequenz werden oben im Warenkorb angezeigt.',
            buyInfo: 'Mit der Aufgabe dieser Bestellung erklären Sie sich mit den <termsLink>Geschäftsbedingungen</termsLink> von Cleverbridge einverstanden und bestätigen, dass Sie die <policyLink>Datenschutzrichtlinien</policyLink> von Cleverbridge und die Informationen zum <revocationLink>Recht auf Widerruf</revocationLink> als Verbraucher gelesen und verstanden haben.',
            links: {
                terms: 'Geschäftsbedingungen',
                contact: 'Kontakt',
                revocation: 'Recht auf Widerruf',
                security: 'Sicherheit',
                legal: 'Rechtliche Hinweise',
                policy: 'Datenschutzrichtlinie',
            },
            footer: 'Sie bestellen über unseren Reseller {companyName}. Zahlungsbearbeitung und Bestellvorgang werden durchgeführt von {companyName}, {companyAddress}.',
            nonUS: {
                companyName: 'Cleverbridge AG',
                companyAddress: 'Gereonstr. 43-65, 50670 Köln (Deutschland)',
            },
            US: {
                companyName: 'Cleverbridge, Inc.',
                companyAddress: '350 N Clark, Suite 700, Chicago, IL 60654 (USA)',
            },
        },
        Renew: {
            drawer: {
                title: 'Abonnement erneut aktivieren',
            },
            form: {
                submitButton: 'Verlängerung bestätigen',
                confirmText: 'Wenn Sie auf die Schaltfläche unten klicken, bestätigen Sie die Verlängerung Ihres Abonnements für "{product}" von WebPros International GmbH',
            },
            buttonTitle: 'Abonnement erneut aktivieren',
            successMessage: 'Das Abonnement für {product} wurde verlängert. Der Lizenzschlüssel wird in Kürze aktiviert.',
            unavailable: 'Dieses Abonnement kann nicht verlängert werden (es wurde eventuell eingestellt). Bitte kaufen Sie ein neues Produkt.',
        },
        Adjust: {
            delayedChangesProducts: 'Bis zum Ende des Abrechnungszeitraums ({date}) bleiben die folgenden Grenzwerte bestehen',
        },
        Cancel: {
            buttonTitle: 'Stornieren',
            successMessage: 'Das Abonnement von {product} wurde storniert. Sie können das Produkt noch bis zum {date} verwenden.',
            popover: {
                title: 'Abonnement von {product} stornieren',
                message: 'Ihnen werden dadurch keine weiteren Gebühren in Rechnung gestellt. Sie können die Lizenz bis zum {date} weiter nutzen (der aktuelle Abrechnungszeitraum wird nicht erstattet).',
            },
        },
        Payment: {
            card: {
                expired: 'Ihre Karte ist abgelaufen',
            },
        },
        Tours: {
            moreDetails: {
                editPaymentMethod: {
                    text: 'Sie können die Zahlungsmethode in den Einstellungen des Abonnements ändern.',
                    confirm: 'OK',
                },
            },
        },
    },

    ConfirmationPopover: {
        cancel: 'Abbrechen',
    },

    ZendeskChat: {
        confirmationPopover: {
            description1: 'Platform 360 nutzt ZendeskChat, eine Drittanbieteranwendung.',
            description2: 'Indem ich fortfahre, erkläre ich mich damit einverstanden, die Drittanbieteranwendung ZendeskChat zu nutzen. Dabei können meine personenbezogenen Daten (z. B. IP-Adresse) an Dritte innerhalb oder außerhalb Europas übertragen werden. Weitere Informationen finden Sie in unserer <a>Datenschutzrichtlinie</a>.',
            confirmButton: 'ZendeskChat starten',
        },
        supportFormPopover: {
            description: 'Derzeit sind keine Kundenservicemitarbeiter verfügbar. Bitte warten Sie oder verwenden Sie unser Ticketformular, um eine Anfrage zu stellen.',
            confirmButton: 'Formular öffnen',
        },
        ChatButton: {
            title: 'Support',
        },
    },

    keysPageTitle: 'Lizenzen',
    goBack: 'Zurück zum Startbildschirm',
    keysPageDescription: 'Im Plesk-Online-Shop erworbene Lizenzen verwalten.',
    keysToolbarBuyTitle: 'Kaufen',
    keysToolbarFilterTitle: 'Filter',

    keyAdditionalActionsToolbarTitle: 'Zusätzliche Aktionen',
    keyMoreDetails: 'Weitere Details',

    keyForeignCart: 'Sie sind nicht der Inhaber dieser Lizenz',
    keyForeignDescriptionTitle: 'Sie können die Lizenz nicht verwalten, weil sie zu einem anderen Konto gehört. Das kann in den folgenden Situationen der Fall sein:',
    keyForeignDescriptionHosting: 'Ihre Plesk-Lizenz gehört Ihrem Hosting-Provider.',
    keyForeignDescriptionWrongEmail: 'Sie haben die Lizenz über ein anderes Konto erworben. Um die Lizenz abzurufen und zu verwalten, melden Sie sich mit der E-Mail-Adresse an, die zu dem entsprechenden Konto gehört.',

    keyTrialDescription: 'Dies ist eine Testlizenz. Möchten Sie eine {link} erwerben?',
    keyTrialDescriptionLink: 'Kostenpflichtige Lizenz',

    keyTerminatedCart: 'Diese Lizenz wurde beendet',
    keyTerminatedDescription: 'Die Lizenz ist nicht mehr gültig. ',
    keyTerminatedDescriptionRetail: 'Ihnen fallen dafür keine Kosten mehr an.',

    keyPartnerCart: 'Diese Lizenz wurde nicht im Online-Shop von Plesk erworben.',
    keyPartnerDescription: 'Für diese Lizenz sind keine Abrechnungsdetails vorhanden.',

    keyLicenseDetailsTitle: 'Lizenzdetails',
    keyLicenseDetailsCreationDate: 'Erstellungsdatum:',
    keyLicenseDetailsActivationCode: 'Aktivierungscode:',
    keyBillingDetailsTitle: 'Abrechnungsdetails',
    keyBillingDetailsTrial: 'Dies ist eine Testlizenz. Sie läuft am {date} ab. <a>Lizenz kaufen</a>.',
    keyBillingDetailsNotRetail: 'Für diese Lizenz können keine Abrechnungsdetails angezeigt werden, weil sie nicht im Online-Shop von Plesk gekauft wurde.',
    keyBillingDetailsTerminated: 'Ihr Abonnement wurde gekündigt.',
    keyBillingDetailsSubscriptionId: 'Abonnement-ID:',
    keyBillingDetailsCycle: 'Abrechnungszeitraum:',
    keyBillingDetailsForeign: 'Für diese Lizenz können keine Abrechnungsdetails angezeigt werden, weil sie zu einem anderen Konto gehört.',
    keyServerDetailsTitle: 'Serverdetails',
    keyServerDetailsAddresses: 'IP-Adressen des Servers:',
    keyServerDetailsOs: 'Betriebssystem des Servers:',
    keyServerDetailsProductVersion: 'Produktversion:',
    keyServerDetailsAbsent: 'Die Lizenz wurde noch nicht auf einem Plesk-Server installiert.',

    pleskUpgradedMessage: 'Sie haben ein Upgrade für die Lizenz "{from}" ({id}) auf "{to}" durchgeführt.',
    propertyUpgradedIncreaseMessage: 'Die Kapazität der Lizenz {keyName} wurde auf {to} {name} erhöht',
    propertyUpgradedDecreaseMessage: 'Die Kapazität der Lizenz {keyName} wurde auf {to} {name} verringert. Die Änderungen werden im neuen Abrechnungszeitraum umgesetzt.',
    keyUpgradedMessage: 'Sie haben ein Upgrade für die Lizenz "{from}" ({id}) auf "{to}" durchgeführt. Ihre Lizenz "{to}" ({id}) ist der Plesk-Lizenz "{parentId}" zugewiesen.',
    keyAddedMessage: 'Sie haben die Lizenz "{addon}" erworben. Sie ist der Lizenz "{parent}" zugewiesen: {parentId}.',
    refreshSuggestion: 'Falls Sie die Änderungen nicht sehen, <a>aktualisieren Sie die Seite</a>.',

    upsellPageTitle: 'Neue Erweiterung zur Lizenz hinzufügen',
    yearlyPrices: 'Jährlicher Preis',
    monthlyPrices: 'Monatlicher Preis',
    subscriptionForBillingPeriodNotFound: 'Der Abrechnungszeitraum des Abonnements und der Lizenz für die Erweiterung stimmen nicht überein oder es wurde keine Plesk-Lizenz für den Abrechnungszeitraum erworben. Passen Sie den Zeitraum an oder kaufen Sie eine Plesk-Lizenz im Online-Shop.',
    subscriptionToUpgradeHeader: 'Upgrade des Abonnements',
    selectedSubscription: '{subscriptionId} (Währung: {billingDate}; Abrechnungszeitraum: {billingCycle})',
    toOnlineStoreButton: 'Zum Online-Shop',
    why: 'Warum?',
    whyCharge: 'Der in Rechnung gestellte Betrag entspricht anteilig den restlichen Tagen im aktuellen Abrechnungszeitraum.',
    chargeNow: 'Ihnen werden jetzt <b>{grossPrice}</b> (inkl. {vatPrice} MwSt.) in Rechnung gestellt.',
    chargeNowShort: 'Die Gebühren werden Ihnen jetzt in Rechnung gestellt',
    inclVatShort: 'inkl. {vatPrice} MwSt.',
    delayedCharge: 'Der Grenzwert liegt bis zum Ende des Abrechnungszeitraums bei {limit} {property}.',
    nextCharge: '<b>{grossPrice}</b> (inkl. MwSt {vatPrice}) beginnend ab {nextBillingDate}',
    nextChargeShort: 'Ab dem {nextBillingDate} werden Ihnen Gebühren in Rechnung gestellt',
    noProductForBillingPeriod: 'Für den ausgewählten Abrechnungszeitraum sind keine Produkte vorhanden.',
    confirmationPopupTitle: 'Upgrade für derzeitiges Abonnement',

    upgradePageTitle: 'Lizenz-Upgrade',
    upgradePageDescription: 'Hier können Sie ein Upgrade für Ihre Lizenz durchführen.',
    upgradePanelBeforeTitle: 'Vor dem Upgrade',
    upgradePanelOptionsTitle: 'Upgrade-Optionen',
    upgradePanelAfterTitle: 'Nach dem Upgrade',
    upgradePanelOptionsRecommendationsTitle: 'Unsere Empfehlungen:',
    upgradePanelOptionsRecommendationsAlt: 'Oder {link} im Nachfolgenden an.',
    upgradePanelOptionsRecommendationsAltLink: 'sehen Sie sich alle möglichen Optionen',
    upgradeSuggestion: 'Produkte zur Lizenz hinzufügen',
    upgradeSuggestionFreeCost: 'Jetzt kostenlos ausprobieren!',
    upgradeSuggestionDescription: '(Produktdetails für weitere Informationen aufrufen)',
    upgradeMultiOfferSuggestion: 'Anderes Paket auswählen',
    upgradeConfirm: 'Jetzt kaufen',
    upgradeAccept: 'Bestätigen',
    upgradeBack: 'Zurück zur Produktliste',
    planUpdate: 'Paketaktualisierung',
    adjustPending: 'Die Anpassung dieser Lizenz steht noch aus. Versuchen Sie es erneut in 30 Sekunden.',
    waitAMoment: 'Bitte warten',
    upgradeConfirmPriceRecalculation: 'Ihnen werden jetzt {chargeInfo} in Rechnung gestellt. Dies ist der anteilige Betrag, der dem Zeitraum bis zum nächsten Abrechnungsdatum am {nextBillingDate} entspricht. Ab dem {nextBillingDate} wird Ihnen dann {renewalInfo} für dieses Produkt berechnet.',
    buyNowNotification: 'Klicken Sie auf "Jetzt kaufen", um eine Lizenz oder ein Upgrade zu erwerben. Sie werden dann an den von Cleverbridge AG bereitgestellten Plesk-Online-Shop weitergeleitet. Für die Bezahlung wird im Online-Shop die Zahlungsmethode verwendet, die Sie bereits zuvor für den Kauf oder das Upgrade Ihrer Lizenz eingesetzt haben. Alle Zahlungen werden von Cleverbridge AG verarbeitet.',
    paymentDetailsLinkText: 'Zahlungsdetails für diese Transaktion aufrufen und bearbeiten.',
    upgradeDoneRedirectToPleskTitle: 'Kauf abgeschlossen',
    upgradeDoneRedirectToPleskDescription: 'Die Installation der Lizenz kann einige Zeit dauern.',
    upgradeDoneRedirectToPleskButton: 'Zurück zu Plesk',
    upgradeDoneRedirectToKeys: 'Meine Lizenzen anzeigen',
    total: 'GESAMT',
    vat: 'MwSt.',
    newTotal: 'GESAMT NEU',
    newVat: 'MWST. NEU',

    firstLoginWithoutEmailVerification: 'Vielen Dank, dass Sie sich für den Dienst Plesk 360 registriert haben. Wir haben Ihnen eine E-Mail mit dem Bestätigungslink gesendet. Klicken Sie auf den Link, um Ihr Konto zu bestätigen und sich anzumelden. Sie können dann Ihre Lizenzen in Plesk 360 verwalten.',
    pleaseVerifyEmailTitle: 'Plesk 360-Konto bestätigen',
    secondLoginWithoutEmailVerification: 'Sie versuchen, sich in einem Konto anzumelden, das noch nicht bestätigt wurde. Wir haben Ihnen eine E-Mail mit dem Bestätigungslink gesendet. Klicken Sie auf den Link, um Ihr Konto zu bestätigen und sich anzumelden. Sie können dann Ihre Lizenzen in Plesk 360 verwalten.',
    userBlocked: "Das Konto wurde wegen eines Verstoßes gegen die Nutzungsvereinbarung gesperrt. Wenn Sie glauben, dass dies ein Fehler ist, schreiben Sie bitte an {emailLink}.",

    errorDefault: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
    errorDefaultWithReqId: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal. Anfrage-ID: {requestId}.',
    errorTooManyRequests: 'Limit für API-Aufrufe wurde für diese IP-Adresse erreicht. Bitte versuchen Sie es in Kürze erneut.',
    errorTokenRenewal: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
    errorNetworkIssue: 'Sie scheinen derzeit Probleme mit der Verbindung zum Netzwerk zu haben.',

    errorKAUnavailable: 'Der Dienst ist vorübergehend nicht verfügbar. Bitte versuchen Sie es später erneut.',
    errorKAUnavailableWithReqId: 'Der Dienst ist vorübergehend nicht verfügbar. Bitte versuchen Sie es später noch einmal. Anfrage-ID: {requestId}.',

    footerPurposeExtension: {
        text: 'Noch mehr Erweiterungen finden Sie im <a>Katalog der Erweiterungen von Plesk</a>.',
    },

    filterAll: 'Alle Lizenzen',
    filterActive: 'Nur aktive',
    renewalCost: 'Kosten für die Verlängerung:',
    nextBillingDate: 'Nächstes Rechnungsdatum:',
    canceledSubscriptionDate: 'Das Abonnement wurde beendet. Ihre Lizenz ist gültig bis zum',
    paymentHistory: 'Zahlungsverlauf',
    licenseStatus: 'Lizenzstatus:',
    upgradeButton: 'Upgrade',
    upsellButton: 'Erweiterung hinzufügen',
    upsellText: 'Eine neue Erweiterung wird in Plesk hinzugefügt',
    billingPeriod: 'Abrechnungszeitraum',
    features: 'Zusätzliche Funktionen:',
    noFeatures: 'Keine zusätzlichen Funktionen',
    toPlesk: 'Zu Plesk wechseln',
    expiresNote: '(läuft ab am {date})',
    startingFrom: 'ab dem',
    billingIntervalMonth: '{value}/Monat',
    billingIntervalYear: '{value}/Jahr',
    billingIntervalOnce: '{value} (Einmalzahlung)',
    perOne: 'pro Einheit',

    paymentMethod: 'Zahlungsmethode',
    editPaymentMethod: 'Zahlungsmethode bearbeiten',
    paymentCard: '{paymentType} xxxxxxxxxxxx{cardLastFourDigits} {cardExpirationMonth}/{cardExpirationYear}',

    added: 'hinzugefügt',
    addresses: 'Adressen',
    back: 'Zurück',

    monthly: 'Monatlich',
    on: 'am',
    plesk: 'Plesk',
    product: 'Produkt',
    price: 'Preis',
    products: 'Produkte',
    purchased: 'Gekauft',
    select: 'Auswählen',
    sinceNote: '(seit {date})',
    show: 'Anzeigen',
    upgrade: 'Upgrade',
    yearly: 'Jährlich',

    isEmpty: 'Dieses Feld ist erforderlich.',
    errorPasswordIsEmpty: 'Es muss ein Passwort angegeben werden.',
    errorPasswordIsWrong: 'Falsches Passwort.',
    errorPasswordIsTooWeak: 'Das Passwort ist zu schwach.',
    errorEmailOrPasswordIsIncorrect: 'E-Mail-Adresse oder Passwort ungültig.',
    errorIncorrectEmail: 'Falsche E-Mail Adresse.',
    errorUserDoesntExist: 'Der Benutzer ist nicht vorhanden.',
    errorUserExist: 'Der Benutzer ist bereits vorhanden.',
    errorUserIsNotVerified: 'Der Benutzer ist vorhanden, wurde jedoch noch nicht bestätigt. Wir haben Ihnen erneut eine E-Mail zur Bestätigung gesendet. Bitte überprüfen Sie Ihren Posteingang.',
    errorUnknownError: 'Unbekannter Fehler.',
    errorEmailIsAlreadyLinked: 'Diese E-Mail-Adresse ist bereits mit Ihrem Konto verknüpft.',
    errorLinkIsOutdated: 'Dieser Bestätigungslink ist abgelaufen.',
    errorBounceOrComplaint: 'Diese E-Mail-Adresse ist nicht vorhanden oder die Nachricht wurde als Spam eingestuft. Bitte wenden Sie sich an das Plesk 360-Supportteam.',
    errorUserEmailAlreadyVerified: 'Diese E-Mail-Adresse wurde bereits bestätigt.',
    errorDoResourceIdExist: 'Dieses Digital Ocean-Konto wurde bereits registriert.',
    errorCopyToClipboard: 'Fehler beim Kopieren. Versuchen Sie, die Elemente manuell zu kopieren.',

    PrivacyPolicy: {
        privacyPolicyText: 'WebPros International GmbH verarbeitet die von Ihnen zur Verfügung gestellten Daten, um Ihre Anfrage in Übereinstimmung mit der <a>Datenschutzrichtlinie</a> zu bearbeiten.',
    },

    Auth: {
        errors: {
            userDoesntExist: 'Der Benutzer ist nicht vorhanden.',
            bounceOrComplaint: 'Diese E-Mail-Adresse ist nicht vorhanden oder die Nachricht wurde als Spam eingestuft. Bitte wenden Sie sich an das Plesk 360-Supportteam.',
            userIsNotVerified: 'Der Benutzer ist vorhanden, wurde jedoch noch nicht bestätigt. Wir haben Ihnen erneut eine E-Mail zur Bestätigung gesendet. Bitte überprüfen Sie Ihren Posteingang.',
            userExist: 'Der Benutzer ist bereits vorhanden.',
            errorPasswordIsEmpty: 'Es muss ein Passwort angegeben werden.',
            errorPasswordIsWrong: 'Falsches Passwort.',
            errorPasswordIsTooWeak: 'Das Passwort ist zu schwach.',
        },
        AuthContainer: {
            tabs: {
                login: 'Anmelden',
                signUp: 'Registrieren',
            },
        },
        PageIntro: {
            PlatformPageIntro: {
                title: 'Willkommen bei Plesk 360',
                text1: 'Das Monitoring und die Verwaltung von Servern ganz leicht gemacht.',
                text2: 'Mit dem vollständig <b>integrierten Website- und Server-Monitoring</b> behalten Sie die Performance im Auge und können Ausfallzeiten vermeiden.',
                text3: 'Mit einem Upgrade haben Sie Zugriff auf <b>konfigurierbare Dashboards</b>, personalisierte Warnungen, minütliche Scans, den Netzwerkverlauf und vieles mehr.',
                text4: 'Sie können Ihr <b>Serverinventar</b> verwalten, alle Ihre Plesk-Lizenzen aufrufen sowie Abonnements kaufen oder aktualisieren.',
                text5: 'Und das alles auf einer einzigen Plattform in einem einzigen Konto.',
            },
            MonitoringPageIntro: {
                title: 'Willkommen bei 360 Monitoring',
                text1: 'Das Monitoring und die Verwaltung von Servern ganz leicht gemacht.',
                text2: 'Vollständig <b>integriertes Website- und Server-Monitoring</b> – so behalten Sie die Performance im Auge und können Ausfallzeiten vermeiden.',
                text3: '<b>Konfigurierbare Dashboards</b>, personalisierte Warnungen, minütliche Scans, Servermessdaten und E/A, Netzwerkleistung und benutzerdefinierte Messwerte via Plugins. ',
                text4: 'Multi-Cloud und <b>plattformunabhängig</b>.',
            },
        },
        SignUp: {
            signUp: 'Registrieren',
            loginLink: 'Zur Anmeldung',
            emailLabel: 'E-Mail-Adresse',
            emailDescription: 'Verwenden Sie am besten die E-Mail-Adresse, über die Sie auch Plesk und Erweiterungen gekauft haben',
            passwordLabel: 'Passwort',
            privacyPolicyAgreementTitle: 'Ich stimme den <a>{termsLink}</a> zu',
            termsPlatform: 'Plesk 360-Nutzungsbedingungen',
            termsMonitoring: '360 Monitoring - Nutzungsbedingungen',
            privacyPolicyAgreementDescription: 'Zum Zwecke der Erstellung und Pflege meines Account auf der Plattform werden die WebPros International GmbH und andere Unternehmen der WebPros-Gruppe die bereitgestellten Informationen in ihren internen Systemen gemäß der <a>Datenschutzrichtlinie</a> verarbeiten, solange mein Konto aktiv ist.',
            verifyAccountTitle: 'Konto bestätigen',
            verifyAccountText: 'Wir haben eine E-Mail mit dem Bestätigungslink an {email} gesendet. Klicken Sie auf diesen Link, um Ihr Konto zu bestätigen und sich anzumelden.',
            resendDescription: 'E-Mail nicht erhalten? Überprüfen Sie Ihren Spamordner oder klicken Sie auf "Erneut senden", damit die E-Mail noch einmal gesendet wird.',
            resendLabel: 'Erneut senden',
            retryCountdown: 'Kann erneut gesendet werden in {time}',
            SignUpByInvitation: {
                emailDescription: 'Sie werden mit der oben genannten E-Mail angemeldet',
                invitationSucceed: 'Sie haben sich erfolgreich registriert. Bitte melden Sie sich jetzt an.',
            },
            SignUpForm: {
                NameFormField: {
                    firstNamePlaceholder: 'Vorname',
                    lastNamePlaceholder: 'Nachname',
                    nameLabel: 'Name',
                },
            },
        },
        Login: {
            signUpButton: 'Registrieren',
            emailLabel: 'E-Mail-Adresse',
            passwordLabel: 'Passwort',
            submitButton: 'Anmelden',
            forgotYourPasswordLink: 'Passwort vergessen?',
        },
        ResetPassword: {
            title: 'Passwort zurücksetzen',
            text: 'Geben Sie Ihre E-Mail-Adresse ein. Sie erhalten dann eine E-Mail zum Zurücksetzen des Passworts.',
            emailLabel: 'E-Mail-Adresse:',
            submitButton: 'Senden',
            successTitle: 'E-Mail zum Zurücksetzen des Passworts gesendet',
            successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your {brand} and set a new password.',
            successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
            resendButton: 'Erneut senden',
            loginForm: 'Jetzt anmelden',
            retryCountdown: 'Kann erneut gesendet werden in {time}',
        },
    },

    Footer: {
        copyright: '© {year} WebPros International GmbH. Alle Rechte vorbehalten. Plesk und das Plesk Logo sind Marken von WebPros International GmbH.',
        followPlesk: 'Folgen Sie Plesk:',
        followCPanel: 'Folgen Sie cPanel:',
        company: 'Unternehmen',
        aboutCPanel: 'Über cPanel',
        aboutPlesk: 'Über Plesk',
        legal: 'Rechtliches',
        cookiehub: 'Cookie-Einstellungen',
        privacyPolicy: 'Datenschutzrichtlinie',
        impressum: 'Impressum',
        knowledgeBase: 'Knowledge Base',
        documentation: 'Dokumentation',
        helpCenterCPanel: 'cPanel Hilfe',
        helpCenterPlesk: 'Plesk Hilfe',
        contactUs: 'Kontakt',
        pleskLifecyclePolicy: 'Plesk-Richtlinien zum Lebenszyklus',
        community: 'Community',
        blogCPanel: 'cPanel Blog',
        blogPlesk: 'Plesk Blog',
        forumsCPanel: 'cPanel Foren',
        forumsPlesk: 'Plesk Foren',
        cPanelUniversity: 'cPanel University',
        pleskUniversity: 'Plesk University',
    },

    Form: {
        requiredLegend: 'Erforderliche Felder',
        submitButton: 'OK',
        cancelButton: 'Abbrechen',
    },

    FormField: {
      addMore: 'Weitere hinzufügen',
      remove: 'Entfernen',
      clear: 'Löschen',
    },

    FormFieldPassword: {
        improvePassword: 'Tipps zum Festlegen von sicheren Passwörtern:',
        passwordTooShort: 'Wählen Sie ein längeres Passwort aus.',
        lettersLowerCase: 'Verwenden Sie mindestens einen Kleinbuchstaben.',
        lettersUpperCase: 'Verwenden Sie mindestens einen Großbuchstaben.',
        numbers1: 'Verwenden Sie mindestens eine Ziffer.',
        numbers3: 'Verwenden Sie mindestens drei Ziffern.',
        specialChar1: 'Verwenden Sie mindestens ein Sonderzeichen (zum Beispiel: !, @, #, $, % ,^, &, *, ?, _, ~).',
        specialChar2: 'Verwenden Sie mindestens zwei Sonderzeichen (zum Beispiel: !, @, #, $, %, ^, &, *, ?, _, ~).',
        comboUpperAndLower: 'Verwenden Sie sowohl Groß- als auch Kleinbuchstaben.',
        comboLettersAndNumbers: 'Verwenden Sie sowohl Ziffern als auch Buchstaben.',
        comboLettersNumbersSpecial: 'Verwenden Sie Ziffern, Buchstaben und Sonderzeichen.',
        yourPasswordIsStrong: 'Längere Passwörter sind noch sicherer.',
        passwordStrength: 'Die Passwortsicherheit ist %%strength%%.',
        strengthVeryStrong: 'SEHR STARK',
        strengthStrong: 'STARK',
        strengthVeryWeak: 'SEHR SCHWACH',
        strengthWeak: 'SCHWACH',
        strengthMedium: 'MITTEL',
        hidePassword: 'Passwort ausblenden',
        showPassword: 'Passwort anzeigen',
        generateButton: 'Generieren',
        generateButtonHint: 'Starkes Passwort generieren',
    },

    Pagination: {
        totalShort: 'von %%total%%',
        itemsPerPage: 'Elemente pro Seite',
        all: 'Alle',
        total: 'Seite %%current%% von %%total%%',
    },

    ListEmptyView: {
        filteredTitle: 'Keine mit dem Filter übereinstimmende Elemente gefunden',
        emptyTitle: 'Keine Elemente vorhanden',
        filteredDescription: 'Passen Sie Ihre Anfrage an oder entfernen Sie einige der Filterkriterien und versuchen Sie es erneut.',
    },

    Dialog: {
        cancelButton: 'Abbrechen',
    },
    keyNotUnderOnlineStore: 'Sie können kein Upgrade für die Lizenz in Plesk 360 durchführen, weil sie nicht über den Online-Shop von Plesk gekauft wurde. Falls Sie diese Lizenz über Ihren Hosting-Provider erworben haben, wenden Sie sich bitte an ihn, um weitere Details zu den Upgrade-Optionen zu erhalten.',
    notOwnLicense: 'Sie können kein Upgrade für die Lizenz in Plesk 360 durchführen, weil sie zu einem anderen Konto gehört. Falls Sie diese Lizenz über Ihren Hosting-Provider erworben haben, wenden Sie sich bitte an ihn, um weitere Details zu den Upgrade-Optionen zu erhalten.',
    subscriptionNotFound: 'Abonnementdaten nicht gefunden',
    unableToUpgrade: 'Upgrade ist nicht verfügbar. Wenden Sie sich bitte an den Plesk Support.',
    unknownError: 'Der Dienst ist vorübergehend nicht verfügbar. Bitte versuchen Sie es später erneut.',
    pendingOperationFound: 'Dieser Vorgang kann nicht durchgeführt werden, weil eine vorherige Aktion (z. B. Ändern der Zahlungsmethode) noch nicht abgeschlossen wurde. Schließen Sie den Vorgang ab, um den nächsten durchzuführen.',

    LinkEmail: {
        title: 'E-Mail-Adresse verknüpfen',
        tryAgain: 'Erneut versuchen',
    },

    GaOptOutLink: {
        gaSuccess: 'Tracking über Google Analytics wurde angehalten.',
    },

    Servers: {
        sync: 'Synchronisieren',
        syncNoSelection: 'Sie haben keine Server für die Synchronisierung ausgewählt. Möchten Sie alle Server synchronisieren?',
        syncAll: 'Alle synchronisieren',
        serverNameColumn: 'Servername',
        productColumn: 'Produkt',
        panelColumn: 'Hosting-Control-Panel',
        stateColumn: 'Serverstatus',
        stateLastSync: 'Letzte Synchronisierung: {duration}',
        stateLastSyncNow: 'Letzte Synchronisierung: gerade eben',
        login: {
            plesk: 'Zu Plesk wechseln',
            cpanel: 'cPanel öffnen',
            wp: 'Zu WordPress',
        },
        forceSyncScheduledSuccess: '{count, plural, =1{Es ist geplant, den Server <b>{title}</b> zu synchronisieren.} one{Es ist geplant, <b>#</b> Server zu synchronisieren.} other{Es ist geplant, <b>#</b> Server zu synchronisieren.}}',
        updateServer: '{product} aktualisieren',
        cancel: 'Abbrechen',
        Toolbar: {
            add: 'Hinzufügen',
            disconnect: 'Trennen',
            sync: 'Synchronisieren',
            groupSearch: 'Suchen',
        },
        Onboarding: {
            title: 'Hier können Sie Ihre Plesk-Server hinzufügen und verwalten',
            description: 'Fügen Sie Ihre Server hinzu, um mit nur einem Klick auf das Control Panel zugreifen zu können.',
            docsLink: 'Weitere Informationen',
            addServersButton: 'Server verbinden',
            feedbackButton: 'Feedback senden',
            installPleskButton: 'Plesk auf einem neuen Server installieren',
        },
        FeedbackButton: {
            heading: 'Feedback geben',
            subHeading: 'Rückmeldungen helfen uns bei der weiteren Entwicklung des Produkts.',
        },
        Tour: {
            next: 'Weiter',
            previous: 'Zurück',
            gotit: 'OK',
            step1: {
                title: 'Das Serverinventar',
                description: 'Ein Tool für die Verwaltung mehrerer Server in Plesk 360. Fügen Sie Ihre Plesk-Server einfach und sicher über den Tab "Server" oder über Plesk hinzu.',
            },
            step2: {
                title: 'Das Serverinventar',
                description: 'Der Tab "Server" enthält auch durchsuchbare Listen aller Kunden und Domains auf Ihren Servern.',
            },
        },
        AddServerWithExternalRedirectDialog: {
            products: {
                plesk: 'Plesk',
                cpanel: 'cPanel',
                wp: 'WordPress',
            },
            title: 'Verbindung des Servers mit Plesk 360 bestätigen',
            description: 'Ihre {product}-Systemsteuerung unter <b>{address}</b> wird mit Ihrem Konto <b>{email}</b> bei Plesk 360 verknüpft. Bitte stellen Sie sicher, dass dies Ihr Konto ist, bevor sie bestätigen!',
            accessTitle: 'Gewährte Berechtigungen',
            accessMessage1 : '<b>Plesk 360</b> wird der Zugriff auf die Plesk-Systemsteuerung unter <b>{address}</b> gewährt.',
            accessMessage2 : '<b>Plesk 360</b> gewährt dem Konto <b>{email}</b> folgende Berechtigungen:',
            accessItem1: 'Anmeldung bei Plesk als Administrator oder als ein anderer Benutzer, ohne die Eingabe der Zugangsdaten,',
            accessItem2: 'Abrufen der Liste der auf dem Server gehosteten Clients und Websites,',
            accessItem3: 'Aktivierung der externen Überwachung des Servers,',
            accessItem4: 'Ausführung anderer Aktionen (zukünftig).',
            buttons: {
                confirm: 'Bestätigen',
                confirmAnyway: 'Trotzdem bestätigen',
                nextStep: 'Nächster Schritt',
                deny: 'Verweigern',
            },
            enableIpRestrictionTitle: 'API-Schlüssel-Details',
            fields: {
                enableIpRestriction: 'IP-Adressbeschränkung für API-Schlüssel aktivieren, erlaubte IPs:',
            },
        },
        AddServerControlStatus: {
            info: {
                access: 'Der Server muss über das Internet zugreifbar sein.',
                certificate: 'Der Server muss durch ein gültiges SSL/TLS-Zertifikat geschützt sein.',
            },
            success: {
                access: 'Die Plesk-Installation ist über das Internet zugänglich.',
            },
            ServerAccessError: {
                urlIsRequired: 'Bitte geben Sie die Server-URL ein.',
                urlMaxLength: "{max, plural, one{Die URL des Servers muss weniger als # Zeichen haben.} other{Die URL des Servers muss weniger als # Zeichen haben.}}",

                urlInvalid: {
                    title: 'Die angegebene Serveradresse kann nicht verwendet werden.',
                    description: 'Dies ist in der Regel darauf zurückzuführen, dass in der Adresse "http" statt "https" angegeben wird oder die URL einen Tippfehler enthält, z. B. fehlt die Top-Level-Domain (.com, .org usw.).',
                },

                urlPrivateIp: {
                    title: 'Ihr Server ist nicht verfügbar und kann nicht erreicht werden.',
                    description: 'Die Serveradresse des Hosts ist keine öffentliche IP-Adresse.',
                },

                serverAlreadyAdded: 'Dieser Server wurde bereits hinzugefügt. <a>Zu den Serverdetails.</a>',

                domainNotResolved: {
                    title: 'Ihr Server ist nicht verfügbar und kann nicht erreicht werden.',
                    description: 'Der Domainname der Serveradresse kann von Plesk 360 nicht aufgelöst werden.',
                },

                domainResolvedPrivateIp: {
                    title: 'Ihr Server ist nicht verfügbar und kann nicht erreicht werden.',
                    description: 'Vergewissern Sie sich, dass dieser Domain eine öffentliche IP zugewiesen ist.',
                },

                timeout: {
                    title: 'Ihr Server ist nicht verfügbar und kann nicht erreicht werden.',
                    description: 'Überprüfen Sie Ihre Firewall-Einstellungen und lassen Sie den angegebenen Port von {publicIps} zu.',
                },

                connectionAborted: {
                    title: 'Ihr Server ist nicht verfügbar und kann nicht erreicht werden.',
                    description: 'Überprüfen Sie Ihre Firewall-Einstellungen und lassen Sie den angegebenen Port von {publicIps} zu.',
                },

                connectionRefused: {
                    title: 'Die Netzwerkverbindung wurde abgelehnt.',
                    description: 'Überprüfen Sie die angegebene Domain und den Port.',
                },

                badProtocol: 'Die angegebene Adresse sollte durch ein gültiges SSL-Zertifikat geschützt sein.',

                notAuthenticated: {
                    title: 'Die Verbindung wird vom Remoteserver nicht autorisiert.',
                    description: 'Bitte fügen Sie den Server erneut zum Serverinventar hinzu.',
                },

                notAuthorized: 'Verbindung blockiert.',

                forbiddenByImunify360: 'Access denied by Imunify360 Anti-bot Protection.',

                unsupportedServerVersion: {
                    title: 'Der Server kann nicht hinzugefügt werden. Wenden Sie sich bitte an den Support.',
                    description: 'Die installierte Version von Plesk ist veraltet und kann nicht hinzugefügt werden.{minSupportedVersion, select,_ {}other { Nur Plesk Obsidian {minSupportedVersion} und später werden unterstützt.}} <a>Erfahren Sie, wie man Plesk aktualisiert.</a>',
                },
                serverMisconfigured: {
                    title: 'Der Server kann nicht hinzugefügt werden. Wenden Sie sich bitte an den Support.',
                    description: 'Der Server ist nicht richtig konfiguriert und kann nicht hinzugefügt werden.',
                },
                pleskConfiguration: {
                    title: 'Wir können diesen Server nicht hinzufügen. Bitte kontaktieren Sie den Support.',
                    description: 'Diese Plesk-Version wird nicht unterstützt (unter {pleskMinVersion}) oder Plesk ist falsch konfiguriert.',
                },

                unknownError:  {
                    title: 'Der Server kann nicht hinzugefügt werden. Wenden Sie sich bitte an den Support.',
                    description: 'Unbekannter Fehler: {message}.',
                },
            },
            FirewallLinks: {
                plesk: {
                    linux: 'Anleitung zum Verwalten von lokalen Firewall-Regeln mit Plesk Firewall in Plesk für Linux.',
                    windows: 'Die Plesk Firewall (Windows)',
                },
            },
            ServerCertificateError: {
                certExpired: {
                    title: 'Für diese Plesk-Installation wurde ein abgelaufenes Zertifikat konfiguriert.',
                    description: 'Es ist nicht sicher, eine solche Konfiguration für Plesk zu verwenden und eine Verbindung mit Plesk 360 herzustellen. Wir empfehlen die Konfiguration eines selbstsignierten Zertifikats oder eines Zertifikats, das von einer Zertifizierungsstelle signiert ist. Wenn Sie auf "Weiter" klicken, nehmen Sie zur Kenntnis, dass diese Konfiguration nicht sicher ist.',
                },
                certInvalid: {
                    title: 'Das Zertifikat dieses Servers wird für einen anderen Domainnamen verwendet.',
                    description: 'Das Zertifikat wurde nicht für diesen Domainnamen ausgestellt oder Sie haben die IP-Adresse statt des gültigen Domainnamens des Servers eingegeben. <a>In diesem Artikel</a> finden Sie weitere Informationen, wenn Sie sicher sind, dass die angegebene URL gültig ist.',
                },
                certSelfSigned: {
                    title: 'Für den Remoteserver ist ein selbstsigniertes Zertifikat konfiguriert.',
                    description: 'Sie können eine Plesk-Installation zu Plesk 360 hinzufügen, aber wir empfehlen dringend die Verwendung eines Zertifikats, das von einer Zertifizierungsstelle wie Let\'s Encrypt signiert ist, um Plesk vor möglichen Sicherheitsproblemen zu schützen.',
                },
                certUntrustedCa: {
                    title: 'Die Zertifizierungsstelle des Remoteservers wird von Plesk 360 nicht als vertrauenswürdig eingestuft.',
                    description: 'Sie können eine Plesk-Installation zu Plesk 360 hinzufügen, aber wir empfehlen dringend die Verwendung eines Zertifikats, das von einer Zertifizierungsstelle wie Let\'s Encrypt signiert ist, um Plesk vor möglichen Sicherheitsproblemen zu schützen.',
                },
                agreeInvalidCertCheckbox: 'Ich stimme zu, diesen Server ohne gültiges Zertifikat hinzuzufügen',
            },
        },
        UnsecureConfirmation: {
            wouldLikeAddUnsecuredServer: 'Ich möchte einen ungesicherten Server ({hostname}) in mein Plesk 360-Konto aufnehmen',
            understandSecurityIssues: 'Ich bin mir bewusst, dass mein Server für verschiedene Probleme mit der Netzwerksicherheit anfällig ist',
            understandDataCanLost: 'Ich bin mir bewusst, dass ich so schnell wie möglich ein gültiges Zertifikat hinzufügen sollte, da sonst der Zugriff auf meine Plesk-Installation und meine Daten verloren gehen können',
            agreeNotResponsibility: 'Durch Klicken auf "Trotzdem verknüpfen" bestätige ich, dass Plesk 360 nicht für die Sicherheit dieses Servers verantwortlich ist.',
        },
    },
    DisconnectServersOperation: {
        disconnect: 'Trennen',
        noSelectedServers: 'Wählen Sie mindestens einen Server aus, der getrennt werden soll.',
        disconnectConfirmation: '{count, plural, =1{Verknüpfung mit <b>{title}</b> trennen?} one{Verknüpfung mit <b>#</b> Server trennen?} other{Verknüpfung mit <b>#</b> Servern trennen?}} Dies kann nicht rückgängig gemacht werden.',
        notifyDisconnectSuccess: '{count, plural, =1{Die Verknüpfung mit dem Server <b>{title}</b> wurde getrennt.} one{Die Verknüpfung mit <b>#</b> Server wurde getrennt.} other{Die Verknüpfung mit <b>#</b> Servern wurde getrennt.}}',
        notifyDisconnectFail: '{count, plural, =1{Die Verknüpfung mit dem Server <b>{title}</b> wurde nicht getrennt.} one{Die Verknüpfung mit <b>#</b> Server wurde nicht getrennt.} other{Die Verknüpfung mit <b>#</b> Servern wurde nicht getrennt.}}',
    },
    ViewServer: {
        title: 'Serverdetails',
        inGeneral: 'Überblick',
        domains: 'Websites',
        clients: 'Kunden',
        installationLog: 'Installationsprotokolle',
        address: 'Adresse',
        notes: 'Anmerkungen',
        product: 'Produkt',
        version: 'Version',
        unknownVersion: 'nicht bekannt',
        showErrorDetail: 'Mehr anzeigen.',
        allowedInvalidCertificateWarning: 'Die Verbindung zum Server kann aufgrund von Problemen mit der Validierung des SSL/TLS-Zertifikats unsicher sein.',
        allowedInvalidCertificateRisksNote: 'Sie haben sich entschieden, das Ergebnis der Zertifikatsvalidierung zu ignorieren und alle damit verbundenen Risiken zu akzeptieren.',
        allowedInvalidCertificateLinkToDocs: 'Weitere Informationen zu Problemen und Risiken.',
        certificateErrorStatusMessage: 'Die Verbindung zum Server wurde deaktiviert, nachdem bei der Verbindung mit dem Server {serverName} folgendes Problem mit dem SSL/TLS-Zertifikat erkannt wurde',
        certificateErrorFixDescription: 'Bitte beheben Sie den Fehler und prüfen Sie das Zertifikat erneut, um die Verbindung wiederherzustellen. Sie können das Ergebnis der Zertifikatsvalidierung auch ignorieren und damit <a>alle verbundenen Risiken</a> akzeptieren.',
        relatedRisks: 'alle damit verknüpften Risiken',
        ignoreCertificateErrorsButton: 'Zertifikatsüberprüfung ignorieren und wiederherstellen',
        ignoreCertificateConfirmation: 'Bestätigen Sie bitte, dass Sie alle Risiken im Zusammenhang mit den Zertifikatsproblemen kennen und akzeptieren.',
        confirmAndProceed: 'Bestätigen und weiter',
        recheckCertificateButton: 'Zertifikat erneut überprüfen',
        recheckResultSuccess: 'Die Verbindung wurde wiederhergestellt.',
        recheckResultCertificateError: 'Die Zertifikatsvalidierung ist fehlgeschlagen. Fehlermeldung: {errorMessage}',
        recheckResultConnectionError: 'Plesk 360 kann keine Verbindung mit {serverName} herstellen.',
        recheckResultConnectionBlocked: 'Verbindung zum Server {serverName} ist blockiert',
        connectionErrorStatusMessage: 'Die Verbindung zum Server {serverName} kann nicht korrekt hergestellt werden.',
        connectionErrorFixDescription: 'Beheben Sie den Fehler und klicken Sie auf die Schaltfläche unten, um die Verbindung wiederherzustellen.',
        updatesButton: '{product}-Aktualisierungen',
        login: {
            plesk: 'Zu Plesk wechseln',
            cpanel: 'cPanel öffnen',
            wp: 'Zu WordPress',
        },
        AccessProblemStatusMessage: {
            causesTitle: 'Der Server ist so konfiguriert, dass es unmöglich ist, eine Verbindung herzustellen. Mögliche Ursachen:',
            caseRestrictionManagement: 'IP-Adressen von Platform 360 werden für den Adminzugriff herausgefiltert (<a>Anleitung</a>)',
            casePanelIniApiSection: 'Der Remotezugriff über die Plesk API ist in panel.ini deaktiviert (<a>Anleitung</a>)',
            caseImunify360: 'Access denied by Imunify360 Anti-bot protection.',
            listPublicIps: 'Stellen Sie sicher, dass der Zugriff von den folgenden IP-Adressen erlaubt ist: <b>{publicIps}</b>.',
            fixDescription: 'Um eine erfolgreiche Verbindung mit dem Server herzustellen, passen Sie bitte die entsprechenden Konfigurationseinstellungen an. Klicken Sie anschließend auf die Schaltfläche unten, um die Verbindung zu überprüfen.',
            recheckConnectionButton: 'Verbindung erneut überprüfen',
        },

        recheckConnectionButton: 'Verbindung erneut überprüfen',
        sync: 'Synchronisieren',
        state: 'Serverstatus',
        Domains: {
            name: 'Name',
            hostingType: 'Hosting-Typ',
            virtualHostingType: 'Website',
            forwardingHostingType: 'Weiterleitung',
            noneHostingType: 'Kein Hosting',
            mainDomainType: 'Hauptdomain',
            subDomainType: 'Subdomain',
            addonDomainType: 'Add-on-Domain',
            parkedDomainType: 'Alias',
            manage: 'Verwalten',
            manageLinkUnavailableHint: 'Plesk RESTful API 1.4.0 oder höher ist erforderlich, um einen direkten Link zum Plesk Panel herzustellen. Weitere Informationen finden Sie in der Dokumentation.',
            open: 'Öffnen',
        },
        Clients: {
            name: 'Kontaktname',
            email: 'E-Mail-Adresse',
            login: 'Benutzername',
            type: 'Typ',
            status: 'Status',
            typeAdmin: 'Admin',
            typeReseller: 'Reseller',
            typeCustomer: 'Kunde',
            typeCustomerWithOwner: 'Kunde von {owner}',
            statusActive: 'Aktiv',
            statusSuspended: 'Gesperrt',
            manage: 'Verwalten',
        },
    },
    InPlaceText: {
        ok: 'Speichern (Strg + Eingabetaste)',
        cancel: 'Abbrechen (Esc)',
        edit: 'Bearbeiten',
        add: 'Hinzufügen',
    },
    ServerLogin: {
        title: 'Sie werden zum Control Panel unter {address} weitergeleitet',
        progress: 'Dies kann einige Sekunden dauern',
        clientNote: 'Zur Erinnerung: Kunden/Reseller sind in der Power-User-Ansicht nicht sichtbar.',
        errorTitle: 'Beim Versuch, eine Verbindung zum Server herzustellen, ist ein Fehler aufgetreten',
        errorDescription: 'Melden Sie sich direkt über die Serveradresse im Server an. Verwenden Sie dazu die entsprechenden Anmeldedaten. Oder versuchen Sie es später erneut, den Server über Plesk 360 zu autorisieren.',
        btnGroupLogin: 'Anmelden',
        tryAgain: 'Erneut versuchen',
        loginDirectly: 'Direkt anmelden',
        btnGroupCancel: 'Abbrechen',
        cancel: 'Abbrechen',
    },
    Team: {
        Layout: {
            title: 'Unternehmen',
            members: 'Mitglieder',
            invitations: 'Einladungen',
        },
        settings: 'Einstellungen',
        Members: {
            email: 'E-Mail-Adresse',
            fullName: 'Vollständiger Name',
            mfaStatus: 'MFA-Status',
            tags: 'Labels',
            remove: 'Entfernen',
            removeMemberConfirmation: '<b>{name}</b> hat dann keinen Zugriff mehr auf die Organisation und die gemeinsam genutzten Ressourcen, Server, Websites und Lizenzen.',
            SpecialMarks: {
                you: 'Sie',
                owner: 'Inhaber',
            },
            Onboarding: {
                title: 'Fügen Sie Ihr Team hinzu, um Ihre Organisation auf unserer Plattform zu erstellen',
                description: 'In unserer Plattform finden Sie Funktionen zur Zusammenarbeit und zum effizienten Verwalten von Organisationen. Mitglieder der jeweiligen Organisationen haben direkt Zugriff auf verknüpfte Server. Gemeinsam genutzte Passwörter und komplizierte Zugriffsregelungen gehören damit endgültig der Vergangenheit an.',
                addMembersButton: 'Mitglieder der Organisation hinzufügen',
            },
            MultifactorStatus: {
                enabled: 'MFA aktiviert',
                disabled: 'MFA nicht aktiviert',
            },
        },
        Invitations: {
            email: 'E-Mail-Adresse',
            expireAt: 'Läuft ab am',
            expired: 'Abgelaufen',
            add: 'Hinzufügen',
            revoke: 'Widerrufen',
            Onboarding: {
                title: 'Hier können Sie die Mitglieder Ihrer Organisation einladen',
                description: 'Fügen Sie Ihre Kollegen hinzu, um Server und Websites für sie freizugeben.',
                addButton: 'Neues Mitglied einladen',
            },
            ListActions: {
                resendInvitationTooltip: 'Einladung erneut senden.',
                successMessage: 'Einladungs-E-Mail wurde erneut gesendet.',
            },
            AddInvitations: {
                title: 'Neue Mitglieder zur Organisation hinzufügen',
                infoMessage: 'Laden Sie die Mitglieder Ihrer Organisation ein, indem Sie ihre E-Mail-Adressen unten eingeben.',
                infoDescription: 'Berufliche E-Mail-Adressen werden persönlichen E-Mail-Adressen vorgezogen, um die persönlichen Daten der Benutzer zu schützen.',
                emailsLabel: 'E-Mail-Adressen für Einladungslink',
                submitButton: 'Einladung senden',
                successMessage: '{count, plural, =1{Einladung an {email} wurde gesendet.} one{# Einladung wurde gesendet.} other{# Einladungen wurden gesendet.}}',
            },
            RevokeInvitationsOperation: {
                revoke: 'Widerrufen',
                noSelectedInvitations: 'Wählen Sie die Einladungen aus, die widerrufen werden sollen.',
                revokeInvitationConfirmation: '{count, plural, =1{Einladung an <b>{email}</b> widerrufen?} one{<b>#</b> Einladung widerrufen?} other{<b>#</b> Einladungen widerrufen?}} Dies kann nicht rückgängig gemacht werden.',
                successRevokeInvitation: '{count, plural, =1{Die Einladung an <b>{email}</b> wurde widerrufen.} one{<b>#</b> Einladung wurde widerrufen.} other{<b>#</b> Einladungen wurden widerrufen.}}',
            },
        },
    },

    consents: {
        sendPlatform360Announce: {
            title: 'Projekt-Updates und geschäftliche Mitteilungen',
            text: 'WebPros International GmbH und andere Unternehmen der WebPros-Gruppe können die von mir angegebenen Daten speichern und verarbeiten, um personalisierte Marketinginhalte wie Newsletter und individuelle Angebote gemäß der <a>Datenschutzrichtlinien</a> zu senden. Maßgeschneiderte Angebote können auf zusätzlichen Informationen wie Nutzungs- und Verhaltensdaten basieren (Profiling). Ich kann diese Zustimmung jederzeit in den Projekt-Einstellungen widerrufen.',
        },
    },

    components: {
        List: {
            selectAll: 'Alle auswählen',
            deselectAll: 'Auswahl aufheben',
            selectedRows: '{selectedRows, plural, one{# von {totalRows, plural, one{# Element} other{# Elementen}} ausgewählt} other{# von {totalRows, plural, one{# Element} other{# Elementen}} ausgewählt}}',
            totalRows: '{totalRows, plural, one{# Element insgesamt} other{# Elemente insgesamt}}',
            foundRows: '{foundRows, plural, one{# Element gefunden} other{# Elemente gefunden}}',
        },
        DataList: {
            groupSearch: 'Suchen',
        },
        KbInstructionsFixLink: 'Eine Anleitung zum Beheben des Problems finden Sie <a>in diesem KB-Artikel</a>.',
    },
};