// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    CopyText: {
        copied: 'Copiado para a área de transferência.',
    },
    Page: {
        ImpersonationBanner: {
            title: 'Você está se passando por uma conta diferente.',
            depersonateButton: 'Parar de se Passar Por Outro',
            auth0IdLabel: 'auth0Id:',
            teamGuidLabel: 'teamGuid:',
            successToast: 'Você não está mais se passando por uma conta diferente.',
        },
        MainMenu: {
            betaVersion: 'Versão Beta',
            servers: 'Servidores',
            websites: 'Websites',
            licenses: 'Licenças',
            monitoring: 'Monitoramento',
        },
        DotsMenu: {
            platform: 'Plesk 360',
            wpsd: 'WP Guardian',
            monitoring: 'Monitoramento',
            feedback: 'Seus comentários',
            services: 'Serviços 360',
        },
        GlobalSearch: {
            SearchButton: {
                search: 'Pesquisar...',
            },
            ClientResultGroup: {
                clients: 'Clientes',
                clientsShowMore: '{showMore, plural, one{mais # cliente...} other{mais # clientes...}}',
            },
            DomainResultGroup: {
                domains: 'Websites',
                domainsShowMore: '{showMore, plural, one{mais # website...} other{mais # websites...}}',
            },
            ServersResultGroup: {
                servers: 'Servidores',
                serversShowMore: '{showMore, plural, one{mais # servidor...} other{mais # servidores...}}',
            },
            search: 'Pesquisar...',
            noResults: 'Nada encontrado...',
        },
        UserMenu: {
            team: 'Organização',
            logout: 'Sair',
            changePassword: 'Mudar senha',
            viewProfile: 'Perfil pessoal',
            businessProfile: 'Perfil do negócio',
            apiTokens: 'Tokens de API',
        },
    },
    limit: 'Limite:',
    upgradePropertiesButton: 'Ajustar',

    common: {
        errors: {
            isNotEmpty: 'Este campo obrigatório está vazio.',
            required: 'Este campo obrigatório está vazio.',
            url: 'O valor não é um URL válido.',
            isUrl: 'O valor não é um URL válido.',
            email: 'O valor deve ser um endereço de e-mail válido.',
            isEmail: 'O valor deve ser um endereço de e-mail válido.',
            json: 'O valor deve ser JSON válido.',
            min: '{min, plural, one{O valor deve ter pelo menos # caractere.} other{O valor deve ter pelo menos # caracteres.}}',
            maxLength: '{max, plural, one{O valor deve ter no máximo # caractere.} other{O valor deve ter no máximo # caracteres.}}',
            isHttps: 'O nome do host do servidor deve começar com https://.',
            unique: 'O valor deve ser único.',
            isPublicIp: 'O nome de host do servidor deve resolver para um endereço IP público.',
            unknown: 'Erro desconhecido: {message}',
            externalRedirect: 'Algo deu errado.',
        },
    },

    auth0: {
        errors: {
            errorPasswordIsEmpty: 'Você deve digitar uma senha.',
            errorPasswordIsWrong: 'Senha incorreta.',
            errorPasswordIsTooWeak: 'A senha é muito fraca.',
            errorPasswordContainsPersonalInfo: 'A senha não deve conter informações pessoais.',
            errorPasswordTooCommon: 'A senha é muito comum.',
            errorPasswordPreviouslyUsed: 'A senha foi usada anteriormente.',
            errorInvalidOtp: 'O código inserido não é válido.',
        },
    },

    Keys: {
        headers: {
            confirmSubscription: 'Confirmar assinatura',
            payment: 'Pagamento',
        },
        general: {
            buyNow: 'Compre agora',
            confirm: 'Confirmar',
            confirmAndFeedback: 'Confirmar e Enviar Comentários',
            cancel: 'Cancelar',
        },
        tabTitle: 'Todas as licenças',
        LinkedEmails: {
            tabTitle: 'E-mails vinculados',
            emailColumn: 'E-mail',
            statusColumn: 'Status',
            totalRows: '{totalRows, plural, one{total de # e-mail} other{total de # e-mails}}',
            verifiedStatus: 'Verificado',
            addButton: 'Vincular mais e-mails',
            deleteButton: 'Desvincular e-mail',
            AddEmails: {
                stepOne: {
                    title: 'Vinculando e-mails com licenças vinculadas',
                    emailsLabel: 'Endereços de e-mail usados para comprar licenças',
                    submitButton: 'Enviar verificação',
                },
                stepTwo: {
                    title: 'As cartas de verificação foram enviadas',
                    description: 'Verifique as contas de e-mail para mensagens e siga os links de verificação. Talvez seja necessário fazer login novamente nesta conta para concluir a adição de novos e-mails vinculados. Os links expiram em 1 hora.',
                    emailColumn: 'E-mail',
                    submitButton: 'Feito',
                    resendButton: 'Reenviar',
                },
            },
            Onboarding: {
                title: 'Vincule seus endereços de e-mail a esta conta para ver mais licenças',
                description: 'Para começar a gerenciar mais licenças, insira os endereços de e-mail que foram usados para comprá-las.',
                docsLink: 'Mais informações',
                addEmailsButton: 'Adicionar endereços de e-mail',
            },
        },
        Licenses: {
            MyLicenses: {
                buyButtons: {
                    plesk: 'Comprar o Plesk',
                    monitoring: 'Comprar o 360 Monitoring',
                },
            },
            Onboarding: {
                DefaultOnboarding: {
                    title: 'Aqui você pode visualizar e gerenciar suas licenças',
                    description1: 'Clique em "Comprar uma nova licença" se você não tiver nenhuma licença.',
                    description2: 'Se você comprou licenças com outros endereços de e-mail, clique em "Vincular licenças existentes" para adicioná-las.',
                    docsLink: 'Mais informações',
                    buyButton: 'Compre uma nova licença',
                    addEmailsButton: 'Vincule licenças existentes',
                },
            },
        },
        CB: {
            paymentInfo: 'A Loja Online Plesk operada por {companyName} processará este pedido usando o método de pagamento associado à assinatura selecionada',
            confirmSubscription: 'Ao enviar este pedido, você concorda que está se inscrevendo em um produto de assinatura que será cobrado em intervalos regulares. O uso continuado do produto adquirido depende do pagamento da assinatura. Detalhes sobre o preço e a frequência do faturamento aparecem no carrinho de compras acima.',
            buyInfo: 'Ao enviar este pedido, você concorda com os <termsLink>Termos e Condições</termsLink> da Cleverbridge e confirma que leu e compreendeu a <policyLink>Política de Privacidade</policyLink> da Cleverbridge e seu <revocationLink>Direito de revogação</revocationLink> como consumidor.',
            links: {
                terms: 'Termos e Condições',
                contact: 'Contato',
                revocation: 'Direito de revogação',
                security: 'Segurança',
                legal: 'Informações Legais',
                policy: 'Política de Privacidade',
            },
            footer: 'Você está fazendo o pedido através de nosso revendedor on-line {companyName}. O processamento do pagamento e o atendimento do pedido são feitos por {companyName}, {companyAddress}.',
            nonUS: {
                companyName: 'Cleverbridge AG',
                companyAddress: 'Gereonstr. 43-65, 50670 Colônia, Alemanha',
            },
            US: {
                companyName: 'Cleverbridge, Inc.',
                companyAddress: '350 N Clark, Suite 700, Chicago, IL 60654, Estados Unidos',
            },
        },
        Renew: {
            drawer: {
                title: 'Reativar a assinatura',
            },
            form: {
                submitButton: 'Confirmar Renovação',
                confirmText: 'Ao clicar no botão abaixo, você confirma a renovação da assinatura da WebPros International GmbH "{product}"',
            },
            buttonTitle: 'Reativar assinatura',
            successMessage: 'A assinatura de {product} foi renovada. Sua chave de licença demorará um pouco para se tornar ativa',
            unavailable: 'Não é possível renovar esta assinatura (por exemplo, ela pode ter sido descontinuada). Por favor, compre um novo produto.',
        },
        Adjust: {
            delayedChangesProducts: 'Até o final do período de cobrança ({date}), o(s) seguinte(s) limite(s) permanecerão os mesmos',
        },
        Cancel: {
            buttonTitle: 'Cancelar subscrição',
            successMessage: 'A assinatura de {product} foi cancelada. Você pode continuar usando até {date}',
            popover: {
                title: 'Cancelar inscrição de {product}',
                message: 'Isso interromperá cobranças futuras. Você pode continuar usando a licença até {date} (o período de cobrança atual não será reembolsado).',
            },
        },
        Payment: {
            card: {
                expired: 'Seu cartão expirou',
            },
        },
        Tours: {
            moreDetails: {
                editPaymentMethod: {
                    text: 'Você pode alterar a forma de pagamento nas configurações da sua assinatura',
                    confirm: 'Entendi',
                },
            },
        },
    },

    ConfirmationPopover: {
        cancel: 'Cancelar',
    },

    ZendeskChat: {
        confirmationPopover: {
            description1: 'A Platform 360 usa ZendeskChat, ums aplicação de terceiros.',
            description2: 'Ao prosseguir, concordo em usar o ZendeskChat, uma aplicação de terceiros. Isto pode envolver a transferência dos meus dados pessoais (por exemplo, endereço IP) para terceiros dentro ou fora da Europa. Para mais informações, leia nossa <a>Política de Privacidade</a>.',
            confirmButton: 'Iniciar o ZendeskChat',
        },
        supportFormPopover: {
            description: 'Nenhum agente está disponível no momento. Aguarde ou use nosso formulário de ticket para enviar uma solicitação.',
            confirmButton: 'Abrir Formulário',
        },
        ChatButton: {
            title: 'Suporte',
        },
    },

    keysPageTitle: 'Licenças',
    goBack: 'Voltar para a Tela Inicial',
    keysPageDescription: 'Gerencie licenças compradas na Loja Online Plesk.',
    keysToolbarBuyTitle: 'Comprar',
    keysToolbarFilterTitle: 'Filtrar',

    keyAdditionalActionsToolbarTitle: 'Ações adicionais',
    keyMoreDetails: 'Mais Detalhes',

    keyForeignCart: 'Esta licença não é sua',
    keyForeignDescriptionTitle: 'Você não pode gerenciar a licença porque ela pertence a outra conta. Isso pode acontecer pelos seguintes motivos:',
    keyForeignDescriptionHosting: 'Sua licença Plesk pertence ao seu provedor de hospedagem.',
    keyForeignDescriptionWrongEmail: 'Você comprou a licença usando uma conta diferente. Para ver e gerenciar a licença, faça login com o e-mail correspondente a essa conta.',

    keyTrialDescription: 'Esta é uma licença de teste. Solicitar uma {link}?',
    keyTrialDescriptionLink: 'licença paga',

    keyTerminatedCart: 'Esta licença foi encerrada',
    keyTerminatedDescription: 'Esta licença não é mais válida. ',
    keyTerminatedDescriptionRetail: 'Você não será mais cobrado por isso.',

    keyPartnerCart: 'Esta licença não foi comprada na Loja Online Plesk.',
    keyPartnerDescription: 'Nenhum detalhe de cobrança pode ser mostrado para esta licença.',

    keyLicenseDetailsTitle: 'Detalhes da licença',
    keyLicenseDetailsCreationDate: 'Data de criação:',
    keyLicenseDetailsActivationCode: 'Código de ativação:',
    keyBillingDetailsTitle: 'Detalhes de faturamento',
    keyBillingDetailsTrial: 'Esta é uma licença de avaliação, que expira em {date}. <a>Compre uma licença</a>.',
    keyBillingDetailsNotRetail: 'Nenhum detalhe de cobrança pode ser mostrado para esta licença porque ela não foi comprada na Loja Online Plesk.',
    keyBillingDetailsTerminated: 'Sua assinatura foi cancelada.',
    keyBillingDetailsSubscriptionId: 'ID de Inscrição:',
    keyBillingDetailsCycle: 'Ciclo de faturamento:',
    keyBillingDetailsForeign: 'Nenhum detalhe de cobrança pode ser mostrado para esta licença porque ela pertence a outra conta.',
    keyServerDetailsTitle: 'Detalhes do servidor',
    keyServerDetailsAddresses: 'Endereços IP do servidor:',
    keyServerDetailsOs: 'SO do Servidor:',
    keyServerDetailsProductVersion: 'Versão do produto:',
    keyServerDetailsAbsent: 'Esta licença não parece estar instalada em um servidor Plesk ainda.',

    pleskUpgradedMessage: 'Você fez o upgrade da licença {from} ({id}) para {to}.',
    propertyUpgradedIncreaseMessage: 'A capacidade de licença de {keyName} foi aumentada para {to} {name}',
    propertyUpgradedDecreaseMessage: 'A capacidade de licença de {keyName} foi reduzida para {to} {name}. As alterações entrarão em vigor no novo período de faturamento',
    keyUpgradedMessage: 'Você fez o upgrade da licença {from} ({id}) para {to}. Sua licença {to} ({id}) está atribuída à licença {parentId} Plesk.',
    keyAddedMessage: 'Você comprou a licença {addon}. Ele é atribuído à licença {parent}: {parentId}.',
    refreshSuggestion: 'Se você não ver essas alterações, <a>atualize a página</a>.',

    upsellPageTitle: 'Adicionar nova extensão à sua licença',
    yearlyPrices: 'Preços anuais',
    monthlyPrices: 'Preços mensais',
    subscriptionForBillingPeriodNotFound: 'Os períodos de cobrança da licença de extensão e da assinatura não coincidem ou não existem licenças Plesk para esse período de cobrança. Escolha o período de cobrança correspondente ou compre uma licença Plesk na Loja Online.',
    subscriptionToUpgradeHeader: 'Assinatura para fazer o upgrade',
    selectedSubscription: '{subscriptionId} (moeda: {billingDate}; ciclo de cobrança: {billingCycle})',
    toOnlineStoreButton: 'Para Loja Online',
    why: 'Por quê?',
    whyCharge: 'O valor cobrado foi rateado com base no número de dias restantes no período de cobrança atual.',
    chargeNow: 'Agora você será cobrado <b>{grossPrice}</b> (IVAincluso {vatPrice}).',
    chargeNowShort: 'Você será cobrado agora',
    inclVatShort: 'IVA incluso {vatPrice}',
    delayedCharge: 'O limite permanecerá em {limit} {property} até o final do período de cobrança.',
    nextCharge: '<b>{grossPrice}</b> (VAT incl. {vatPrice}), começando em {nextBillingDate}',
    nextChargeShort: 'A partir de {nextBillingDate}, você será cobrado',
    noProductForBillingPeriod: 'Não há produtos para o período de cobrança selecionado.',
    confirmationPopupTitle: 'Faça o upgrade da sua assinatura atual',

    upgradePageTitle: 'Faça o Upgrade da Licença',
    upgradePageDescription: 'Aqui você pode fazer o upgrade da sua licença.',
    upgradePanelBeforeTitle: 'Antes de fazer o Upgrade',
    upgradePanelOptionsTitle: 'Opções de Upgrade',
    upgradePanelAfterTitle: 'Depois de Fazer o Upgrade',
    upgradePanelOptionsRecommendationsTitle: 'Confira nossas recomendações:',
    upgradePanelOptionsRecommendationsAlt: 'Ou {link} abaixo.',
    upgradePanelOptionsRecommendationsAltLink: 'navegue por todas as opções possíveis',
    upgradeSuggestion: 'Adicione produtos à sua licença',
    upgradeSuggestionFreeCost: 'Experimente gratuitamente!',
    upgradeSuggestionDescription: '(consulte os detalhes do produto para obter informações)',
    upgradeMultiOfferSuggestion: 'Escolha um plano diferente',
    upgradeConfirm: 'Compre agora',
    upgradeAccept: 'Confirmar',
    upgradeBack: 'Voltar à Lista de Produtos',
    planUpdate: 'Atualização do plano',
    adjustPending: 'O ajuste para essa licença já está pendente. Tente novamente em 30 segundos.',
    waitAMoment: 'Espere um momento',
    upgradeConfirmPriceRecalculation: 'Agora será cobrado {chargeInfo} (preço proporcional total com base no número de dias restantes até a próxima data de cobrança, {nextBillingDate}). A partir de {nextBillingDate}, será cobrado {renewalInfo} por este produto.',
    buyNowNotification: 'Clique em "Comprar agora" para prosseguir com a compra ou upgrade da licença. Você será redirecionado para a Loja Online Plesk, operada pela Cleverbridge AG. A loja usará o método de pagamento utilizado anteriormente para comprar ou fazer o upgrade da sua licença. Todos os pagamentos serão processados pela Cleverbridge AG.',
    paymentDetailsLinkText: 'Visualize e edite os detalhes de pagamento desta transação.',
    upgradeDoneRedirectToPleskTitle: 'Compra bem-sucedida',
    upgradeDoneRedirectToPleskDescription: 'A instalação da licença pode levar algum tempo.',
    upgradeDoneRedirectToPleskButton: 'Voltar para Plesk',
    upgradeDoneRedirectToKeys: 'Ver minhas licenças',
    total: 'TOTAL',
    vat: 'IVA',
    newTotal: 'NOVO TOTAL',
    newVat: 'NOVO IVA',

    firstLoginWithoutEmailVerification: 'Obrigado por se inscrever no serviço Plesk 360. Enviamos um e-mail com um link de verificação. Siga o link para verificar sua conta e fazer login. Então você pode começar a gerenciar suas licenças no Plesk 360.',
    pleaseVerifyEmailTitle: 'Verifique sua conta Plesk 360',
    secondLoginWithoutEmailVerification: 'Você está tentando fazer login em uma conta que ainda não foi verificada. Verifique na sua caixa de entrada um e-mail com um link de verificação. Siga o link para verificar sua conta e fazer login. Então você pode começar a gerenciar suas licenças no Plesk 360.',
    userBlocked: "A conta foi suspensa devido a uma violação do acordo do usuário. Se você acredita que isso é um erro, envie um e-mail para este endereço {emailLink}.",

    errorDefault: 'Algo deu errado. Por favor, tente novamente mais tarde.',
    errorDefaultWithReqId: 'Algo deu errado. Por favor, tente novamente mais tarde. Identificação do Pedido: {requestId}.',
    errorTooManyRequests: 'Limite de chamadas de API atingido para este endereço IP. Por favor, tente novamente em um minuto.',
    errorTokenRenewal: 'Sua sessão expirou. Faça login novamente.',
    errorNetworkIssue: 'Parece que você está tendo problemas para se conectar à rede no momento.',

    errorKAUnavailable: 'O serviço está temporariamente indisponível. Por favor, tente novamente mais tarde.',
    errorKAUnavailableWithReqId: 'O serviço está temporariamente indisponível. Por favor, tente novamente mais tarde. Identificação do Pedido: {requestId}.',

    footerPurposeExtension: {
        text: 'Encontre mais extensões aqui <a>Catálogo de Extensões Plesk</a>.',
    },

    filterAll: 'Todas as Licenças',
    filterActive: 'Somente Ativo',
    renewalCost: 'Custo de renovação:',
    nextBillingDate: 'Próxima data de cobrança:',
    canceledSubscriptionDate: 'A assinatura foi cancelada. Sua licença é válida até',
    paymentHistory: 'histórico de pagamento',
    licenseStatus: 'Situação da licença:',
    upgradeButton: 'Upgrade',
    upsellButton: 'Adicionar Extensão',
    upsellText: 'Uma nova extensão será adicionada ao seu Plesk',
    billingPeriod: 'Período de Pagamento',
    features: 'Características adicionais:',
    noFeatures: 'Sem recursos adicionais',
    toPlesk: 'Ir para o Plesk',
    expiresNote: '(expira em {date})',
    startingFrom: 'começando de',
    billingIntervalMonth: '{value} / mês',
    billingIntervalYear: '{value} / ano',
    billingIntervalOnce: '{value} / uma vez',
    perOne: 'por unidade',

    paymentMethod: 'Forma de pagamento',
    editPaymentMethod: 'Editar forma de pagamento',
    paymentCard: '{paymentType} xxxxxxxxxxxx{cardLastFourDigits} {cardExpirationMonth}/{cardExpirationYear}',

    added: 'adicionado',
    addresses: 'endereços',
    back: 'Voltar',

    monthly: 'Mensal',
    on: 'em',
    plesk: 'Plesk',
    product: 'Produto',
    price: 'Preço',
    products: 'Produtos',
    purchased: 'Comprado',
    select: 'Selecionar',
    sinceNote: '(desde {date})',
    show: 'Mostrar',
    upgrade: 'upgrade',
    yearly: 'Anual',

    isEmpty: 'Este campo é obrigatório.',
    errorPasswordIsEmpty: 'A senha é necessária.',
    errorPasswordIsWrong: 'Senha incorreta.',
    errorPasswordIsTooWeak: 'A senha é muito fraca.',
    errorEmailOrPasswordIsIncorrect: 'Senha ou e-mail incorretos.',
    errorIncorrectEmail: 'Endereço de e-mail incorreto.',
    errorUserDoesntExist: 'O usuário não existe.',
    errorUserExist: 'O usuário já existe.',
    errorUserIsNotVerified: 'O usuário existe, mas não foi verificado. Enviamos um novo e-mail de verificação. Verifique sua caixa postal.',
    errorUnknownError: 'Erro desconhecido.',
    errorEmailIsAlreadyLinked: 'Este endereço de e-mail já está vinculado à sua conta.',
    errorLinkIsOutdated: 'Este link de verificação expirou.',
    errorBounceOrComplaint: 'Este endereço de e-mail não existe ou bloqueou nossa mensagem como spam. Entre em contato com a equipe de suporte do Plesk 360.',
    errorUserEmailAlreadyVerified: 'Este endereço de e-mail já foi verificado.',
    errorDoResourceIdExist: 'Esta conta Digital Ocean já está registrada.',
    errorCopyToClipboard: 'Ocorreu um erro de cópia, tente copiar manualmente.',

    PrivacyPolicy: {
        privacyPolicyText: 'A WebPros International GmbH processará os dados fornecidos para processar sua solicitação de acordo com sua <a>Política de Privacidade</a>.',
    },

    Auth: {
        errors: {
            userDoesntExist: 'O usuário não existe.',
            bounceOrComplaint: 'Este endereço de e-mail não existe ou bloqueou nossa mensagem como spam. Entre em contato com a equipe de suporte do Plesk 360.',
            userIsNotVerified: 'O usuário existe, mas não foi verificado. Enviamos um novo e-mail de verificação. Verifique sua caixa postal.',
            userExist: 'O usuário já existe.',
            errorPasswordIsEmpty: 'A senha é necessária.',
            errorPasswordIsWrong: 'Senha incorreta.',
            errorPasswordIsTooWeak: 'A senha é muito fraca.',
        },
        AuthContainer: {
            tabs: {
                login: 'Login',
                signUp: 'Inscrever-se',
            },
        },
        PageIntro: {
            PlatformPageIntro: {
                title: 'Bem-vindo ao Plesk 360',
                text1: 'Capacitando Construtores para Monitorar e Gerenciar Servidores com Facilidade.',
                text2: 'Obtenha total controle através do <b>monitoramento integrado de websites e servidores</b> para acompanhar o desempenho e evitar tempo de inatividade.',
                text3: 'Faça o upgrade para acessar <b>painéis configuráveis</b>, alertas personalizados, verificações a cada minuto, histórico de rede e muito mais.',
                text4: 'Gerencie seu <b>inventário do servidor</b>, visualize todas as suas licenças Plesk e compre ou faça o upgrade de assinaturas.',
                text5: 'Tudo a partir de uma plataforma em uma única conta.',
            },
            MonitoringPageIntro: {
                title: 'Bem-vindo ao 360 Monitoring',
                text1: 'Capacitando Construtores para Monitorar e Gerenciar Servidores com Facilidade.',
                text2: '<b>Tempo de atividade e monitoramento de servidor</b> totalmente integrado para monitorar o desempenho e evitar tempo de inatividade.',
                text3: '<b>Painéis configuráveis</b>, alertas personalizados, verificações a cada minuto, métricas de E/S e de servidor, desempenho de rede e métricas personalizadas através de plugins. ',
                text4: 'Multi-cloud e plataforma <b>compatível</b>.',
            },
        },
        SignUp: {
            signUp: 'Inscrever-se',
            loginLink: 'Ir para a tela de login',
            emailLabel: 'E-mail',
            emailDescription: 'Melhor usar o e-mail que você usou para comprar Plesk e extensões',
            passwordLabel: 'Senha',
            privacyPolicyAgreementTitle: 'Eu concordo com os <a>{termsLink}</a>',
            termsPlatform: 'Termos de uso do Plesk 360',
            termsMonitoring: 'Termos de Uso do 360 Monitoring',
            privacyPolicyAgreementDescription: 'Para criar e manter minha conta na plataforma, a WebPros International GmbH e outras empresas do grupo WebPros processarão as informações fornecidas em seus sistemas internos conforme a <a>Política de Privacidade</a> enquanto minha conta estiver ativa.',
            verifyAccountTitle: 'Verifique sua conta',
            verifyAccountText: 'Enviamos um e-mail com um link de verificação para {email}. Clique no link para verificar sua conta e fazer login.',
            resendDescription: 'Não recebeu o e-mail? Verifique sua pasta de spam ou clique em Reenviar para obter um novo.',
            resendLabel: 'Reenviar',
            retryCountdown: 'O reenvio é possível em {time}',
            SignUpByInvitation: {
                emailDescription: 'Você será cadastrado com o e-mail acima',
                invitationSucceed: 'Você se inscreveu com sucesso. Por favor, faça o login agora.',
            },
            SignUpForm: {
                NameFormField: {
                    firstNamePlaceholder: 'Primeiro nome',
                    lastNamePlaceholder: 'Sobrenome',
                    nameLabel: 'Nome',
                },
            },
        },
        Login: {
            signUpButton: 'Inscrever-se',
            emailLabel: 'E-mail',
            passwordLabel: 'Senha',
            submitButton: 'Log In',
            forgotYourPasswordLink: 'Esqueceu sua senha?',
        },
        ResetPassword: {
            title: 'Redefinir senha',
            text: 'Digite seu endereço de e-mail e nós lhe enviaremos um e-mail com um link de redefinição de senha.',
            emailLabel: 'E-mail:',
            submitButton: 'Enviar',
            successTitle: 'E-mail de Redefinição de Senha Enviado',
            successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your {brand} and set a new password.',
            successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
            resendButton: 'Reenviar',
            loginForm: 'Faça o login agora',
            retryCountdown: 'O reenvio é possível em {time}',
        },
    },

    Footer: {
        copyright: '© {year} WebPros International GmbH. Todos os direitos reservados. Plesk e o logotipo Plesk são marcas comerciais da WebPros International GmbH.',
        followPlesk: 'Siga o Plesk:',
        followCPanel: 'Siga o cPanel:',
        company: 'Empresa',
        aboutCPanel: 'Sobre cPanel',
        aboutPlesk: 'Sobre Plesk',
        legal: 'Jurídico',
        cookiehub: 'Configurações de Cookies',
        privacyPolicy: 'Política de Privacidade',
        impressum: 'Impressum',
        knowledgeBase: 'Base de Conhecimento',
        documentation: 'Documentação',
        helpCenterCPanel: 'Central de Ajuda cPanel',
        helpCenterPlesk: 'Central de Ajuda do Plesk',
        contactUs: 'Contato',
        pleskLifecyclePolicy: 'Política de Ciclo de Vida Plesk',
        community: 'Comunidade',
        blogCPanel: 'Blog cPanel',
        blogPlesk: 'Blog Plesk',
        forumsCPanel: 'Fóruns cPanel',
        forumsPlesk: 'Fóruns do Plesk',
        cPanelUniversity: 'cPanel University',
        pleskUniversity: 'Plesk University',
    },

    Form: {
        requiredLegend: 'Os campos obrigatórios',
        submitButton: 'OK',
        cancelButton: 'Cancelar',
    },

    FormField: {
      addMore: 'Adicione mais um',
      remove: 'Remover',
      clear: 'Limpar',
    },

    FormFieldPassword: {
        improvePassword: 'Para uma senha mais segura:',
        passwordTooShort: 'Crie uma senha mais longa.',
        lettersLowerCase: 'Use pelo menos um caractere minúsculo.',
        lettersUpperCase: 'Use pelo menos um caractere maiúsculo.',
        numbers1: 'Use pelo menos um número.',
        numbers3: 'Use pelo menos três números.',
        specialChar1: 'Use pelo menos um caractere especial (por exemplo: !, @, #, $, % ,^, &, *, ?, _, ~).',
        specialChar2: 'Use pelo menos dois caracteres especiais (por exemplo: !, @, #, $, %, ^, &, *, ?, _, ~).',
        comboUpperAndLower: 'Use caracteres maiúsculos e minúsculos.',
        comboLettersAndNumbers: 'Use números e caracteres.',
        comboLettersNumbersSpecial: 'Use números, caracteres e caracteres especiais.',
        yourPasswordIsStrong: 'Você pode torná-lo ainda mais forte aumentando seu comprimento.',
        passwordStrength: 'A força da senha é %%strength%%.',
        strengthVeryStrong: 'MUITO FORTE',
        strengthStrong: 'FORTE',
        strengthVeryWeak: 'MUITO FRACA',
        strengthWeak: 'FRACA',
        strengthMedium: 'MÉDIA',
        hidePassword: 'Esconder a senha',
        showPassword: 'Mostrar senha',
        generateButton: 'Gerar',
        generateButtonHint: 'Gerar uma senha forte',
    },

    Pagination: {
        totalShort: 'de %%total%%',
        itemsPerPage: 'Itens por página',
        all: 'Todos',
        total: 'Página %%current%% de %%total%%',
    },

    ListEmptyView: {
        filteredTitle: 'Não foi possível encontrar nenhum item que corresponda ao filtro',
        emptyTitle: 'Parece que não há nada aqui',
        filteredDescription: 'Refine sua consulta ou remova alguns dos critérios do filtro e tente novamente.',
    },

    Dialog: {
        cancelButton: 'Cancelar',
    },
    keyNotUnderOnlineStore: 'Você não pode fazer o upgrade da licença no Plesk 360 porque a licença não foi comprada na Loja Online Plesk. Se você comprou a licença do seu provedor de hospedagem, entre em contato com eles para obter opções de atualização.',
    notOwnLicense: 'Você não pode fazer o upgrade da licença no Plesk 360 porque a licença pertence a uma conta diferente. Se você comprou a licença do seu provedor de hospedagem, entre em contato com eles para obter opções de upgrade.',
    subscriptionNotFound: 'Não foi possível encontrar os dados da assinatura',
    unableToUpgrade: 'O upgrade não está disponível. Entre em contato com o Suporte Plesk',
    unknownError: 'O serviço está temporariamente indisponível. Por favor, tente novamente mais tarde.',
    pendingOperationFound: 'Não é possível realizar a operação atual porque a anterior (por exemplo, a alteração da forma de pagamento) não foi concluída. Termine a operação anterior para prosseguir.',

    LinkEmail: {
        title: 'E-mail do link',
        tryAgain: 'Tente novamente',
    },

    GaOptOutLink: {
        gaSuccess: 'O Google Analytics parou de rastrear.',
    },

    Servers: {
        sync: 'Sincronizar',
        syncNoSelection: 'Você não selecionou nenhum servidor para sincronizar. Deseja sincronizar todos?',
        syncAll: 'Sincronizar tudo',
        serverNameColumn: 'Nome do servidor',
        productColumn: 'Produto',
        panelColumn: 'Painel de controle de hospedagem',
        stateColumn: 'Estado do servidor',
        stateLastSync: 'Última sincronização {duration}',
        stateLastSyncNow: 'Última sincronização agora',
        login: {
            plesk: 'Ir para o Plesk',
            cpanel: 'Ir para o cPanel',
            wp: 'Ir para WordPress',
        },
        forceSyncScheduledSuccess: '{count, plural, =1{O <b>{title}</b> servidor está programado para ser sincronizado.} one{<b>#</b> servidor está programado para ser sincronizado.} other{<b>#</b> servidores estão programados para serem sincronizados.}}',
        updateServer: 'Atualizar {product}',
        cancel: 'Cancelar',
        Toolbar: {
            add: 'Adicionar',
            disconnect: 'Desconectar',
            sync: 'Sincronizar',
            groupSearch: 'Pesquisar',
        },
        Onboarding: {
            title: 'Aqui você pode adicionar e gerenciar seus servidores Plesk',
            description: 'Adicione seus servidores para ter acesso com um clique ao painel de controle.',
            docsLink: 'Mais informações',
            addServersButton: 'Conectar servidores',
            feedbackButton: 'Deixar seus comentários',
            installPleskButton: 'Instale o Plesk em um novo servidor',
        },
        FeedbackButton: {
            heading: 'Deixar seus comentários',
            subHeading: 'Isso nos ajudará com o desenvolvimento futuro.',
        },
        Tour: {
            next: 'Próximo',
            previous: 'Anterior',
            gotit: 'Entendi',
            step1: {
                title: 'Apresentando o Inventário do Servidor',
                description: 'Uma ferramenta para gerenciamento de múltiplos servidores no Plesk 360. Adicione seus servidores Plesk com segurança a partir da guia Servidor ou do Plesk.',
            },
            step2: {
                title: 'Apresentando o Inventário do Servidor',
                description: 'A guia Servidor também contém listas pesquisáveis de todos os clientes e domínios hospedados em seus servidores.',
            },
        },
        AddServerWithExternalRedirectDialog: {
            products: {
                plesk: 'Plesk',
                cpanel: 'cPanel',
                wp: 'WordPress',
            },
            title: 'Confirme a conexão do seu servidor ao "Plesk 360"',
            description: 'Seu painel de controle do {product} em <b>{address}</b> será conectado à conta <b>{email}</b> no Plesk 360. Certifique-se de que esta conta pertence a você antes de confirmar!',
            accessTitle: 'O que será permitido',
            accessMessage1 : 'O <b>Plesk 360</b> terá acesso administrativo ao painel de controle do Plesk em <b>{address}</b>.',
            accessMessage2 : 'O <b>Plesk 360</b> permitirá a conta <b>{email}</b>:',
            accessItem1: 'faça login no Plesk como administrador ou qualquer outro usuário sem enviar credenciais,',
            accessItem2: 'recuperar listas de clientes e websites hospedados no servidor,',
            accessItem3: 'ativar o monitoramento externo do servidor,',
            accessItem4: '(no futuro) execute outras ações.',
            buttons: {
                confirm: 'Confirmar',
                confirmAnyway: 'Confirmar mesmo assim',
                nextStep: 'Próxima etapa',
                deny: 'Negar',
            },
            enableIpRestrictionTitle: 'Detalhes da chave API',
            fields: {
                enableIpRestriction: 'Ativar restrição de endereço IP da chave de API, IPs permitidos:',
            },
        },
        AddServerControlStatus: {
            info: {
                access: 'O servidor deve ser acessível pela Internet.',
                certificate: 'O servidor deve estar protegido com um certificado SSL/TLS válido.',
            },
            success: {
                access: 'A instalação do Plesk é acessível da Internet.',
            },
            ServerAccessError: {
                urlIsRequired: 'Insira um URL de servidor.',
                urlMaxLength: "{max, plural, one{A URL do servidor deve ter menos de # caracteres.} other{A URL do servidor deve ter menos de # caracteres.}}",

                urlInvalid: {
                    title: 'Não podemos trabalhar com o endereço do servidor fornecido.',
                    description: 'Geralmente, isso acontecia ao fornecer "http" em vez de "https" no endereço ou ao digitar incorretamente a URL, como falta de domínio de nível superior (.com, .org etc).',
                },

                urlPrivateIp: {
                    title: 'Infelizmente, não conseguimos acessar seu servidor pois ele não está disponível.',
                    description: 'O endereço do host do servidor não é um endereço IP público.',
                },

                serverAlreadyAdded: 'Este servidor já foi adicionado. <a>Abrir detalhes do servidor.</a>',

                domainNotResolved: {
                    title: 'Infelizmente, não conseguimos acessar seu servidor pois ele não está disponível.',
                    description: 'O nome do domínio do endereço do servidor não pode ser resolvido pelo Plesk 360.',
                },

                domainResolvedPrivateIp: {
                    title: 'Infelizmente, não conseguimos acessar seu servidor pois ele não está disponível.',
                    description: 'Certifique-se de ter um IP público atribuído para este domínio.',
                },

                timeout: {
                    title: 'Infelizmente, não conseguimos acessar seu servidor pois ele não está disponível.',
                    description: 'Verifique suas configurações de firewall e permita a porta especificada em {publicIps}.',
                },

                connectionAborted: {
                    title: 'Infelizmente, não conseguimos acessar seu servidor pois ele não está disponível.',
                    description: 'Verifique suas configurações de firewall e permita a porta especificada em {publicIps}.',
                },

                connectionRefused: {
                    title: 'A conexão de rede foi recusada.',
                    description: 'Verifique o domínio e a porta especificados.',
                },

                badProtocol: 'O endereço fornecido deve ser protegido por um certificado SSL válido.',

                notAuthenticated: {
                    title: 'A conexão não está autorizada pelo servidor remoto.',
                    description: 'Adicione o servidor ao Inventário do servidor novamente.',
                },

                notAuthorized: 'Conexão bloqueada.',

                forbiddenByImunify360: 'Access denied by Imunify360 Anti-bot Protection.',

                unsupportedServerVersion: {
                    title: 'O servidor não pode ser adicionado. Entre em contato com o suporte.',
                    description: 'A versão instalada do Plesk está desatualizada e não pode ser adicionada.{minSupportedVersion, select, _ {} other { Somente Plesk Obsidian {minSupportedVersion} e posteriores são suportados.}} <a>Saiba como atualizar o Plesk.</a>',
                },
                serverMisconfigured: {
                    title: 'O servidor não pode ser adicionado. Entre em contato com o suporte.',
                    description: 'O servidor está configurado incorretamente e não pode ser adicionado.',
                },
                pleskConfiguration: {
                    title: 'Não é possível adicionar este servidor. Contate o suporte.',
                    description: 'A versão do Plesk não é suportada (abaixo de {pleskMinVersion}) ou o Plesk está mal configurado.',
                },

                unknownError:  {
                    title: 'O servidor não pode ser adicionado. Entre em contato com o suporte.',
                    description: 'Erro desconhecido: {message}.',
                },
            },
            FirewallLinks: {
                plesk: {
                    linux: 'Como gerenciar as regras de firewall local usando o Plesk Firewall no Plesk para Linux.',
                    windows: 'O Plesk Firewall (Windows)',
                },
            },
            ServerCertificateError: {
                certExpired: {
                    title: 'Esta instalação Plesk tem um certificado vencido configurado.',
                    description: 'Não é seguro usar tal configuração do Plesk e conectá-la ao Plesk 360. Recomendamos configurar um certificado autoassinado ou assinado por CA. Ao clicar em "Próximo", você concorda que essa configuração é insegura.',
                },
                certInvalid: {
                    title: 'O certificado deste servidor está confirmado para funcionar com outro nome de domínio.',
                    description: 'O certificado não foi emitido para este nome de domínio ou você inseriu o endereço IP em vez do nome de domínio do servidor válido. Incluímos mais informações <a>neste artigo</a> se você tiver certeza de que a URL fornecida é válida.',
                },
                certSelfSigned: {
                    title: 'O servidor remoto tem um certificado autoassinado configurado.',
                    description: 'É possível adicionar uma instalação do Plesk ao Plesk 360, mas recomendamos usar um certificado assinado por CA, como o Let\'s Encrypt, para proteger seu Plesk de possíveis problemas de segurança.',
                },
                certUntrustedCa: {
                    title: 'O CA do certificado do servidor remoto não é confiável pelo Plesk 360.',
                    description: 'É possível adicionar uma instalação do Plesk ao Plesk 360, mas recomendamos usar um certificado assinado por CA, como o Let\'s Encrypt, para proteger seu Plesk de possíveis problemas de segurança.',
                },
                agreeInvalidCertCheckbox: 'Concordo em adicionar este servidor sem um certificado válido',
            },
        },
        UnsecureConfirmation: {
            wouldLikeAddUnsecuredServer: 'Desejo adicionar um {hostname} de servidor não seguro à minha conta Plesk 360',
            understandSecurityIssues: 'Eu entendo que meu servidor está vulnerável a diversos problemas de segurança de rede',
            understandDataCanLost: 'Entendo que eu deveria adicionar um certificado válido assim que possível, caso contrário, o acesso à minha instalação e aos dados do Plesk pode ser perdida',
            agreeNotResponsibility: 'Ao clicar em “Conecte mesmo assim”, concordo que o Plesk 360 não é responsável pela segurança deste servidor.',
        },
    },
    DisconnectServersOperation: {
        disconnect: 'Desconectar',
        noSelectedServers: 'Selecione um ou mais servidores para desconectar.',
        disconnectConfirmation: '{count, plural, =1{Desconectar <b>{title}</b>?} one{Desconectar <b>#</b> servidor?} other{Desconctar<b>#</b> servidores?}} Você não pode desfazer esta ação.',
        notifyDisconnectSuccess: '{count, plural, =1{O servidor<b>{title}</b> foi desconectado.} one{<b>#</b> servidor foi desconectado.} other{<b>#</b> servidores foram desconectados.}}',
        notifyDisconnectFail: '{count, plural, =1{O servidor<b>{title}</b> não foi desconectado.} one{<b>#</b> servidor não foi desconectado.} other{<b>#</b> servidores não foram desconectados.}}',
    },
    ViewServer: {
        title: 'Detalhes do servidor',
        inGeneral: 'Visão geral',
        domains: 'Websites',
        clients: 'Clientes',
        installationLog: 'Registros de instalação',
        address: 'Endereço',
        notes: 'Notas',
        product: 'Produto',
        version: 'Versão',
        unknownVersion: 'desconhecida',
        showErrorDetail: 'Mostrar mais.',
        allowedInvalidCertificateWarning: 'A conexão com o servidor pode não ser segura devido a problemas de validação do certificado SSL/TLS.',
        allowedInvalidCertificateRisksNote: 'Você optou por ignorar o resultado da validação do certificado e aceitou todos os riscos relacionados.',
        allowedInvalidCertificateLinkToDocs: 'Saiba mais sobre problemas e riscos.',
        certificateErrorStatusMessage: 'A conexão com o servidor foi desativada depois que o seguinte problema de certificado SSL/TLS foi detectado ao se conectar ao servidor {serverName}',
        certificateErrorFixDescription: 'Corrija o erro e verifique novamente o certificado para restaurar a conexão. Ou ignore o resultado da validação do certificado e aceite <a>todos os riscos relacionados</a>.',
        relatedRisks: 'todos os riscos relacionados',
        ignoreCertificateErrorsButton: 'Ignorar a verificação e restauração do certificado',
        ignoreCertificateConfirmation: 'Confirme que você reconhece e aceita todos os riscos relacionados aos problemas do certificado.',
        confirmAndProceed: 'Confirme e continue',
        recheckCertificateButton: 'Verifique novamente o certificado',
        recheckResultSuccess: 'A conexão foi restabelecida com sucesso.',
        recheckResultCertificateError: 'A validação do certificado falhou com o seguinte erro: {errorMessage}',
        recheckResultConnectionError: 'O Plesk 360 não pode estabelecer uma conexão com {serverName}.',
        recheckResultConnectionBlocked: 'A conexão com o servidor {serverName} está bloqueada',
        connectionErrorStatusMessage: 'A conexão com o servidor {serverName} não pode ser estabelecida corretamente.',
        connectionErrorFixDescription: 'Corrija o erro e pressione o botão abaixo para restaurar a conexão.',
        updatesButton: '{product} Atualizações',
        login: {
            plesk: 'Ir para o Plesk',
            cpanel: 'Ir para o cPanel',
            wp: 'Ir para WordPress',
        },
        AccessProblemStatusMessage: {
            causesTitle: 'O servidor está configurado para que seja impossível estabelecer uma conexão. Causas possíveis:',
            caseRestrictionManagement: 'Os endereços IP do Platform 360 são filtrados para acesso do Administrador (<a>Instruções</a>)',
            casePanelIniApiSection: 'O acesso remoto via Plesk API está desabilitado em panel.ini (<a>instruções</a>)',
            caseImunify360: 'Access denied by Imunify360 Anti-bot protection.',
            listPublicIps: 'Certifique-se de que o acesso a partir dos seguintes endereços IP seja permitido: <b>{publicIps}</b>.',
            fixDescription: 'Para conectar o servidor com sucesso, ajuste as definições de configuração correspondentes. Quando terminar, clique no botão abaixo para verificar a conexão.',
            recheckConnectionButton: 'Verifique novamente a conexão',
        },

        recheckConnectionButton: 'Verifique novamente a conexão',
        sync: 'Sincronizar',
        state: 'Estado do servidor',
        Domains: {
            name: 'Nome',
            hostingType: 'Tipo de Hospedagem',
            virtualHostingType: 'Website',
            forwardingHostingType: 'Redirecionamento',
            noneHostingType: 'Sem hospedagem',
            mainDomainType: 'Domínio Principal',
            subDomainType: 'Subdomínio',
            addonDomainType: 'Domínio Addon',
            parkedDomainType: 'Alias',
            manage: 'Gerenciar',
            manageLinkUnavailableHint: 'Plesk RESTful API 1.4.0 ou posterior é necessário para gerar um link direto para o painel Plesk. Verifique a Documentação para mais detalhes.',
            open: 'Abrir',
        },
        Clients: {
            name: 'Nome de Contato',
            email: 'Endereço de E-mail',
            login: 'Nome de Usuário',
            type: 'Tipo',
            status: 'Status',
            typeAdmin: 'Admin',
            typeReseller: 'Revendedor',
            typeCustomer: 'Cliente',
            typeCustomerWithOwner: 'Cliente de {owner}',
            statusActive: 'Ativo',
            statusSuspended: 'Suspenso',
            manage: 'Gerenciar',
        },
    },
    InPlaceText: {
        ok: 'Salvar (Ctrl+Enter)',
        cancel: 'Cancelar (Esc)',
        edit: 'Editar',
        add: 'Adicionar',
    },
    ServerLogin: {
        title: 'Redirecionando você para o painel de controle em {address}',
        progress: 'Isso pode demorar alguns segundos',
        clientNote: 'Lembre-se de que os Clientes / Revendedores não estão visíveis na Visualização Power User.',
        errorTitle: 'Ocorreu um erro ao tentar se conectar ao servidor',
        errorDescription: 'Faça login diretamente no servidor pelo endereço do servidor usando as credenciais apropriadas ou tente novamente autorizá-lo via Plesk 360 posteriormente.',
        btnGroupLogin: 'Log In',
        tryAgain: 'Tente novamente',
        loginDirectly: 'Faça login diretamente',
        btnGroupCancel: 'Cancelar',
        cancel: 'Cancelar',
    },
    Team: {
        Layout: {
            title: 'Organização',
            members: 'Membros',
            invitations: 'Convites',
        },
        settings: 'Configurações',
        Members: {
            email: 'E-mail',
            fullName: 'Nome completo',
            mfaStatus: 'Status MFA',
            tags: 'Marcas especiais',
            remove: 'Remover',
            removeMemberConfirmation: '<b>{name}</b> não terá mais acesso à organização e aos recursos compartilhados dentro dela, servidores, websites e licenças.',
            SpecialMarks: {
                you: 'Você',
                owner: 'Proprietário',
            },
            Onboarding: {
                title: 'Crie sua organização em nossa plataforma adicionando seus colegas',
                description: 'Nossa plataforma oferece recursos para gerenciamento e colaboração eficientes da organização. Os membros da sua organização podem trabalhar com você em servidores conectados acessando-os diretamente daqui. Você não precisa mais usar senhas compartilhadas inseguras ou arranjos de acesso complicados.',
                addMembersButton: 'Adicionar membros da organização',
            },
            MultifactorStatus: {
                enabled: 'MFA Habilitado',
                disabled: 'MFA Não habilitado',
            },
        },
        Invitations: {
            email: 'E-mail',
            expireAt: 'Expira Em',
            expired: 'Vencido',
            add: 'Adicionar',
            revoke: 'Revogar',
            Onboarding: {
                title: 'Aqui você pode convidar os membros de sua organização',
                description: 'Adicione seus colegas para compartilhar com eles servidores e websites.',
                addButton: 'Convidar novo membro',
            },
            ListActions: {
                resendInvitationTooltip: 'Reenviar convite.',
                successMessage: 'E-mail de convite enviado novamente.',
            },
            AddInvitations: {
                title: 'Adicionar novos membros à organização',
                infoMessage: 'Convide os membros da sua organização para participar inserindo seus endereços de e-mail de trabalho abaixo.',
                infoDescription: 'Os endereços de e-mail de trabalho são preferidos aos endereços de e-mail pessoais para proteger as informações pessoais dos usuários.',
                emailsLabel: 'Endereços de e-mail para link de convite',
                submitButton: 'Enviar convite',
                successMessage: '{count, plural, =1{Convite para {email} foi enviado.} one{# o convite foi enviado.} other{# convites foram enviados.}}',
            },
            RevokeInvitationsOperation: {
                revoke: 'Revogar',
                noSelectedInvitations: 'Selecione um ou mais convites para revogar.',
                revokeInvitationConfirmation: '{count, plural, =1{Revogar<b>{email}</b>?} one{Revogar<b>#</b> convite?} other{Revogar<b>#</b> convites?}} Você não pode desfazer esta ação.',
                successRevokeInvitation: '{count, plural, =1{O e-mail<b>{email}</b> foi revogado.} one{<b>#</b> convite foi revogado.} other{<b>#</b> convites foram revogados.}}',
            },
        },
    },

    consents: {
        sendPlatform360Announce: {
            title: 'Atualizações do projeto e comunicação comercial',
            text: 'A WebPros International GmbH e outras empresas do grupo WebPros podem armazenar e processar os dados que eu forneço com a finalidade de fornecer conteúdo de marketing personalizado, como boletins informativos e ofertas individuais, de acordo com a <a>Política Privacidade</a>. As ofertas personalizadas podem ser baseadas em informações adicionais, como dados de uso e comportamento (Profiling). Posso revogar este consentimento a qualquer momento nas preferências do projeto.',
        },
    },

    components: {
        List: {
            selectAll: 'Selecionar tudo',
            deselectAll: 'Desmarcar',
            selectedRows: '{selectedRows, plural, one{# de {totalRows, plural, one{# item} other{# itens}} selecionado} other{# de {totalRows, plural, one{# item} other{# itens}} selecionados}}',
            totalRows: '{totalRows, plural, one{total de # item} other{total de # itens}}',
            foundRows: '{foundRows, plural, one{# item encontrado} other{# itens encontrados}}',
        },
        DataList: {
            groupSearch: 'Pesquisar',
        },
        KbInstructionsFixLink: '<a>Consulte este artigo da base de conhecimento</a> para obter instruções para corrigir isso.',
    },
};