// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { AxiosRequestConfig } from 'axios';
import {
    SendVerificationEmailRequest,
    SignUpByInvitationRequest,
    SignUpRequest,
} from '@platform360/auth/shared/api-contract';

export const signUpByInvitation = (request: SignUpByInvitationRequest) =>
    apiClient.post('/auth/sign-up/invitation', request, {
        skipAuthRefresh: true,
    } as AxiosRequestConfig);

export const signUp = (request: SignUpRequest) => apiClient.post('/auth/sign-up', request);

export const sendVerification = (request: SendVerificationEmailRequest) =>
    apiClient.post('/auth/sign-up/verification-email', request);
