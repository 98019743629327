// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse } from '@platform360/libs/shared-web/typings/api';

export type DigitalOceanUserEmailResponse = {
    email: string;
};

export const fetchDigitalOceanUserEmail = async (): Promise<DigitalOceanUserEmailResponse> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<DigitalOceanUserEmailResponse>>(
        '/v1/security-dashboard/digitalocean/sso',
        {
            withCredentials: true,
        },
    );

    return data;
};
