// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Heading, Icon, Item, ItemList, Section } from '@plesk/ui-library';
import { Link } from 'react-router-dom';
import plesk from './images/plesk.svg';
import cpanel from './images/cpanel.svg';
import serverInstallation from './images/server-installation.svg';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import StepForm from '@platform360/server-inventory/web/components/Servers/AddingServer/StepForm';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { useGetConsentsQuery } from '@platform360/accounts/web/queries';
import { AGREEMENT_TYPE_DPA } from '@platform360/libs/common/agreements';
import { hasAccepted } from '@platform360/libs/common/consents';

export type StartStepProps = {
    onClose: () => void;
};

export const StartStep = ({ onClose, ...props }: StartStepProps) => {
    const translate = useTranslate('server-inventory.AddingServer.AddingServerWizard.StartStep');
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const { data } = useGetConsentsQuery();
    const isAccepted = hasAccepted(data, AGREEMENT_TYPE_DPA);

    const getLink = (productType: 'plesk' | 'cpanel') =>
        isAccepted ? `server-installation-${productType}` : `dpa-agreement-${productType}`;

    const allItems = (
        <>
            <Section vertical title={translate('installNewProduct')}>
                <ItemList selectable stretchable minColumnWidth={220} gap="xs">
                    <Item
                        icon={<Icon src={serverInstallation} />}
                        component={Link}
                        to={getLink('plesk')}
                        title={
                            <Heading level={4}>
                                {translate('serverInstallTitle', { productType: 'Plesk' })}
                            </Heading>
                        }
                        description={translate('serverInstallDescription', {
                            productType: 'Plesk',
                        })}
                        onClick={() =>
                            analyticsEvents.addingServerWizardSelectSecondStep(
                                'server-installation-plesk',
                            )
                        }
                    />
                    <Item
                        icon={<Icon src={serverInstallation} />}
                        component={Link}
                        to={getLink('cpanel')}
                        title={
                            <Heading level={4}>
                                {translate('serverInstallTitle', { productType: 'cPanel' })}
                            </Heading>
                        }
                        description={translate('serverInstallDescription', {
                            productType: 'cPanel',
                        })}
                        onClick={() =>
                            analyticsEvents.addingServerWizardSelectSecondStep(
                                'server-installation-cpanel',
                            )
                        }
                    />
                </ItemList>
            </Section>
            <hr />
            <Section vertical title={translate('connectInstallation')}>
                <ItemList selectable stretchable minColumnWidth={220} gap="xs">
                    <Item
                        icon={<Icon src={plesk} />}
                        component={Link}
                        to="plesk"
                        title={<Heading level={4}>{translate('connectPleskTitle')}</Heading>}
                        description={translate('connectPleskDescription')}
                        onClick={() =>
                            analyticsEvents.addingServerWizardSelectSecondStep('connect-plesk')
                        }
                    />
                    <Item
                        icon={<Icon src={cpanel} />}
                        component={Link}
                        to="cpanel"
                        title={<Heading level={4}>{translate('connectCPanelTitle')}</Heading>}
                        description={translate('connectCPanelDescription')}
                        onClick={() =>
                            analyticsEvents.addingServerWizardSelectSecondStep('connect-cpanel')
                        }
                    />
                </ItemList>
            </Section>
        </>
    );

    useEffectOnce(() => {
        analyticsEvents.addingServerWizardFirstStepOpen();
    });

    return (
        <StepForm
            {...props}
            title={translate('title')}
            closeButton={{
                children: translate('closeButton'),
                onClick: onClose,
            }}
        >
            {allItems}
        </StepForm>
    );
};
