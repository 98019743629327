// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { FetcherOptions } from '@platform360/libs/shared-web/typings/api';

export type ManagedUserIdentifierResponse = {
    data: ManagedUserIdentifierDataResponse;
};

export type ManagedUserIdentifierDataResponse = {
    email: string;
};

export type ManagedUserIdentifierOptions = FetcherOptions<{
    hash: string;
}>;

export const managedUserIdentify = async ({
    variables: { hash },
}: ManagedUserIdentifierOptions): Promise<ManagedUserIdentifierDataResponse> => {
    const { data } = await apiClient.get<ManagedUserIdentifierResponse>(
        '/security-dashboard/managed-user/sso',
        {
            params: {
                hash,
            },
        },
    );

    return data.data;
};
