// Copyright 2024. WebPros International GmbH. All rights reserved.

import DataList from '@platform360/libs/shared-web/components/DataList';
import { Text, ListActions, ListAction } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Domain, isPleskFields } from '@platform360/server-inventory/web/types';
import {
    HOSTING_TYPE_NONE,
    HOSTING_TYPE_FRAME_FORWARDING,
    HOSTING_TYPE_STANDARD_FORWARDING,
    HOSTING_TYPE_VIRTUAL,
    DOMAIN_TYPE_PARKED,
    DOMAIN_TYPE_ADDON,
    DOMAIN_TYPE_MAIN,
    DOMAIN_TYPE_SUB,
} from '@platform360/server-inventory/shared/constants';
import usePagination from '@platform360/libs/shared-web/helpers/usePagination';
import useSearch from '@platform360/libs/shared-web/helpers/useSearch';
import { Link, Outlet } from 'react-router-dom';
import { useServerInventoryAnalyticsEvents } from '@platform360/server-inventory/web/helpers/analytics';
import Page from '@platform360/libs/shared-web/components/Page';
import useAllDomainsQuery from '@platform360/server-inventory/web/queries/useAllDomainsQuery';
import { LoginTargets } from '@platform360/server-inventory/shared/login-link-params';

const hostingTypeTitles = {
    [HOSTING_TYPE_VIRTUAL]: 'virtualHostingType',
    [HOSTING_TYPE_STANDARD_FORWARDING]: 'forwardingHostingType',
    [HOSTING_TYPE_FRAME_FORWARDING]: 'forwardingHostingType',
    [HOSTING_TYPE_NONE]: 'noneHostingType',
};
const domainTypeTitles = {
    [DOMAIN_TYPE_MAIN]: 'mainDomainType',
    [DOMAIN_TYPE_SUB]: 'subDomainType',
    [DOMAIN_TYPE_ADDON]: 'addonDomainType',
    [DOMAIN_TYPE_PARKED]: 'parkedDomainType',
};

export type AllDomainsProps = object;

const AllDomains = (props: AllDomainsProps) => {
    const translate = useTranslate('server-inventory.AllDomains');
    const analyticsEvents = useServerInventoryAnalyticsEvents();
    const pagination = usePagination('si-all-domains');
    const search = useSearch();
    const {
        data,
        isLoading,
        isPlaceholderData: isPreviousData,
    } = useAllDomainsQuery({
        variables: {
            page: pagination.current,
            pageSize: pagination.itemsPerPage,
            filter: search.debouncedValue,
        },
        placeholderData: (prev) => prev,
    });

    const onSearch = (term: string) => {
        if (term) {
            analyticsEvents.searchDomains();
        }
        search.onSearch(term);
    };

    return (
        <Page title={translate('title')}>
            <DataList<Domain>
                {...props}
                data={data?.data}
                totalRows={data?.totalCount}
                isPreviousData={isPreviousData}
                loading={isLoading}
                pagination={pagination}
                search={{
                    value: search.value,
                    debouncedValue: search.debouncedValue,
                    onSearch,
                }}
                rowKey={({ server, id }) => `${server.id}:${id}`}
                columns={[
                    {
                        key: 'name',
                        type: 'title',
                        title: translate('name'),
                        render: ({ name }) => <Text bold>{name}</Text>,
                    },
                    {
                        key: 'hostingType',
                        title: translate('hostingType'),
                        render: ({ fields }) =>
                            isPleskFields(fields)
                                ? translate(
                                      hostingTypeTitles[fields.hostingType],
                                      {},
                                      fields.hostingType,
                                  )
                                : translate(
                                      domainTypeTitles[fields.domainType],
                                      {},
                                      fields.domainType,
                                  ),
                    },
                    {
                        key: 'hostingServer',
                        title: translate('hostingServer'),
                        render: ({ server }) => (
                            <Link to={`server/${server.id}`}>{server.address.hostname}</Link>
                        ),
                    },
                    {
                        key: 'actions',
                        type: 'actions',
                        render: ({ id, name, fields, server }) => (
                            <ListActions>
                                <ListAction
                                    icon="web"
                                    primary
                                    component="a"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`http://${name}`}
                                    onClick={() => {
                                        analyticsEvents.websitesItemOpenClicked();
                                    }}
                                >
                                    {translate('open')}
                                </ListAction>
                                <ListAction
                                    icon="sliders"
                                    primary
                                    component="a"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`/si/servers/${server.id}/login/${LoginTargets.domain}?domainId=${id}`}
                                    data-type="manage-domain-link"
                                    disabled={
                                        isPleskFields(fields)
                                            ? fields.baseDomainId === null
                                            : undefined
                                    }
                                    onClick={() => {
                                        analyticsEvents.websitesItemManageClicked();
                                    }}
                                >
                                    {translate(
                                        isPleskFields(fields) && fields.baseDomainId === null
                                            ? 'manageLinkUnavailableHint'
                                            : 'manage',
                                    )}
                                </ListAction>
                            </ListActions>
                        ),
                    },
                ]}
            />
            <Outlet />
        </Page>
    );
};

export default AllDomains;
