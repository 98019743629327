// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Alert } from '@plesk/ui-library';
import {
    translateError,
    ValidationError,
} from '@platform360/libs/shared-web/helpers/translateErrors';
import { StatusDanger } from './status';
import { FirewallLinks } from './FirewallLinks';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { Link } from 'react-router-dom';
import { captureMessage } from '@sentry/browser';
import { HowToUpgradePleskKBArticleHref } from '@platform360/libs/shared-web/components/HowToUpgradePleskKBArticleLink';

export type ServerAccessStatusProps = {
    error: ValidationError;
};

export const ServerAccessError = ({ error }: ServerAccessStatusProps) => {
    const translate = useTranslate('Servers.AddServerControlStatus.ServerAccessError');
    const { publicIps } = useConfig();

    switch (error.type) {
        case 'isNotEmpty':
        case 'required':
            return <StatusDanger>{translate('urlIsRequired')}</StatusDanger>;

        case 'maxLength':
            return <StatusDanger>{translate('urlMaxLength', error.params)}</StatusDanger>;

        case 'isUrl':
        case 'url':
            return (
                <>
                    <StatusDanger>{translate('urlInvalid.title')}</StatusDanger>
                    <Alert intent="danger">{translate('urlInvalid.description')}</Alert>
                </>
            );

        case 'isPublicIp':
            return (
                <>
                    <StatusDanger>{translate('urlPrivateIp.title')}</StatusDanger>
                    <Alert intent="danger">{translate('urlPrivateIp.description')}</Alert>
                </>
            );

        case 'publicIP':
            return (
                <>
                    <StatusDanger>{translate('domainResolvedPrivateIp.title')}</StatusDanger>
                    <Alert intent="danger">
                        {translate('domainResolvedPrivateIp.description')}
                    </Alert>
                </>
            );

        case 'newServer':
            if (!error.params?.serverId) {
                captureMessage('No serverId placeholder for error "serverAlreadyAdded".');
            }

            return (
                <StatusDanger>
                    {translate('serverAlreadyAdded', {
                        a: (parts) =>
                            error.params?.serverId ? (
                                <Link to={`/si/servers/${error.params.serverId}`}>{parts}</Link>
                            ) : (
                                ''
                            ),
                    })}
                </StatusDanger>
            );

        case 'domainName':
            return (
                <>
                    <StatusDanger>{translate('domainNotResolved.title')}</StatusDanger>
                    <Alert intent="danger">{translate('domainNotResolved.description')}</Alert>
                </>
            );

        case 'timeout':
            return (
                <>
                    <StatusDanger>{translate('timeout.title')}</StatusDanger>
                    <Alert intent="danger">
                        {translate('timeout.description', { publicIps: publicIps.join(' ') })}
                        <FirewallLinks />
                    </Alert>
                </>
            );

        case 'connection':
            return (
                <>
                    <StatusDanger>{translate('connectionAborted.title')}</StatusDanger>
                    <Alert intent="danger">
                        {translate('connectionAborted.description', {
                            publicIps: publicIps.join(' '),
                        })}
                        <FirewallLinks />
                    </Alert>
                </>
            );

        case 'connectionRefused':
            return (
                <>
                    <StatusDanger>{translate('connectionRefused.title')}</StatusDanger>
                    <Alert intent="danger">{translate('connectionRefused.description')}</Alert>
                </>
            );

        case 'httpsRequired':
            return <StatusDanger>{translate('badProtocol')}</StatusDanger>;

        case 'apiKey':
            return (
                <>
                    <StatusDanger>{translate('notAuthenticated.title')}</StatusDanger>
                    <Alert intent="danger">{translate('notAuthenticated.description')}</Alert>
                </>
            );

        case 'forbidden':
            return <StatusDanger>{translate('notAuthorized')}</StatusDanger>;

        case 'forbiddenByImunify360':
            return <StatusDanger>{translate('forbiddenByImunify360')}</StatusDanger>;

        case 'unsupportedServerVersion':
            return (
                <>
                    <StatusDanger>{translate('unsupportedServerVersion.title')}</StatusDanger>
                    <Alert intent="danger">
                        {translate('unsupportedServerVersion.description', {
                            minSupportedVersion: error.params?.minSupportedVersion ?? '_',
                            a: (parts) => (
                                <Link
                                    to={HowToUpgradePleskKBArticleHref}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {parts}
                                </Link>
                            ),
                        })}
                    </Alert>
                </>
            );

        case 'pleskConfiguration':
            return (
                <>
                    <StatusDanger>{translate('serverMisconfigured.title')}</StatusDanger>
                    <Alert intent="danger">{translate('serverMisconfigured.description')}</Alert>
                </>
            );

        case 'unknown':
            return (
                <>
                    <StatusDanger>{translate('unknownError.title')}</StatusDanger>
                    <Alert intent="danger">
                        {translate('unknownError.description', { message: error.message })}
                    </Alert>
                </>
            );

        default:
            return (
                <>
                    <StatusDanger>{translate('unknownError.title')}</StatusDanger>
                    <Alert intent="danger">
                        {translate(error.type, error.params, '') || translateError(error)}
                    </Alert>
                </>
            );
    }
};
