// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse, FetcherOptions } from '@platform360/libs/shared-web/typings/api';
import { WithDateTimeProps } from '@platform360/libs/shared-web/types';
import { CreateApiTokenRequest } from '@platform360/accounts/shared/api-contract';
import { DateTime } from 'luxon';
import { TokenType } from '@platform360/accounts/shared/token-type';

type ApiTokenResponse = {
    id: number;
    name: string;
    createdAt: string;
    lastUsedAt: string | null;
    expireAt: string | null;
    type: TokenType;
};

export type ApiToken = WithDateTimeProps<ApiTokenResponse, 'createdAt' | 'lastUsedAt' | 'expireAt'>;

type ApiTokenWithSecretResponse = ApiTokenResponse & {
    token: string;
};

export type ApiTokenWithSecret = WithDateTimeProps<
    ApiTokenWithSecretResponse,
    'createdAt' | 'lastUsedAt' | 'expireAt'
>;

const normalizeApiToken = <T extends ApiTokenResponse>(token: T) =>
    ({
        ...token,
        createdAt: DateTime.fromISO(token.createdAt),
        lastUsedAt: token.lastUsedAt === null ? null : DateTime.fromISO(token.lastUsedAt),
        expireAt: token.expireAt === null ? null : DateTime.fromISO(token.expireAt),
        type: token.type,
    }) as WithDateTimeProps<T, 'createdAt' | 'lastUsedAt' | 'expireAt'>;

export const getApiTokens = async ({ signal }: FetcherOptions): Promise<ApiToken[]> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<ApiTokenResponse[]>>('/accounts/me/api-tokens', {
        signal,
    });

    return data.map(normalizeApiToken);
};

export const createApiToken = async (
    params: CreateApiTokenRequest,
): Promise<ApiTokenWithSecret> => {
    const {
        data: { data },
    } = await apiClient.post<ApiResponse<ApiTokenWithSecretResponse>>(
        '/accounts/me/api-tokens',
        params,
    );

    return normalizeApiToken(data);
};

export const deleteApiToken = (id: number) => apiClient.delete(`/accounts/me/api-tokens/${id}`);
