// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getInstallationSnippet } from '@platform360/security-dashboard/web/api/installation';

const INSTALLATION_SNIPPET_QUERY_ID = ['/security-dashboard/installation/snippet'];

const useInstallationSnippetQuery = () =>
    useQuery({
        queryKey: INSTALLATION_SNIPPET_QUERY_ID,
        queryFn: getInstallationSnippet,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

export default useInstallationSnippetQuery;
