// Copyright 2024. WebPros International GmbH. All rights reserved.

import { DpaManager } from './DpaManager';
import { useCurrentUserQuery, useGetConsentsQuery } from '@platform360/accounts/web/queries';
import { AGREEMENT_TYPE_DPA } from '@platform360/libs/common/agreements';
import { hasAccepted } from '@platform360/libs/common/consents';
import { useState } from 'react';

export const DpaManagerContainer = () => {
    const [isEditing, setIsEditing] = useState(false);
    // DpaManager uses the same query as ConsentManager which shows loading and error states.
    const { data } = useGetConsentsQuery({
        meta: {
            defaultErrorHandler: false,
        },
    });
    const { data: personalInfo } = useCurrentUserQuery();
    const isAccepted = hasAccepted(data, AGREEMENT_TYPE_DPA);
    const handleStartEdit = () => {
        setIsEditing(true);
    };
    const handleStopEdit = () => {
        setIsEditing(false);
    };

    if (!data || !personalInfo) {
        return null;
    }

    const isDpaManagementBlocked = !personalInfo.firstName || !personalInfo.lastName;

    return (
        <DpaManager
            isAccepted={isAccepted}
            isEditing={isEditing}
            onStartEdit={handleStartEdit}
            onStopEdit={handleStopEdit}
            isBlocked={isDpaManagementBlocked}
        />
    );
};
