// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse } from '@platform360/libs/shared-web/typings/api';

export type InstallationTokenResponse = {
    /** JWT Token that provides an ability to connect the remove server. */
    token: string;
    expireAt: string;
    totalTime: number;
    remainingTime: number;
};

export type InstallationSnippetResponse = {
    /** Snippet that provides an ability to connect the remove server. */
    snippet: string;
    expireAt: string;
    totalTime: number;
    remainingTime: number;
};

export const getInstallationToken = async (): Promise<InstallationTokenResponse> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<InstallationTokenResponse>>(
        '/security-dashboard/installation/token',
    );

    return data;
};

export const getInstallationSnippet = async (): Promise<InstallationSnippetResponse> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<InstallationSnippetResponse>>(
        '/security-dashboard/installation/snippet',
    );

    return data;
};

export const resetInstallationTokenDuration = async (): Promise<InstallationTokenResponse> => {
    const {
        data: { data },
    } = await apiClient.post<ApiResponse<InstallationTokenResponse>>(
        '/security-dashboard/installation/token/reset-duration',
    );

    return data;
};

export const resetInstallationSnippetDuration = async (): Promise<InstallationSnippetResponse> => {
    const {
        data: { data },
    } = await apiClient.post<ApiResponse<InstallationSnippetResponse>>(
        '/security-dashboard/installation/snippet/reset-duration',
    );

    return data;
};
