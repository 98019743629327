// Copyright 2024. WebPros International GmbH. All rights reserved.

export const SERVER_NOTES_LEN_MAX = 1024;

export const HOSTING_TYPE_VIRTUAL = 'virtual';
export const HOSTING_TYPE_STANDARD_FORWARDING = 'standard_forwarding';
export const HOSTING_TYPE_FRAME_FORWARDING = 'frame_forwarding';
export const HOSTING_TYPE_NONE = 'none';
export const DOMAIN_TYPE_MAIN = 'main';
export const DOMAIN_TYPE_SUB = 'sub';
export const DOMAIN_TYPE_ADDON = 'addon';
export const DOMAIN_TYPE_PARKED = 'parked';
