// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';
import { signUpByInvitation } from '@platform360/auth/web/api/sign-up';

type UseSignUpByInvitationOptions = {
    onMutate?: () => void;
    onSuccess?: () => void;
    onError?: (error: unknown) => void;
};

const useSignUpByInvitation = (options: UseSignUpByInvitationOptions) => {
    const translateValidationErrors = useTranslateValidationErrors();
    const { error, ...rest } = useMutation({ ...options, mutationFn: signUpByInvitation });

    return { ...rest, errors: translateValidationErrors(getValidationErrors(error)) };
};

export default useSignUpByInvitation;
