// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEventListener } from 'usehooks-ts';
import { Drawer, StatusMessage, Icon } from '@plesk/ui-library';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import {
    useInstallationSnippetQuery,
    useInstallationTokenQuery,
} from '@platform360/security-dashboard/web/queries';
import {
    useResetInstallationTokenDurationMutation,
    useResetInstallationSnippetDurationMutation,
} from '@platform360/security-dashboard/web/mutations';
import useCountdown from '@platform360/libs/shared-web/helpers/useCountdown';
import InstallationSnippet from '@platform360/security-dashboard/web/components/Servers/InstallationSnippet';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
    InstallationSnippetResponse,
    InstallationTokenResponse,
} from '@platform360/security-dashboard/web/api/installation';
import styles from './AddServerDrawer.module.less';
import useFeatureFlagEnabled from '@platform360/libs/shared-web/helpers/useFeatureFlagEnabled';

import banner from './banner.png';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import useInstallationSnippet from '@platform360/security-dashboard/web/components/Servers/InstallationSnippet/useInstallationSnippet';

// When the timer shows up.
const EXPIRATION_WARNING_LINE = 300;
// When the timer going red.
const EXPIRATION_BOTTOM_LINE = 180;

export type AddServerDrawerProps = {
    isOpen: boolean;
    onClose: () => void;
};

const AddServerDrawer = ({ isOpen, onClose }: AddServerDrawerProps) => {
    const isSnippetGenerationEnabled = useFeatureFlagEnabled(
        'securityDashboardGenerateInstallationSnippet',
    );
    const translate = useTranslate('security-dashboard.Servers.AddServerDrawer');
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const documentRef = useRef<Document>(document);
    // Used to invalidate timer in case if the token was refreshed.
    const lastExpireAtRef = useRef<null | string>(null);
    const [timer, { updateTimer, startCountdown }] = useCountdown({
        countStart: 0,
    });
    const [isTimerInitialized, setIsTimerInitialized] = useState(false);

    useEffect(() => {
        if (isOpen) {
            analyticsEvents.wpConnectServerShown();
        } else {
            analyticsEvents.wpConnectServerClosed();
        }
    }, [analyticsEvents, isOpen]);

    const handleTimerUpdate = useCallback(
        ({ expireAt, remainingTime }: InstallationTokenResponse | InstallationSnippetResponse) => {
            // Invalidate cache and reset timer.
            if (expireAt !== lastExpireAtRef.current) {
                updateTimer(remainingTime);
                startCountdown();
                lastExpireAtRef.current = expireAt;
                setIsTimerInitialized(true);
            }
        },
        [updateTimer, startCountdown],
    );

    const tokenData = useInstallationTokenQuery();
    const snippetData = useInstallationSnippetQuery();
    const { data, isLoading, isError, refetch } = isSnippetGenerationEnabled
        ? snippetData
        : tokenData;

    useEffect(() => {
        if (data) {
            handleTimerUpdate(data);
        }
    }, [data, handleTimerUpdate]);

    useEffect(() => {
        if (lastExpireAtRef.current && timer === 0) {
            lastExpireAtRef.current = null;
            // Hack to ensure that backend state was changed.
            setTimeout(() => {
                void refetch();
            }, 2000);
        }
    }, [refetch, timer]);

    // Synchronize timer on tab change.
    useEventListener(
        'visibilitychange',
        () => {
            if (!documentRef.current.hidden) {
                lastExpireAtRef.current = null;
                void refetch();
            }
        },
        documentRef,
    );

    const { mutate: resetTokenDuration, isPending: isResettingTokenDuration } =
        useResetInstallationTokenDurationMutation({
            onSuccess: handleTimerUpdate,
        });

    const { mutate: resetSnippetDuration, isPending: isResettingSnippetDuration } =
        useResetInstallationSnippetDurationMutation({
            onSuccess: handleTimerUpdate,
        });

    const handleResetDuration = () => {
        isSnippetGenerationEnabled ? resetSnippetDuration() : resetTokenDuration();
        analyticsEvents.wpConnectServerRefreshClick(timer);
    };

    const {
        securityDashboard: { wpAgentInstallerUrl, wpAgentRepoUrl },
    } = useConfig();

    const snippetFromToken = useInstallationSnippet(
        wpAgentInstallerUrl,
        wpAgentRepoUrl,
        tokenData.data?.token || '',
    );

    const snippet = isSnippetGenerationEnabled
        ? snippetData.data?.snippet || ''
        : snippetFromToken.snippet;

    const renderContent = () => {
        if (isError) {
            return (
                <StatusMessage intent="danger">
                    {translate('errors.failedToGenerateToken')}
                </StatusMessage>
            );
        }

        return (
            <>
                <ol>
                    <li>
                        <p>{translate('paragraph1')}</p>
                    </li>
                    <li>
                        <p>{translate('paragraph2')}</p>
                    </li>
                </ol>
                <InstallationSnippet
                    isTimerHidden={!isTimerInitialized || timer > EXPIRATION_WARNING_LINE}
                    timer={timer}
                    timerIntent={timer <= EXPIRATION_BOTTOM_LINE ? 'danger' : 'muted'}
                    snippet={snippet}
                    isLoading={isLoading}
                    onCopy={() => analyticsEvents.wpCopyToClipboardClick()}
                    isRefreshing={
                        isSnippetGenerationEnabled
                            ? isResettingSnippetDuration
                            : isResettingTokenDuration
                    }
                    onRefreshClick={handleResetDuration}
                />
                <a
                    href="https://docs.wpguardian.io/#connecting-servers-to-wp-guardian"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => analyticsEvents.wpAddServerDrawerDocumentationClick()}
                    className={styles.documentationLink}
                >
                    <Icon
                        name="question-mark-circle"
                        size="16"
                        intent="inactive"
                        className={styles.documentationIcon}
                    />
                    {translate('documentationLink')}
                </a>
            </>
        );
    };

    return (
        <Drawer
            data-type="wp-server-add-drawer"
            title={translate('title')}
            size="xs"
            isOpen={isOpen}
            onClose={onClose}
            form={{
                vertical: true,
                applyButton: false,
                cancelButton: false,
                submitButton: {
                    children: translate('done'),
                    onClick: onClose,
                },
            }}
            sideBanner={
                <img
                    alt=""
                    src={banner}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
            }
        >
            {renderContent()}
        </Drawer>
    );
};

export default AddServerDrawer;
