// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { Domain } from '@platform360/server-inventory/web/types';
import { DomainResponse } from '@platform360/server-inventory/shared/api-contract';
import {
    ApiListResponse,
    FetcherOptions,
    FilterQuery,
    PaginatedQuery,
} from '@platform360/libs/shared-web/typings/api';

export const normalizeDomain = (domain: DomainResponse): Domain => ({
    ...domain,
    server: {
        ...domain.server,
        address: new URL(domain.server.address),
    },
});

export type GetAllDomainsOptions = FetcherOptions<PaginatedQuery & FilterQuery>;

export const getAllDomains = async ({ variables, signal }: GetAllDomainsOptions) => {
    const { data } = await apiClient.get<ApiListResponse<DomainResponse>>('/si/domains', {
        params: variables,
        signal,
    });
    return {
        ...data,
        data: data.data.map(normalizeDomain),
    };
};
