// Copyright 2024. WebPros International GmbH. All rights reserved.

import { fetchDigitalOceanUserEmail } from '@platform360/security-dashboard/web/api/digitalocean';
import { useQuery } from '@tanstack/react-query';

const DIGITALOCEAN_USER_EMAIL_QUERY_ID = ['/security-dashboard/digitalocean/sso'];

const useDigitalOceanUserEmail = () =>
    useQuery({
        queryKey: DIGITALOCEAN_USER_EMAIL_QUERY_ID,
        queryFn: fetchDigitalOceanUserEmail,
        refetchInterval: false,
        refetchOnWindowFocus: false,
    });

export default useDigitalOceanUserEmail;
