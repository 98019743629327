// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useAuth } from '@platform360/libs/shared-web/auth';
import { Brandings } from '@platform360/libs/common/branding';
import useDigitalOceanUserEmail from '@platform360/security-dashboard/web/queries/useDigitalOceanUserEmail';
import { ContentLoader } from '@plesk/ui-library';
import { useEffect } from 'react';
import {
    digitalOceanResourceUuidCookie,
    digitalOceanTimestampCookie,
    digitalOceanTokenCookie,
} from '@platform360/security-dashboard/shared/cookies';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

export const DigitalOceanLogin = () => {
    const { login } = useAuth();
    const navigate = useNavigate();
    const { data, isLoading } = useDigitalOceanUserEmail();

    const removeCookies = (): boolean => {
        const cookies = [
            digitalOceanResourceUuidCookie,
            digitalOceanTokenCookie,
            digitalOceanTimestampCookie,
        ];
        cookies.forEach((cookie) => Cookies.remove(cookie));

        return true;
    };

    useEffect(() => {
        removeCookies();

        if (!isLoading) {
            if (!data) {
                navigate('/auth/login');
                return;
            }

            void login({
                email: data.email,
                branding: Brandings.securityDashboard,
                passwordless: true,
                passwordlessDescription: 'digitalOcean',
                redirectUrl: '/security-dashboard/servers',
                hideBackButton: true,
            });
        }
    }, [isLoading, data, navigate, login]);

    return <ContentLoader />;
};
