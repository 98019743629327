// Copyright 2024. WebPros International GmbH. All rights reserved.

import ListEmptyView from '@platform360/libs/shared-web/components/ListEmptyView';
import DeleteButton, { DeleteButtonProps } from './DeleteButton';
import { ApiToken } from '@platform360/accounts/web/api/api-tokens';
import { useClipboard } from '@platform360/libs/shared-web/helpers/useClipboard';
import styles from './ApiTokens.module.css';
import {
    List,
    ListActions,
    ListAction,
    Button,
    Toolbar,
    Icon,
    Text,
    Tooltip,
    ContentLoader,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useFormatDateTime } from '@platform360/libs/shared-web/helpers/useFormatDateTime';
import { TokenTypes } from '@platform360/accounts/shared/token-type';

export const MAX_TOKEN_AMOUNT = 10;

export type ApiTokensProps = {
    isLoading: boolean;
    isLimitExceeded: boolean;
    deletingTokenIds: number[];
    data: ApiToken[];
    handleOpenAddDialog: () => void;
    handleTokenDelete: (id: number) => void;
};

const ApiTokens = ({
    isLoading,
    isLimitExceeded,
    deletingTokenIds,
    data,
    handleOpenAddDialog,
    handleTokenDelete,
}: ApiTokensProps) => {
    const { copyText } = useClipboard();
    const translate = useTranslate('accounts.ApiTokens');
    const formatDateTime = useFormatDateTime();

    const copyToClipboard = (text: string) =>
        copyText(text, {
            successMessage: translate('successCopied'),
        });

    const renderCreatedAt = ({ expireAt, createdAt }: Pick<ApiToken, 'createdAt' | 'expireAt'>) => {
        if (!expireAt) {
            return formatDateTime(createdAt);
        }

        return (
            <Tooltip title={translate('expireAtTooltip', { expireAt: formatDateTime(expireAt) })}>
                <Text>{formatDateTime(createdAt)}</Text>
            </Tooltip>
        );
    };

    if (isLoading) {
        return <ContentLoader />;
    }

    return (
        <List<ApiToken>
            loading={isLoading}
            toolbar={
                <Toolbar className={styles.toolbar}>
                    <Button
                        disabled={isLimitExceeded}
                        tooltip={isLimitExceeded && translate('addButtonLimitExceededTooltip')}
                        intent="primary"
                        icon="plus"
                        className={styles.addButton}
                        onClick={handleOpenAddDialog}
                        data-type="api-tokens-add-button"
                    >
                        {translate('addButton')}
                    </Button>
                    <Tooltip title={translate('countTooltip', { max: MAX_TOKEN_AMOUNT })}>
                        <Text fontSize="md" intent="muted" className={styles.totalAmount}>
                            {translate('totalAmount', {
                                itemsAmount: data.length,
                                maxAmount: MAX_TOKEN_AMOUNT,
                                infoIcon: () => <Icon name="info-circle" />,
                            })}
                        </Text>
                    </Tooltip>
                </Toolbar>
            }
            columns={[
                {
                    key: 'name',
                    type: 'title',
                    title: translate('name'),
                    render: ({ name }) => (
                        <Tooltip title={name}>
                            <Text>{name}</Text>
                        </Tooltip>
                    ),
                    truncate: true,
                },
                {
                    key: 'lastUsedAt',
                    title: translate('lastUsageDate'),
                    width: '35%',
                    render: (row) =>
                        row.lastUsedAt
                            ? formatDateTime(row.lastUsedAt)
                            : translate('tokenNeverUsed'),
                },
                {
                    key: 'createdAt',
                    title: translate('creationDate'),
                    width: '35%',
                    render: renderCreatedAt,
                },
                {
                    key: 'actions',
                    type: 'actions',
                    render: ({ id, name, type }) => (
                        <ListActions data-type="api-tokens-delete-action">
                            <ListAction
                                state={deletingTokenIds.includes(id) ? 'loading' : undefined}
                                icon="recycle"
                                primary
                                data-type="api-tokens-delete-button"
                                disabled={type === TokenTypes.system}
                            >
                                {({ wrap }: Pick<DeleteButtonProps, 'wrap'>) => (
                                    <DeleteButton
                                        id={id}
                                        name={name}
                                        handleTokenDelete={handleTokenDelete}
                                        copyToClipboard={copyToClipboard}
                                        wrap={wrap}
                                    />
                                )}
                            </ListAction>
                        </ListActions>
                    ),
                },
            ]}
            data={data.map((v) => ({ ...v, key: v.id.toString() }))}
            emptyView={
                <ListEmptyView
                    data-type="api-tokens-empty-list-view"
                    title={translate('emptyListTitle')}
                    description={translate('emptyListDescription')}
                    actions={
                        <Button
                            intent="primary"
                            onClick={handleOpenAddDialog}
                            data-type="api-tokens-empty-list-add-button"
                        >
                            {translate('createToken')}
                        </Button>
                    }
                />
            }
            data-type="api-tokens-list"
        />
    );
};

export default ApiTokens;
