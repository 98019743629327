// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { Brandings, brandingValues, isBranding } = makeEnum(
    'Branding',
    'platform',
    'monitoring',
    'security-dashboard',
    'get-plesk',
    'get-cpanel',
);

export type Branding = UnionOf<typeof brandingValues>;
