// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    CopyText: {
        copied: 'Copiado al portapapeles.',
    },
    Page: {
        ImpersonationBanner: {
            title: 'Está suplantando a otra cuenta.',
            depersonateButton: 'Dejar de suplantar',
            auth0IdLabel: 'auth0Id:',
            teamGuidLabel: 'teamGuid:',
            successToast: 'Ya no está suplantando a otra cuenta.',
        },
        MainMenu: {
            betaVersion: 'Versión beta',
            servers: 'Servidores',
            websites: 'Sitios web',
            licenses: 'Licencias',
            monitoring: 'Monitorización',
        },
        DotsMenu: {
            platform: 'Plesk 360',
            wpsd: 'WP Guardian',
            monitoring: 'Monitorización',
            feedback: 'Su opinión',
            services: 'Servicios 360',
        },
        GlobalSearch: {
            SearchButton: {
                search: 'Buscar...',
            },
            ClientResultGroup: {
                clients: 'Clientes',
                clientsShowMore: '{showMore, plural, one{# cliente más...} other{# clientes más...}}',
            },
            DomainResultGroup: {
                domains: 'Sitios web',
                domainsShowMore: '{showMore, plural, one{# sitio web más...} other{# sitios web más...}}',
            },
            ServersResultGroup: {
                servers: 'Servidores',
                serversShowMore: '{showMore, plural, one{# servidor más...} other{# servidores más...}}',
            },
            search: 'Buscar...',
            noResults: 'No se encontró nada...',
        },
        UserMenu: {
            team: 'Organización',
            logout: 'Cerrar sesión',
            changePassword: 'Cambiar la contraseña',
            viewProfile: 'Perfil personal',
            businessProfile: 'Perfil corporativo',
            apiTokens: 'Tokens de API',
        },
    },
    limit: 'Límite:',
    upgradePropertiesButton: 'Ajustar',

    common: {
        errors: {
            isNotEmpty: 'Este campo obligatorio está vacío.',
            required: 'Este campo obligatorio está vacío.',
            url: 'El valor no es una URL válida.',
            isUrl: 'El valor no es una URL válida.',
            email: 'Se debe escribir una dirección de email válida.',
            isEmail: 'Se debe escribir una dirección de email válida.',
            json: 'El valor debe ser JSON válido.',
            min: '{min, plural, one{Debe contener al menos # carácter.} other{Debe contener al menos # caracteres.}}',
            maxLength: '{max, plural, one{Debe contener como máximo # carácter.} other{Debe contener como máximo # caracteres.}}',
            isHttps: 'El nombre de host del servidor debe empezar por https://.',
            unique: 'El valor debe ser único.',
            isPublicIp: 'El nombre de host del servidor debería resolver a una dirección IP pública.',
            unknown: 'Error desconocido: {message}',
            externalRedirect: 'Algo ha salido mal.',
        },
    },

    auth0: {
        errors: {
            errorPasswordIsEmpty: 'Indique una contraseña.',
            errorPasswordIsWrong: 'Contraseña incorrecta.',
            errorPasswordIsTooWeak: 'La contraseña no es suficientemente segura.',
            errorPasswordContainsPersonalInfo: 'La contraseña no debe contener información personal.',
            errorPasswordTooCommon: 'La contraseña es demasiado común.',
            errorPasswordPreviouslyUsed: 'La contraseña se ha utilizado anteriormente.',
            errorInvalidOtp: 'El código indicado no es válido.',
        },
    },

    Keys: {
        headers: {
            confirmSubscription: 'Confirme la suscripción',
            payment: 'Pago',
        },
        general: {
            buyNow: 'Comprar ahora',
            confirm: 'Confirmar',
            confirmAndFeedback: 'Confirmar y enviar comentarios',
            cancel: 'Cancelar',
        },
        tabTitle: 'Todas las licencias',
        LinkedEmails: {
            tabTitle: 'Emails vinculados',
            emailColumn: 'Email',
            statusColumn: 'Estado',
            totalRows: '{totalRows, plural, one{# correo electrónico en total} other{# correos electrónicos en total}}',
            verifiedStatus: 'Verificado',
            addButton: 'Vincular más emails',
            deleteButton: 'Desvincular email',
            AddEmails: {
                stepOne: {
                    title: 'Vinculación de emails con licencias vinculadas',
                    emailsLabel: 'Direcciones de email usadas para la compra de licencias',
                    submitButton: 'Enviar verificación',
                },
                stepTwo: {
                    title: 'Las cartas de verificación han sido enviadas',
                    description: 'Compruebe la existencia de mensajes en los buzones de correo y haga clic en los enlaces de verificación. Es posible que deba volver a iniciar sesión para completar la adición de nuevos emails vinculados. Tenga en cuenta que los enlaces expiran al cabo de una hora.',
                    emailColumn: 'Email',
                    submitButton: 'Hecho',
                    resendButton: 'Reenviar',
                },
            },
            Onboarding: {
                title: 'Vincular sus direcciones de email a esta cuenta para ver más licencias',
                description: 'Para empezar a gestionar más licencias, indique las direcciones de email que se usaron para comprarlas.',
                docsLink: 'Más info',
                addEmailsButton: 'Añadir direcciones de email',
            },
        },
        Licenses: {
            MyLicenses: {
                buyButtons: {
                    plesk: 'Comprar Plesk',
                    monitoring: 'Comprar 360 Monitoring',
                },
            },
            Onboarding: {
                DefaultOnboarding: {
                    title: 'Aquí puede ver y gestionar sus licencias',
                    description1: 'Si todavía no dispone de ninguna licencia, haga clic en "Comprar una licencia nueva".',
                    description2: 'Si compró licencias con otras direcciones de email, haga clic en "Vincular licencias existentes" para añadirlas.',
                    docsLink: 'Más info',
                    buyButton: 'Comprar una licencia nueva',
                    addEmailsButton: 'Vincular licencias existentes',
                },
            },
        },
        CB: {
            paymentInfo: 'La tienda online de Plesk gestionada por {companyName} procesará este pedido usando el método de pago vinculado a la suscripción seleccionada',
            confirmSubscription: 'Al enviar este pedido acepta que está registrándose para una suscripción de producto que se facturará en intervalos regulares. El uso continuado del producto comprado está supeditado a su pago de la suscripción. Los detalles sobre el precio y la frecuencia de facturación se muestran en el carro de la compra que aparece arriba.',
            buyInfo: 'Al enviar este pedido, acepta los <termsLink>términos y condiciones</termsLink> de Cleverbridge y confirma que ha leído y entendido la <policyLink>política de privacidad</policyLink> de Cleverbridge y su <revocationLink>derecho de revocación</revocationLink> como cliente.',
            links: {
                terms: 'Términos y condiciones',
                contact: 'Contacto',
                revocation: 'Derecho de revocación',
                security: 'Seguridad',
                legal: 'Información legal',
                policy: 'política de privacidad',
            },
            footer: 'Está realizando el pedido a través de nuestro revendedor online {companyName}. El proceso de pago y el cumplimiento de los pedidos es gestionado por {companyName}, {companyAddress}.',
            nonUS: {
                companyName: 'Cleverbridge AG',
                companyAddress: 'Gereonstr. 43-65, 50670 Cologne, Germany',
            },
            US: {
                companyName: 'Cleverbridge, Inc.',
                companyAddress: '350 N Clark, Suite 700, Chicago, IL 60654, United States',
            },
        },
        Renew: {
            drawer: {
                title: 'Reactivar la suscripción',
            },
            form: {
                submitButton: 'Confirmar la renovación',
                confirmText: 'Al hacer clic en el botón que aparece a continuación, confirma la renovación de su suscripción para "{product}" de WebPros International GmbH',
            },
            buttonTitle: 'Reactivar la suscripción',
            successMessage: 'Se ha renovado la suscripción para {product}. Su llave de licencia tardará un poco en estar activa',
            unavailable: 'No es posible renovar esta suscripción (por ejemplo, esta suscripción podría haberse interrumpido). Compre un producto nuevo.',
        },
        Adjust: {
            delayedChangesProducts: 'Los siguientes límites seguirán siendo los mismos hasta la finalización del periodo de facturación ({date})',
        },
        Cancel: {
            buttonTitle: 'Cancelar suscripción',
            successMessage: 'La suscripción para {product} ha sido cancelada. Puede seguir usándola hasta el {date}',
            popover: {
                title: 'Cancelar la suscripción de {product}',
                message: 'Como consecuencia, en el futuro no se efectuará ningún otro cargo. Puede seguir usando la licencia hasta el {date} (no se reembolsará el periodo de facturación actual).',
            },
        },
        Payment: {
            card: {
                expired: 'Su tarjeta ha expirado',
            },
        },
        Tours: {
            moreDetails: {
                editPaymentMethod: {
                    text: 'Puede modificar el método de pago en la configuración de su suscripción',
                    confirm: 'Entendido',
                },
            },
        },
    },

    ConfirmationPopover: {
        cancel: 'Cancelar',
    },

    ZendeskChat: {
        confirmationPopover: {
            description1: 'Platform 360 utiliza ZendeskChat, una aplicación de terceros.',
            description2: 'Al proceder, acepto usar ZendeskChat, que es una aplicación de terceros. Esto puede implicar que se transfieran mis datos personales (como, por ejemplo, la dirección IP) a terceros dentro o fuera de Europa. Si desea más información, consulte nuestra <a>Política de privacidad</a>.',
            confirmButton: 'Iniciar ZendeskChat',
        },
        supportFormPopover: {
            description: 'En este momento no hay ningún agente disponible. Espere o utilice nuestro formulario de tickets para enviar una solicitud.',
            confirmButton: 'Abrir formulario',
        },
        ChatButton: {
            title: 'Ayuda',
        },
    },

    keysPageTitle: 'Licencias',
    goBack: 'Volver a la pantalla principal',
    keysPageDescription: 'Gestione licencias compradas en la tienda online de Plesk.',
    keysToolbarBuyTitle: 'Comprar',
    keysToolbarFilterTitle: 'Filtrar',

    keyAdditionalActionsToolbarTitle: 'Acciones adicionales',
    keyMoreDetails: 'Más información',

    keyForeignCart: 'Esta licencia no es de su propiedad',
    keyForeignDescriptionTitle: 'No puede gestionar la licencia porque esta pertenece a otra cuenta. Esto puede deberse a las siguientes razones:',
    keyForeignDescriptionHosting: 'Su licencia de Plesk pertenece a su proveedor de hosting.',
    keyForeignDescriptionWrongEmail: 'Ha comprado la licencia usando otra cuenta. Para ver y gestionar la licencia, acceda con el email correspondiente a dicha cuenta.',

    keyTrialDescription: 'Esta licencia es una licencia de prueba. ¿Quiere comprar una {link}?',
    keyTrialDescriptionLink: 'licencia comercial',

    keyTerminatedCart: 'Esta licencia ha sido terminada',
    keyTerminatedDescription: 'Esta licencia ya no es válida.',
    keyTerminatedDescriptionRetail: 'Ya no se le realizará ningún cargo para la misma.',

    keyPartnerCart: 'Esta licencia no se compró en la tienda online de Plesk.',
    keyPartnerDescription: 'No puede mostrarse ningún detalle de facturación para esta licencia.',

    keyLicenseDetailsTitle: 'Detalles de la licencia',
    keyLicenseDetailsCreationDate: 'Fecha de creación:',
    keyLicenseDetailsActivationCode: 'Código de activación:',
    keyBillingDetailsTitle: 'Detalles de facturación',
    keyBillingDetailsTrial: 'Esta es una licencia de prueba que expira el {date}. <a>Compre una licencia</a>.',
    keyBillingDetailsNotRetail: 'No se puede mostrar la información de facturación para esta licencia porque esta no se compró en la tienda online de Plesk.',
    keyBillingDetailsTerminated: 'Su suscripción ha sido cancelada.',
    keyBillingDetailsSubscriptionId: 'ID de la suscripción:',
    keyBillingDetailsCycle: 'Ciclo de facturación:',
    keyBillingDetailsForeign: 'No se puede mostrar la información de facturación para esta licencia porque esta pertenece a otra cuenta.',
    keyServerDetailsTitle: 'Detalles del servidor',
    keyServerDetailsAddresses: 'Direcciones IP del servidor:',
    keyServerDetailsOs: 'SO del servidor:',
    keyServerDetailsProductVersion: 'Versión del producto:',
    keyServerDetailsAbsent: 'Parece ser que esta licencia todavía no se ha instalado en ningún servidor Plesk.',

    pleskUpgradedMessage: 'Ha mejorado la licencia {from} ({id}) a {to}.',
    propertyUpgradedIncreaseMessage: 'La capacidad de la licencia {keyName} ha aumentado a {to} {name}',
    propertyUpgradedDecreaseMessage: 'La capacidad de la licencia {keyName} se ha reducido a {to} {name}. Los cambios se aplicarán a partir del nuevo periodo de facturación',
    keyUpgradedMessage: 'Ha mejorado la licencia {from} ({id}) a {to}. Su licencia {to} ({id}) está asignada a la licencia {parentId} de Plesk.',
    keyAddedMessage: 'Ha comprado la licencia {addon}. Esta está asignada a la licencia {parent}: {parentId}.',
    refreshSuggestion: 'Si no ve estos cambios, <a>actualice la página</a>.',

    upsellPageTitle: 'Añadir extensión nueva a su licencia',
    yearlyPrices: 'Precios anuales',
    monthlyPrices: 'Precios mensuales',
    subscriptionForBillingPeriodNotFound: 'Los periodos de facturación de la licencia de la extensión y de la suscripción no coinciden o bien no existe ninguna licencia de Plesk para dicho periodo de facturación. Seleccione el periodo de facturación coincidente o compre una licencia de Plesk en la tienda online.',
    subscriptionToUpgradeHeader: 'Suscripción que quiere mejorar',
    selectedSubscription: '{subscriptionId} (divisa: {billingDate}; ciclo de facturación: {billingCycle})',
    toOnlineStoreButton: 'A la tienda online',
    why: 'Se preguntará....¿Por qué?',
    whyCharge: 'El importe a cargar se ha prorrateado en función del número de días pendientes en el periodo de facturación actual.',
    chargeNow: 'Ahora se le aplicará un cargo de <b>{grossPrice}</b> (incl. IVA {vatPrice}).',
    chargeNowShort: 'El cargo se efectuará ahora',
    inclVatShort: 'incl. IVA {vatPrice}',
    delayedCharge: 'El límite seguirá siendo {limit} {property} hasta la finalización del periodo de facturación.',
    nextCharge: '<b>{grossPrice}</b> (incl. IVA {vatPrice}), a partir del {nextBillingDate}',
    nextChargeShort: 'A partir del {nextBillingDate}, se le cobrará',
    noProductForBillingPeriod: 'No existe ningún producto para el periodo de facturación seleccionado.',
    confirmationPopupTitle: 'Mejore su suscripción actual',

    upgradePageTitle: 'Mejora de la licencia',
    upgradePageDescription: 'Aquí puede mejorar su licencia.',
    upgradePanelBeforeTitle: 'Antes de la mejora',
    upgradePanelOptionsTitle: 'Opciones de mejora',
    upgradePanelAfterTitle: 'Tras la mejora',
    upgradePanelOptionsRecommendationsTitle: 'Eche un vistazo a nuestras recomendaciones',
    upgradePanelOptionsRecommendationsAlt: 'O {link} a continuación.',
    upgradePanelOptionsRecommendationsAltLink: 'examine todas las opciones posibles',
    upgradeSuggestion: 'Añada productos a su licencia',
    upgradeSuggestionFreeCost: '¡Pruébelo gratis!',
    upgradeSuggestionDescription: '(vea los detalles del producto para más información)',
    upgradeMultiOfferSuggestion: 'Seleccione otro plan',
    upgradeConfirm: 'Comprar ahora',
    upgradeAccept: 'Confirmar',
    upgradeBack: 'Volver a la lista de productos',
    planUpdate: 'Mejora del plan',
    adjustPending: 'El ajuste para esta licencia ya está pendiente. Inténtelo de nuevo en 30 segundos.',
    waitAMoment: 'Espere un momento',
    upgradeConfirmPriceRecalculation: 'Ahora se le facturará {chargeInfo} (precio total prorrateado basado en el número de días que quedan hasta su próxima fecha de facturación, {nextBillingDate}). A partir de {nextBillingDate}, se le facturará {renewalInfo} en concepto de este producto.',
    buyNowNotification: 'Haga clic en "Comprar ahora" para comprar o mejorar la licencia. Se le redireccionará a la tienda online de Plesk, gestionada por Cleverbridge AG. En la tienda se usará el método de pago usado anteriormente para la compra o mejora de su licencia. Todos los pagos serán procesados por Cleverbridge AG.',
    paymentDetailsLinkText: 'Vea y edite los detalles de pago para esta transacción.',
    upgradeDoneRedirectToPleskTitle: 'Compra efectuada correctamente',
    upgradeDoneRedirectToPleskDescription: 'La instalación de la licencia puede tardar unos minutos.',
    upgradeDoneRedirectToPleskButton: 'Volver a Plesk',
    upgradeDoneRedirectToKeys: 'Ver mis licencias',
    total: 'TOTAL',
    vat: 'IVA',
    newTotal: 'TOTAL NUEVO',
    newVat: 'IVA NUEVO',

    firstLoginWithoutEmailVerification: 'Gracias por registrarse en el servicio Plesk 360. Le hemos enviado un email con un enlace de verificación. Haga clic en el enlace para verificar su cuenta e iniciar sesión. A continuación puede empezar a gestionar sus licencias en Plesk 360.',
    pleaseVerifyEmailTitle: 'Verificar su cuenta Plesk 360',
    secondLoginWithoutEmailVerification: 'Está intentando acceder a una cuenta que todavía no ha sido verificada. Compruebe su buzón de entrada para ver si ha recibido un email con un enlace de verificación. Haga clic en el enlace para verificar su cuenta e iniciar sesión. A continuación puede empezar a gestionar sus licencias en Plesk 360.',
    userBlocked: "La cuenta se ha suspendido por una infracción del contrato del usuario. Si considera que se trata de un error, escriba a {emailLink}.",

    errorDefault: 'Algo ha salido mal. Inténtelo de nuevo en unos minutos.',
    errorDefaultWithReqId: 'Algo salió mal. Inténtelo de nuevo más tarde. ID de la petición: {requestId}.',
    errorTooManyRequests: 'Límite de llamada API alcanzado para esta dirección IP. Inténtelo de nuevo en unos minutos.',
    errorTokenRenewal: 'Su sesión ha expirado. Vuelva a iniciar sesión.',
    errorNetworkIssue: 'Parece que tiene problemas para conectarse a la red en este momento.',

    errorKAUnavailable: 'El servicio está temporalmente fuera de servicio. Inténtelo de nuevo transcurridos unos minutos.',
    errorKAUnavailableWithReqId: 'El servicio está temporalmente fuera de servicio. Inténtelo de nuevo transcurridos unos minutos. ID de la petición: {requestId}.',

    footerPurposeExtension: {
        text: 'Encontrará más extensiones en el <a>catálogo de extensiones</a>.',
    },

    filterAll: 'Todas las licencias',
    filterActive: 'Solo activas',
    renewalCost: 'Coste de renovación:',
    nextBillingDate: 'Próxima fecha de facturación:',
    canceledSubscriptionDate: 'La suscripción ha sido cancelada. Su licencia es válida hasta',
    paymentHistory: 'historial de pagos',
    licenseStatus: 'Estado de la licencia:',
    upgradeButton: 'Mejorar',
    upsellButton: 'Añadir extensión',
    upsellText: 'Se añadirá una extensión nueva a su Plesk',
    billingPeriod: 'Período de facturación',
    features: 'Prestaciones adicionales:',
    noFeatures: 'Ninguna prestación adicional',
    toPlesk: 'Ir a Plesk',
    expiresNote: '(expira el {date})',
    startingFrom: 'a partir de',
    billingIntervalMonth: '{value} / mes',
    billingIntervalYear: '{value} / año',
    billingIntervalOnce: '{value} / una vez',
    perOne: 'por unidad',

    paymentMethod: 'Método de pago',
    editPaymentMethod: 'Editar método de pago',
    paymentCard: '{paymentType} xxxxxxxxxxxx{cardLastFourDigits} {cardExpirationMonth}/{cardExpirationYear}',

    added: 'añadido',
    addresses: 'direcciones',
    back: 'Atrás',

    monthly: 'Mensualmente',
    on: 'en',
    plesk: 'Plesk',
    product: 'Producto',
    price: 'Precio',
    products: 'Productos',
    purchased: 'Comprada',
    select: 'Seleccionar',
    sinceNote: '(desde {date})',
    show: 'Mostrar',
    upgrade: 'mejorar',
    yearly: 'Anualmente',

    isEmpty: 'Este campo es obligatorio.',
    errorPasswordIsEmpty: 'Indique la contraseña.',
    errorPasswordIsWrong: 'Contraseña incorrecta.',
    errorPasswordIsTooWeak: 'La contraseña no es suficientemente segura.',
    errorEmailOrPasswordIsIncorrect: 'El email o la contraseña no son correctos.',
    errorIncorrectEmail: 'Dirección de email incorrecta.',
    errorUserDoesntExist: 'El usuario no existe.',
    errorUserExist: 'El usuario ya existe.',
    errorUserIsNotVerified: 'El usuario existe pero no ha sido verificado. Le hemos enviado un nuevo email de verificación, por lo que le recomendamos examinar su buzón de correo.',
    errorUnknownError: 'Error desconocido.',
    errorEmailIsAlreadyLinked: 'Esta dirección de email ya está vinculada a su cuenta.',
    errorLinkIsOutdated: 'Este enlace de verificación ha expirado.',
    errorBounceOrComplaint: 'Esta dirección de email no existe o ha bloqueado nuestro mensaje porque lo considera spam. Póngase en contacto con el equipo de soporte de Plesk 360.',
    errorUserEmailAlreadyVerified: 'Esta dirección de email ya ha sido verificada.',
    errorDoResourceIdExist: 'Esta cuenta de Digital Ocean ya está registrada.',
    errorCopyToClipboard: 'Se ha producido un error de copia. Intente llevar a cabo la acción de copiar de forma manual.',

    PrivacyPolicy: {
        privacyPolicyText: 'WebPros International GmbH procesará los datos proporcionados por usted para procesar su petición conforme a su <a>política de privacidad</a>.',
    },

    Auth: {
        errors: {
            userDoesntExist: 'El usuario no existe.',
            bounceOrComplaint: 'Esta dirección de email no existe o ha bloqueado nuestro mensaje porque lo considera spam. Póngase en contacto con el equipo de soporte de Plesk 360.',
            userIsNotVerified: 'El usuario existe pero no ha sido verificado. Le hemos enviado un nuevo email de verificación, por lo que le recomendamos examinar su buzón de correo.',
            userExist: 'El usuario ya existe.',
            errorPasswordIsEmpty: 'Indique la contraseña.',
            errorPasswordIsWrong: 'Contraseña incorrecta.',
            errorPasswordIsTooWeak: 'La contraseña no es suficientemente segura.',
        },
        AuthContainer: {
            tabs: {
                login: 'Inicio de sesión',
                signUp: 'Registro',
            },
        },
        PageIntro: {
            PlatformPageIntro: {
                title: 'Bienvenido/a a Plesk 360',
                text1: 'Monitorizar y gestionar servidores nunca ha sido tan fácil.',
                text2: 'Esté al corriente en todo momento del rendimiento y evite periodos de inactividad gracias a una <b>completa monitorización integrada de sitios y servidores</b>.',
                text3: 'Disfrute de <b>paneles configurables</b>, alertas personalizadas, verificaciones de un minuto, historial de red y mucho más.',
                text4: 'Gestione su <b>inventario de servidores</b>, vea todas sus licencias Plesk y compre o actualice suscripciones.',
                text5: 'Todo desde una plataforma bajo una única cuenta.',
            },
            MonitoringPageIntro: {
                title: 'Le damos la bienvenida a 360 Monitoring',
                text1: 'Monitorizar y gestionar servidores nunca ha sido tan fácil.',
                text2: 'Completa <b>monitorización de sitios y servidores</b> integrada para disfrutar de un óptimo seguimiento del rendimiento y evitar periodos de inactividad.',
                text3: '<b>Paneles configurables</b>, alertas personalizadas, verificaciones de un minuto, E/S y métricas del servidor, rendimiento de red y métricas personalizadas mediante plugins. ',
                text4: 'Multi-cloud e <b>indiferente</b> a la plataforma.',
            },
        },
        SignUp: {
            signUp: 'Regístrese',
            loginLink: 'Ir a la pantalla de inicio de sesión',
            emailLabel: 'Email',
            emailDescription: 'Mejor use el email que utilizó para comprar Plesk y extensiones',
            passwordLabel: 'Contraseña',
            privacyPolicyAgreementTitle: 'Acepto los <a>{termsLink}</a>',
            termsPlatform: 'términos de uso de Plesk 360',
            termsMonitoring: 'términos de uso de 360 Monitoring',
            privacyPolicyAgreementDescription: 'Con el fin de crear y mantener mi cuenta en la plataforma, WebPros International GmbH y otras empresas del grupo WebPros procesarán la información proporcionada en sus sistemas internos conforme a la <a>política de privacidad</a> durante el periodo en el que mi cuenta esté activa.',
            verifyAccountTitle: 'Verifique su cuenta',
            verifyAccountText: 'Hemos enviado un email con un enlace de verificación a {email}. Haga clic en el enlace para verificar su cuenta e inicie sesión.',
            resendDescription: '¿No recibió el email? Compruebe su carpeta de correo no deseado o haga clic en Reenviar para obtener uno nuevo.',
            resendLabel: 'Reenviar',
            retryCountdown: 'Se podrá proceder con el reenvío en {time}',
            SignUpByInvitation: {
                emailDescription: 'Se le registrará con el email que se muestra arriba',
                invitationSucceed: 'Se ha registrado correctamente. Inicie sesión ahora.',
            },
            SignUpForm: {
                NameFormField: {
                    firstNamePlaceholder: 'Nombre',
                    lastNamePlaceholder: 'Apellidos',
                    nameLabel: 'Nombre',
                },
            },
        },
        Login: {
            signUpButton: 'Regístrese',
            emailLabel: 'Email',
            passwordLabel: 'Contraseña',
            submitButton: 'Iniciar sesión',
            forgotYourPasswordLink: '¿Ha olvidado su contraseña?',
        },
        ResetPassword: {
            title: 'Restablecer la contraseña',
            text: 'Introduzca su dirección de email y le enviaremos un email con el enlace de restablecimiento de la contraseña.',
            emailLabel: 'Email:',
            submitButton: 'Enviar',
            successTitle: 'Se ha enviado el email de restablecimiento de contraseña',
            successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your {brand} and set a new password.',
            successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
            resendButton: 'Reenviar',
            loginForm: 'Iniciar sesión ahora',
            retryCountdown: 'Se podrá proceder con el reenvío en {time}',
        },
    },

    Footer: {
        copyright: '© {year} WebPros International GmbH. Todos los derechos reservados. Plesk y el logo de Plesk son marcas comerciales de WebPros International GmbH.',
        followPlesk: 'Siga a Plesk:',
        followCPanel: 'Siga a cPanel:',
        company: 'Empresa',
        aboutCPanel: 'Información de cPanel',
        aboutPlesk: 'Información sobre Plesk',
        legal: 'Avisos legales',
        cookiehub: 'Configuración de cookies',
        privacyPolicy: 'Política de privacidad',
        impressum: 'Aviso legal',
        knowledgeBase: 'Base de conocimiento',
        documentation: 'Documentación',
        helpCenterCPanel: 'Centro de ayuda de cPanel',
        helpCenterPlesk: 'Centro de ayuda de Plesk',
        contactUs: 'Contacte con nosotros',
        pleskLifecyclePolicy: 'Directiva de ciclo de vida de Plesk',
        community: 'Comunidad',
        blogCPanel: 'Blog de cPanel',
        blogPlesk: 'Blog de Plesk',
        forumsCPanel: 'Foros de cPanel',
        forumsPlesk: 'Foros de Plesk',
        cPanelUniversity: 'cPanel University',
        pleskUniversity: 'Plesk University',
    },

    Form: {
        requiredLegend: 'Campos obligatorios',
        submitButton: 'ACEPTAR',
        cancelButton: 'Cancelar',
    },

    FormField: {
      addMore: 'Añadir más',
      remove: 'Eliminar',
      clear: 'Borrar',
    },

    FormFieldPassword: {
        improvePassword: 'Para disponer de una contraseña más segura:',
        passwordTooShort: 'Aumente la longitud de la contraseña.',
        lettersLowerCase: 'Use al menos un carácter en minúscula.',
        lettersUpperCase: 'Use al menos un carácter en mayúscula.',
        numbers1: 'Use al menos un número.',
        numbers3: 'Use al menos tres números.',
        specialChar1: 'Use al menos un carácter especial (por ejemplo: !, @, #, $, % ,^, &, *, ?, _, ~).',
        specialChar2: 'Use al menos dos caracteres especiales (por ejemplo: : !, @, #, $, %, ^, &, *, ?, _, ~).',
        comboUpperAndLower: 'Use tanto caracteres en minúsculas como caracteres en mayúsculas.',
        comboLettersAndNumbers: 'Use tanto números como caracteres.',
        comboLettersNumbersSpecial: 'Use números, letras y caracteres especiales.',
        yourPasswordIsStrong: 'Si desea que todavía sea más segura, aumente su longitud.',
        passwordStrength: 'La seguridad de la contraseña es %%strength%%.',
        strengthVeryStrong: 'MUY SEGURA',
        strengthStrong: 'SEGURA',
        strengthVeryWeak: 'MUY POCO SEGURA',
        strengthWeak: 'POCO SEGURA',
        strengthMedium: 'MEDIA',
        hidePassword: 'Ocultar la contraseña',
        showPassword: 'Mostrar la contraseña',
        generateButton: 'Generar',
        generateButtonHint: 'Genere una contraseña segura',
    },

    Pagination: {
        totalShort: 'de %%total%%',
        itemsPerPage: 'Elementos por página',
        all: 'Todos',
        total: 'Página %%current%% de %%total%%',
    },

    ListEmptyView: {
        filteredTitle: 'No se encontró ningún elemento que coincida con el filtro',
        emptyTitle: 'Parece que aquí no hay nada',
        filteredDescription: 'Refine su consulta o elimine algunos de los criterios del filtro e inténtelo de nuevo.',
    },

    Dialog: {
        cancelButton: 'Cancelar',
    },
    keyNotUnderOnlineStore: 'No puede mejorar la licencia en Plesk 360 porque la licencia no se compró en la tienda online de Plesk. Si la compró a través de su proveedor de hosting, póngase en contacto con este para examinar las opciones de mejora.',
    notOwnLicense: 'No puede mejorar la licencia en Plesk 360 porque la licencia pertenece a otra cuenta. Si compró la licencia a través de su proveedor de hosting, póngase en contacto con este para conocer las posibilidades de mejora.',
    subscriptionNotFound: 'No pudieron encontrarse los datos de la suscripción',
    unableToUpgrade: 'La mejora no se encuentra disponible. Póngase en contacto con el equipo de soporte de Plesk',
    unknownError: 'El servicio está temporalmente fuera de servicio. Inténtelo de nuevo transcurridos unos minutos.',
    pendingOperationFound: 'No puede efectuarse la operación actual porque no se finalizó la anterior (por ejemplo, el cambio del método de pago). Finalice la operación anterior para continuar.',

    LinkEmail: {
        title: 'Asociar email',
        tryAgain: 'Volver a intentar',
    },

    GaOptOutLink: {
        gaSuccess: 'Ahora Google Analytics ya no efectúa ningún seguimiento.',
    },

    Servers: {
        sync: 'Sinc',
        syncNoSelection: 'No ha seleccionado ningún servidor a sincronizar. ¿Desea sincronizarlos todos?',
        syncAll: 'Sinc todo',
        serverNameColumn: 'Nombre del servidor',
        productColumn: 'Producto',
        panelColumn: 'Panel de control de hosting',
        stateColumn: 'Estado del servidor',
        stateLastSync: 'Última sincronización {duration}',
        stateLastSyncNow: 'Última sincronización justo ahora',
        login: {
            plesk: 'Ir a Plesk',
            cpanel: 'Ir a cPanel',
            wp: 'Vaya a WordPress',
        },
        forceSyncScheduledSuccess: '{count, plural, =1{Hay prevista una sincronización del servidor <b>{title}</b>.} one{Hay prevista una sincronización de <b>#</b> servidor.} other{Hay prevista una sincronización de <b>#</b> servidores.}}',
        updateServer: 'Actualizar {product}',
        cancel: 'Cancelar',
        Toolbar: {
            add: 'Añadir',
            disconnect: 'Desconectar',
            sync: 'Sinc',
            groupSearch: 'Buscar',
        },
        Onboarding: {
            title: 'Aquí puede añadir y gestionar sus servidores Plesk',
            description: 'Añada sus servidores para poder acceder al panel de control con un solo clic.',
            docsLink: 'Más info',
            addServersButton: 'Conectar servidores',
            feedbackButton: 'Denos su opinión',
            installPleskButton: 'Instalar Plesk en un servidor nuevo',
        },
        FeedbackButton: {
            heading: 'Denos su opinión',
            subHeading: 'Esto nos ayudará con su futuro desarrollo.',
        },
        Tour: {
            next: 'Siguiente',
            previous: 'Anterior',
            gotit: 'Entendido',
            step1: {
                title: 'Presentación de inventario de servidores',
                description: 'Una herramienta para la gestión de múltiples servidores en Plesk 360. Añada sus servidores Plesk de forma segura desde la pestaña Servidor o desde Plesk.',
            },
            step2: {
                title: 'Presentación de inventario de servidores',
                description: 'Además, la pestaña Servidor también incluye listas que permiten la búsqueda de todos los clientes y dominios alojados en sus servidores.',
            },
        },
        AddServerWithExternalRedirectDialog: {
            products: {
                plesk: 'Plesk',
                cpanel: 'cPanel',
                wp: 'WordPress',
            },
            title: 'Confirme la conexión de su servidor a "Plesk 360"',
            description: 'Su panel de control de {product} en <b>{address}</b> se conectará a la cuenta <b>{email}</b> en Plesk 360. ¡Asegúrese de que esta cuenta le pertenece antes de confirmar!',
            accessTitle: 'Qué se permitirá',
            accessMessage1 : '<b>Plesk 360</b> tendrá acceso administrativo al panel de control de Plesk en <b>{address}</b>.',
            accessMessage2 : '<b>Plesk 360</b> permitirá a la cuenta <b>{email}</b>:',
            accessItem1: 'iniciar sesión en Plesk como administrador o cualquier otro usuario sin enviar credenciales,',
            accessItem2: 'recuperar listas de clientes y sitios web alojados en el servidor,',
            accessItem3: 'activar la monitorización externa del servidor,',
            accessItem4: '(en el futuro) realizar otras acciones.',
            buttons: {
                confirm: 'Confirmar',
                confirmAnyway: 'Confirmar de todos modos',
                nextStep: 'Próximo paso',
                deny: 'Denegar',
            },
            enableIpRestrictionTitle: 'Detalles de la clave API',
            fields: {
                enableIpRestriction: 'Activar restricción de dirección IP de clave API. IPs permitidas:',
            },
        },
        AddServerControlStatus: {
            info: {
                access: 'El servidor debe ser accesible a través de internet.',
                certificate: 'El servidor debe estar protegido con un certificado SSL/TLS válido.',
            },
            success: {
                access: 'Es posible acceder a la instalación de Plesk mediante Internet.',
            },
            ServerAccessError: {
                urlIsRequired: 'Indique la URL del servidor.',
                urlMaxLength: "{max, plural, one{La URL del servidor debe tener menos de # carácter.} other{La URL del servidor debe tener menos de # caracteres.}}",

                urlInvalid: {
                    title: 'No podemos operar con la dirección de servidor proporcionada.',
                    description: 'Generalmente esto sucede cuando se indica "http" en vez de "https" en la dirección o cuando la URL no se introduce correctamente, como por ejemplo si no se indica el TLD como .com u .org, entre otros.',
                },

                urlPrivateIp: {
                    title: 'Lamentablemente, no podemos acceder a su servidor porque no está disponible.',
                    description: 'El host de la dirección del servidor es una dirección IP que no es pública.',
                },

                serverAlreadyAdded: 'Este servidor ya ha sido añadido. <a>Vea los detalles del servidor.</a>',

                domainNotResolved: {
                    title: 'Lamentablemente, no podemos acceder a su servidor porque no está disponible.',
                    description: 'El nombre de dominio de la dirección del servidor no puede ser resuelto por Plesk 360.',
                },

                domainResolvedPrivateIp: {
                    title: 'Lamentablemente, no podemos acceder a su servidor porque no está disponible.',
                    description: 'Compruebe que se ha asignado una IP pública a este dominio.',
                },

                timeout: {
                    title: 'Lamentablemente, no podemos acceder a su servidor porque no está disponible.',
                    description: 'Compruebe su configuración de firewall y permita el puerto especificado desde {publicIps}.',
                },

                connectionAborted: {
                    title: 'Lamentablemente, no podemos acceder a su servidor porque no está disponible.',
                    description: 'Compruebe su configuración de firewall y permita el puerto especificado desde {publicIps}.',
                },

                connectionRefused: {
                    title: 'La conexión de red ha sido rechazada.',
                    description: 'Compruebe el puerto y el dominio indicado.',
                },

                badProtocol: 'La dirección indicada debería protegerse con un certificado SSL válido.',

                notAuthenticated: {
                    title: 'La conexión no está autorizada por el servidor remoto.',
                    description: 'Vuelva a añadir el servidor a inventario de servidores.',
                },

                notAuthorized: 'Conexión bloqueada.',

                forbiddenByImunify360: 'Access denied by Imunify360 Anti-bot Protection.',

                unsupportedServerVersion: {
                    title: 'No se puede añadir el servidor. Póngase en contacto con el equipo de soporte.',
                    description: 'La versión de Plesk instalada es obsoleta y no se puede añadir.{minSupportedVersion, select, _ {} other { Solo se admite Plesk Obsidian {minSupportedVersion} y versiones posteriores.}} <a>Descubra cómo actualizar Plesk.</a>',
                },
                serverMisconfigured: {
                    title: 'No se puede añadir el servidor. Póngase en contacto con el equipo de soporte.',
                    description: 'El servidor no está configurado correctamente y no se puede añadir.',
                },
                pleskConfiguration: {
                    title: 'No podemos añadir este servidor. Póngase en contacto con el equipo de soporte.',
                    description: 'No se soporta la versión de Plesk (anterior a {pleskMinVersion}) o Plesk no está configurado correctamente.',
                },

                unknownError:  {
                    title: 'No se puede añadir el servidor. Póngase en contacto con el equipo de soporte.',
                    description: 'Error desconocido: {message}.',
                },
            },
            FirewallLinks: {
                plesk: {
                    linux: 'Cómo gestionar reglas de firewall locales usando Plesk Firewall en Plesk para Linux.',
                    windows: 'El firewall de Plesk (Windows)',
                },
            },
            ServerCertificateError: {
                certExpired: {
                    title: 'Esta instalación de Plesk tiene configurado un certificado expirado.',
                    description: 'No es seguro usar este tipo de configuración y conectarla a Plesk 360. Le recomendamos configurar un certificado autofirmado o firmado por una autoridad de certificación. Al hacer clic en "Siguiente", acepta que esta configuración no es segura.',
                },
                certInvalid: {
                    title: 'El certificado de este servidor está confirmado para operar con otro nombre de dominio.',
                    description: 'El certificado no se ha emitido para este nombre de dominio o bien ha indicado la dirección IP en vez del nombre de dominio válido del servidor. Si tiene la certeza de que la URL indicada es válida, lea <a>este artículo</a> para obtener más información.',
                },
                certSelfSigned: {
                    title: 'El servidor remoto tiene configurado un certificado autofirmado.',
                    description: 'Puede añadir una instalación de Plesk a Plesk 360, si bien recomendamos encarecidamente usar un certificado firmado por una autoridad de certificación, como puede ser el caso de Let\'s Encrypt, para proteger su Plesk frente a posibles incidencias de seguridad.',
                },
                certUntrustedCa: {
                    title: 'La autoridad de certificación del certificado del servidor remoto no cuenta con la confianza de Plesk 360.',
                    description: 'Puede añadir una instalación de Plesk a Plesk 360, si bien recomendamos encarecidamente usar un certificado firmado por una autoridad de certificación, como puede ser el caso de Let\'s Encrypt, para proteger su Plesk frente a posibles incidencias de seguridad.',
                },
                agreeInvalidCertCheckbox: 'Estoy de acuerdo en añadir este servidor sin un certificado válido',
            },
        },
        UnsecureConfirmation: {
            wouldLikeAddUnsecuredServer: 'Me gustaría añadir un servidor no protegido {hostname} a mi cuenta de Plesk 360',
            understandSecurityIssues: 'Entiendo que mi servidor es vulnerable a varias incidencias de seguridad de red',
            understandDataCanLost: 'Entiendo que debería añadir un certificado válido lo antes posible, puesto que de no hacerlo podría perderse el acceso a mi instalación de Plesk y mis datos',
            agreeNotResponsibility: 'Al hacer clic en "Conectar de todos modos", acepto que Plesk 360 no es responsable de la seguridad de este servidor.',
        },
    },
    DisconnectServersOperation: {
        disconnect: 'Desconectar',
        noSelectedServers: 'Seleccione uno o más servidores que quiera desconectar.',
        disconnectConfirmation: '{count, plural, =1{¿Desconectar <b>{title}</b>?} one{¿Desconectar <b>#</b> servidor?} other{¿Desconectar <b>#</b> servidores?}} Esta acción no puede deshacerse.',
        notifyDisconnectSuccess: '{count, plural, =1{Se ha desconectado el servidor <b>{title}</b>.} one{Se ha desconectado <b>#</b> servidor.} other{Se han desconectado <b>#</b> servidores.}}',
        notifyDisconnectFail: '{count, plural, =1{El servidor <b>{title}</b> no se ha desconectado.} one{<b>#</b> servidor no se ha desconectado.} other{<b>#</b> servidores no se han desconectado.}}',
    },
    ViewServer: {
        title: 'Detalles del servidor',
        inGeneral: 'Información general',
        domains: 'Sitios web',
        clients: 'Clientes',
        installationLog: 'Registros de instalación',
        address: 'Dirección',
        notes: 'Notas',
        product: 'Producto',
        version: 'Versión',
        unknownVersion: 'desconocida',
        showErrorDetail: 'Ver más.',
        allowedInvalidCertificateWarning: 'Es posible que la conexión al servidor no sea segura debido a incidencias de validación del certificado SSL/TLS.',
        allowedInvalidCertificateRisksNote: 'Ha optado por ignorar el resultado de la validación del certificado y ha aceptado todos los riesgos relacionados.',
        allowedInvalidCertificateLinkToDocs: 'Más información sobre incidencias y riesgos.',
        certificateErrorStatusMessage: 'La conexión al servidor ha sido desactivada tras detectarse la siguiente incidencia del certificado SSL/TLS mientras se estaba conectando al servidor {serverName}',
        certificateErrorFixDescription: 'Corrija el error y vuelva a comprobar el certificado para restaurar la conexión. También puede ignorar el resultado de la validación del certificado y aceptar <a>todos los riesgos relacionados</a>.',
        relatedRisks: 'todos los riesgos relacionados',
        ignoreCertificateErrorsButton: 'Ignorar la comprobación del certificado y restaurar',
        ignoreCertificateConfirmation: 'Confirme que asume y acepta todos los riesgos relacionados con las incidencias del certificado.',
        confirmAndProceed: 'Confirmar y proceder',
        recheckCertificateButton: 'Volver a comprobar el certificado',
        recheckResultSuccess: 'La conexión se ha restablecido correctamente.',
        recheckResultCertificateError: 'No pudo validarse el certificado debido al siguiente error: {errorMessage}',
        recheckResultConnectionError: 'Plesk 360 no puede establecer una conexión con {serverName}.',
        recheckResultConnectionBlocked: 'La conexión al servidor {serverName} está bloqueada',
        connectionErrorStatusMessage: 'La conexión al servidor {serverName} no puede establecerse correctamente.',
        connectionErrorFixDescription: 'Corrija el error y haga clic en el botón que se muestra a continuación para restaurar la conexión.',
        updatesButton: 'Actualizaciones de {product}',
        login: {
            plesk: 'Ir a Plesk',
            cpanel: 'Ir a cPanel',
            wp: 'Vaya a WordPress',
        },
        AccessProblemStatusMessage: {
            causesTitle: 'El servidor está configurado y no es posible establecer una conexión. Posibles causas:',
            caseRestrictionManagement: 'Las direcciones IP de Platform 360 se filtran para el acceso del administrador (<a>instrucciones</a>)',
            casePanelIniApiSection: 'El acceso remoto mediante la API de Plesk está desactivado en panel.ini (<a>instrucciones</a>)',
            caseImunify360: 'Access denied by Imunify360 Anti-bot protection.',
            listPublicIps: 'Compruebe que se permite el acceso desde las siguientes direcciones IP: <b>{publicIps}</b>.',
            fixDescription: 'Para conectar el servidor correctamente, ajuste las opciones de configuración correspondientes. A continuación haga clic en el botón que aparece abajo para verificar la conexión.',
            recheckConnectionButton: 'Volver a comprobar la conexión',
        },

        recheckConnectionButton: 'Volver a comprobar la conexión',
        sync: 'Sinc',
        state: 'Estado del servidor',
        Domains: {
            name: 'Nombre',
            hostingType: 'Tipo de hosting',
            virtualHostingType: 'Sitio web',
            forwardingHostingType: 'Redireccionamiento',
            noneHostingType: 'Sin hosting',
            mainDomainType: 'Dominio principal',
            subDomainType: 'Subdominio',
            addonDomainType: 'Dominio complementario',
            parkedDomainType: 'Alias',
            manage: 'Gestionar',
            manageLinkUnavailableHint: 'Para generar un enlace directo al panel de Plesk, es necesario disponer de Plesk RESTful API 1.4.0 o una versión posterior. Para más información, consulte la documentación.',
            open: 'Abrir',
        },
        Clients: {
            name: 'Nombre de contacto',
            email: 'Dirección de email',
            login: 'Nombre de usuario',
            type: 'Tipo',
            status: 'Estado',
            typeAdmin: 'Admin',
            typeReseller: 'Revendedor',
            typeCustomer: 'Cliente',
            typeCustomerWithOwner: 'Cliente de {owner}',
            statusActive: 'Activo',
            statusSuspended: 'Suspendido',
            manage: 'Gestionar',
        },
    },
    InPlaceText: {
        ok: 'Guardar (Ctrl+Enter)',
        cancel: 'Cancelar (Esc)',
        edit: 'Editar',
        add: 'Añadir',
    },
    ServerLogin: {
        title: 'Redireccionándole al panel de control en {address}',
        progress: 'Esto puede tardar unos segundos',
        clientNote: 'Recuerde que los clientes/revendedores no son visibles en el modo de visualización Power User.',
        errorTitle: 'Se ha producido un error mientras se intentaba conectarse al servidor',
        errorDescription: 'Acceda directamente al servidor mediante su dirección usando las credenciales apropiadas o vuelva a intentar autorizarlo mediante Plesk 360 transcurridos unos minutos.',
        btnGroupLogin: 'Iniciar sesión',
        tryAgain: 'Volver a intentar',
        loginDirectly: 'Acceder directamente',
        btnGroupCancel: 'Cancelar',
        cancel: 'Cancelar',
    },
    Team: {
        Layout: {
            title: 'Organización',
            members: 'Miembros',
            invitations: 'Invitaciones',
        },
        settings: 'Configuración',
        Members: {
            email: 'Email',
            fullName: 'Nombre completo',
            mfaStatus: 'Estado MFA',
            tags: 'Marcas especiales',
            remove: 'Eliminar',
            removeMemberConfirmation: '<b>{name}</b> ya no dispondrá de acceso a la organización ni a los recursos compartidos en servidores, sitios web y licencias.',
            SpecialMarks: {
                you: 'Usted',
                owner: 'Propietario',
            },
            Onboarding: {
                title: 'Cree su organización en nuestra plataforma añadiendo a sus compañeros',
                description: 'Nuestra plataforma le ofrece prestaciones para una colaboración y gestión eficiente de su empresa. Los miembros de su empresa pueden colaborar con usted en servidores conectados accediendo a ellos directamente desde aquí. Ya no es necesario que use contraseñas compartidas inseguras o complicados procesos de acceso.',
                addMembersButton: 'Añadir miembros a la organización',
            },
            MultifactorStatus: {
                enabled: 'MFA activado',
                disabled: 'MFA no activado',
            },
        },
        Invitations: {
            email: 'Email',
            expireAt: 'Expiración',
            expired: 'Expirada',
            add: 'Añadir',
            revoke: 'Revocar',
            Onboarding: {
                title: 'Aquí puede invitar a miembros a su organización',
                description: 'Añada a compañeros para compartir con ellos servidores y sitios web.',
                addButton: 'Invitar a un miembro nuevo',
            },
            ListActions: {
                resendInvitationTooltip: 'Reenvíe la invitación.',
                successMessage: 'Email de invitación enviado de nuevo.',
            },
            AddInvitations: {
                title: 'Añadir nuevos miembros a la organización',
                infoMessage: 'Invite a los miembros de su empresa a unirse indicando sus direcciones de email laborales abajo.',
                infoDescription: 'Es mejor introducir direcciones de email laborables en vez de direcciones personales, puesto que de esta forma se salvaguarda la información personal de los usuarios.',
                emailsLabel: 'Direcciones de email para enviar invitaciones',
                submitButton: 'Enviar invitación',
                successMessage: '{count, plural, =1{Se ha enviado la invitación a {email}.} one{Se ha enviado # invitación.} other{Se han enviado # invitaciones.}}',
            },
            RevokeInvitationsOperation: {
                revoke: 'Revocar',
                noSelectedInvitations: 'Seleccione una o más invitaciones a revocar.',
                revokeInvitationConfirmation: '{count, plural, =1{¿Revocar <b>{email}</b>?} one{¿Revocar <b>#</b> invitación?} other{¿Revocar <b>#</b> invitaciones?}} Esta acción no puede deshacerse.',
                successRevokeInvitation: '{count, plural, =1{Se ha revocado el correo electrónico <b>{email}</b>.} one{Se ha revocado <b>#</b> invitación.} other{Se han revocado <b>#</b> invitaciones.}}',
            },
        },
    },

    consents: {
        sendPlatform360Announce: {
            title: 'Actualizaciones de proyectos y comunicación comercial',
            text: 'Conforme a la <a>política de privacidad</a>, WebPros International GmbH y otras empresas del grupo WebPros pueden almacenar y procesar los datos que proporcione para finalidades de entrega de contenido de marketing personalizado como newsletters u ofertas individuales. Las ofertas personalizadas pueden basarse en información adicional como puede ser datos de comportamiento y uso (Generación de perfiles). Puedo revocar este consentimiento en cualquier momento en las preferencias del proyecto.',
        },
    },

    components: {
        List: {
            selectAll: 'Seleccionar todo',
            deselectAll: 'Deseleccionar',
            selectedRows: '{selectedRows, plural, one{# de {totalRows, plural, one{# objeto} other{# objetos}} seleccionado} other{# de {totalRows, plural, one{# objeto} other{# objetos}} seleccionados}}',
            totalRows: '{totalRows, plural, one{# objeto en total} other{# objetos en total}}',
            foundRows: '{foundRows, plural, one{# objeto encontrado} other{# objetos encontrados}}',
        },
        DataList: {
            groupSearch: 'Buscar',
        },
        KbInstructionsFixLink: 'En <a>este artículo de la base de conocimiento</a> se detalla cómo corregirlo.',
    },
};