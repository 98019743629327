// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ConsentItem } from '@platform360/libs/shared-web/helpers/useConsentItems';
import { ConsentFlags } from '@platform360/auth/web/components/SignUp/SignUpForm';
import { Consent } from '@platform360/libs/common/consents';

export const getConsentsToSend = (
    consentItems: ConsentItem[],
    consentFlags: ConsentFlags,
): Consent[] =>
    consentItems.map(({ type, title, text }) =>
        consentFlags[type]
            ? {
                  type,
                  enabled: true,
                  text: [title, text].join('\n'),
              }
            : {
                  type,
                  enabled: false,
              },
    );
