// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    InPlaceEdit: {
        updating: 'Updating...',
    },
    NotFound: {
        logoutBtn: 'Log out',
    },
    Layout: {
        MfaRequired: {
            title: 'Access denied',
            description: 'To get access, enable MFA on your account.',
            logoutBtn: 'Log out',
        },
        Widgets: {
            ImpersonationWidget: {
                title: 'Impersonation',
                teamGuid: 'teamGuid:',
                auth0Id: 'auth0Id:',
                depersonated: 'You are not impersonating a different account.',
                active: 'Active',
                inactive: 'Inactive',
                successToast: 'You are no longer impersonating a different account.',
                depersonateButton: 'Stop Impersonating',
            },
        },
        Header: {
            logoutBtn: 'Log out',
        },
        Menu: {
            users: 'Users',
            emailMetas: 'Emails with Issues',
            leikaEmailHashes: 'Leika Email Hashes',
            wpgLicenses: 'WPG Trial Licenses',
            wpgAgentRequests: 'WPG Requests',
        },
    },
    HomePage: {
        welcomeMessage: 'Welcome to the Admin Panel, {name}.',
        availablePermissionsDescription: 'Available permissions:',
    },
    Users: {
        title: 'Users',
        UserCard: {
            actions: 'Actions',
            title: 'User Info',
            id: 'ID',
            auth0Id: 'Auth0 ID',
            userGuid: 'User GUID',
            teamGuid: 'Team GUID',
            monitoringUserId: 'Monitoring User ID',
            isDpaAccepted: 'Data Processing Agreement',
            isMarketingConsentAccepted: 'Project Updates and Commercial Communication',
            name: 'Name',
            signupSource: 'Signup Source',
            emailVerified: 'Email Verified',
            lastLogin: 'Last Login',
            createdAt: 'Created At',
            webprosUser: 'WebPros User',
            blockUserButton: 'Block User',
            unblockUserButton: 'Unblock User',
            blocked: 'User is blocked',
            blockedBySanctions: 'User is blocked due to sanctions',
            blockingComment: 'Reason for blocking',
            successToast: 'User was successfully updated.',
            impersonation: {
                buttonText: 'Impersonate',
                description: 'Impersonate the user?',
                successToast: 'You have been impersonated successfully.',
                confirmButton: 'Yes, impersonate',
                cancelButton: 'No',
                navigateToPlesk360: 'Go to Plesk360',
                navigateToWpsd: 'Go to WP Guardian',
                navigateToMonitoring: 'Go to 360 Monitoring',
            },
            delete: {
                buttonText: 'Delete User',
                description: 'Delete the user? This will also delete their team, servers, and other linked entities.',
                successToast: 'The user has been deleted.',
                confirmButton: 'Yes, delete',
                cancelButton: 'No',
            },
            UserBlockDialog: {
                title: 'Block User',
                blockButton: 'Block',
                placeholder: 'Please enter a reason for blocking...',
                successToast: 'The user has been blocked.',
            },
        },
        searchPlaceholder: 'Enter an email address, an auth0Id, or a team GUID...',
        emptyView: {
            noResults: {
                title: 'Could not find any items matching the filter',
                description: 'Refine your query or remove some of the criteria from the filter and try again.',
            },
            initialState: {
                title: 'Search for a user',
                description: 'To find a user, enter an email or aut0Id in the search bar.',
            },
        },
        Actions: {
            edit: 'Edit User',
        },
        EditUserDrawer: {
            title: 'Edit user',
            isWebprosUserLabel: 'WebPros User',
            blockedLabel: 'User is blocked',
            blockingCommentLabel: 'Reason for blocking',
            nameLabel: 'Name',
            emailLabel: 'Email',
            save: 'Save',
            loadFailed: 'Failed to load the user.',
        },
    },
    EmailMetas: {
        title: 'Emails with Issues',
        searchPlaceholder: 'Enter email..',
        successfullyRemovedToast: 'The email was removed from the list.',
        EmptyView: {
            noResults: {
                title: 'Could not find any items matching the filter',
                description: 'Refine your query or remove some of the criteria from the filter and try again.',
            },
            initialState: {
                title: 'Search for an email with issues',
                description: 'To find a record, enter an email in the search bar.',
            },
        },
        EmailMetaCard: {
            actions: {
                unblock: {
                    tooltip: 'Confirm that the email address has no issues and unblock it.',
                    buttonText: 'Unblock',
                    description: 'Unblock the email address?',
                },
            },
            email: 'Email',
            diagnosticCode: 'Diagnostic Code',
            bounced: 'Bounced',
            complained: 'Complained',
            createdAt: 'Created At',
            updatedAt: 'Updated At',
        },
    },
    LeikaEmailHashes: {
        title: 'Leika Email Hashes',
        label: 'Emails',
        placeholder: 'Specify email addresses here (each one on a new line).',
        hashEmails: 'Hash',
        resultLabel: 'Hashes',
        copied: 'Copied to clipboard.',
    },
    'security-dashboard': {
        Licenses: {
            title: 'WPG Trial Licenses',
            searchPlaceholder: 'Enter an email or auth0Id...',
            searchGroup: 'Search',
            actions: {
                edit: 'Edit License',
            },
            LicenseCard: {
                title: 'License Info',
                id: 'ID',
                product: 'Product',
                expiredAt: 'Expires At',
                createdAt: 'Created At',
                savedSuccessToast: 'The license was edited.',
            },
            emptyView: {
                noResults: {
                    title: 'Could not find any items matching the filter',
                    description: 'Refine your query or remove some of the criteria from the filter and try again.',
                },
                initialState: {
                    title: 'Search for a license',
                    description: 'To find a license, enter an email or aut0Id in the search bar.',
                },
            },
        },
        Requests: {
            title: 'WPG Requests',
            searchPlaceholder: 'Find a server by IP address, GUID, or hostname',
            selectedServer: 'Selected server: {server}',
            multipleServersFound: 'More than one server found. Try to use another search option',
            EmptyView: {
                initialTitle: 'Select a server to make a request',
                notFoundTitle: 'Could not find any server matching the request',
            },
            RequestForm: {
                url: 'URL',
                send: 'Send',
                body: 'Body',
                headers: 'Headers',
                NameValuePair: {
                    name: 'Name',
                    value: 'Value',
                },
            },
            Network: {
                time: `Time: <formattedTime>{time} ms</formattedTime>`,
                Status: {
                    status: `Status: {status}`,
                },
                RequestDetails: {
                    requestProgress: 'Your request is being processed. Do not refresh the page.',
                    timeoutReached: 'The platform is not responding. Probably this is caused by a WebSocket-related issue. Try again or check platform logs. <requestInfo>Request ID: {requestId}</requestInfo>',
                    serverUnreachableError: 'The server is unreachable.',
                    errorCode: 'Error code: {code}',
                    response: 'Response',
                    headers: 'Headers',
                    requestHeaders: 'Request Headers',
                    responseHeaders: 'Response Headers',
                    payload: 'Payload',
                    noPayload: 'No payload',
                    queryParameters: 'Query Parameters',
                    body: 'Body',
                    additionalInfo: 'Additional Info',
                    request: 'Request',
                    requestId: 'Request ID',
                    server: 'Server',
                    hostname: 'Hostname',
                    ip: 'IP',
                    guid: 'GUID',
                },
                ResponseDataPreview: {
                    copied: 'Copied',
                    emptyResponse: 'Empty response',
                    largeResponse: 'The response is too large to display. It will be downloaded instead.',
                },
            },
        },
    },
};
