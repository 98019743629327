// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ChangePasswordRequest } from '@platform360/auth/shared/api-contract';
import { changePassword } from '@platform360/auth/web/api/passwords';
import { useMutation } from '@tanstack/react-query';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';

const useChangePassword = (onSuccess: (password: string) => void) => {
    const translateValidationErrors = useTranslateValidationErrors();
    const { error, ...rest } = useMutation({
        mutationFn: async ({ password, useCase }: ChangePasswordRequest) => {
            await changePassword({ password, useCase });

            return password;
        },
        onSuccess,
    });

    return { errors: translateValidationErrors(getValidationErrors(error)), ...rest };
};

export default useChangePassword;
