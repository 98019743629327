// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from '@platform360/libs/shared-web/auth';
import { ContentLoader } from '@plesk/ui-library';
import useManagedUserIdentifierQuery from '@platform360/security-dashboard/web/queries/useManagedUserIdentifierQuery';
import { useEffect } from 'react';
import { Brandings } from '@platform360/libs/common/branding';

export const ManagedUserApiLogin = () => {
    const { login } = useAuth();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { data, isLoading } = useManagedUserIdentifierQuery({
        variables: {
            hash: searchParams.get('hash') ?? '',
        },
    });

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!data?.email) {
            navigate('/auth/login');
            return;
        }

        void login({
            email: data.email,
            passwordless: true,
            redirectUrl: '/security-dashboard/servers',
            branding: Brandings.securityDashboard,
        });
    }, [data, isLoading, login, navigate]);

    return <ContentLoader />;
};
