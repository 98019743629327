// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse } from '@platform360/libs/shared-web/typings/api';
import {
    GetConsentsResponse,
    ReportConsentsRequest,
} from '@platform360/accounts/shared/api-contract';

export const getConsents = async (): Promise<GetConsentsResponse> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<GetConsentsResponse>>('/accounts/me/consents');

    return data;
};

export const postConsents = async (params: ReportConsentsRequest) => {
    await apiClient.post('/accounts/me/consents', params);

    return params.consents;
};
