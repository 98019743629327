// Copyright 2024. WebPros International GmbH. All rights reserved.

import { DateTime } from 'luxon';
import {
    ServerResponse,
    ClientResponse,
    DomainResponse,
    PleskFields,
    CpanelFields,
} from '@platform360/server-inventory/shared/api-contract';

export type ServerLastRequestResultType =
    | { status: 'ok' }
    | {
          status: 'certificateError' | 'connectionError' | 'connectionBlocked';
          errorCode: string;
          errorDetail: string;
      };

export type Server = Omit<
    ServerResponse,
    | 'address'
    | 'syncFinishedAt'
    | 'nextSyncAt'
    | 'syncStartedAt'
    | 'nextPingAt'
    | 'pingStartedAt'
    | 'lastRequestResultType'
    | 'lastRequestResultDetail'
    | 'lastRequestResultUpdatedAt'
> & {
    address: URL;
    syncFinishedAt: DateTime | null;
    lastRequestResultUpdatedAt: DateTime | null;
    nextSyncAt: DateTime;
    lastRequestResultType: ServerLastRequestResultType;
};

export const isPleskFields = (fields: PleskFields | CpanelFields): fields is PleskFields =>
    fields.product === 'plesk';

export type Domain = Omit<DomainResponse, 'server'> & {
    server: {
        id: number;
        address: URL;
    };
};

export const CLIENT_TYPE_ADMIN = 'admin';
export const CLIENT_TYPE_RESELLER = 'reseller';
export const CLIENT_TYPE_CUSTOMER = 'customer';

export type Client = Omit<ClientResponse, 'server'> & {
    server: {
        id: number;
        address: URL;
    };
};

export type ValidationError = {
    type: string;
    path: string;
    message: string;
};
