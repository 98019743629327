// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import {
    InstallationSnippetResponse,
    resetInstallationSnippetDuration,
} from '@platform360/security-dashboard/web/api/installation';

type MutationOptions = {
    onSuccess?: (data: InstallationSnippetResponse) => void;
};

const useResetInstallationSnippetDurationMutation = ({ onSuccess }: MutationOptions = {}) =>
    useMutation({
        mutationFn: resetInstallationSnippetDuration,
        onSuccess,
    });

export default useResetInstallationSnippetDurationMutation;
