// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    CopyText: {
        copied: 'Скопировано в буфер обмена.',
    },
    Page: {
        ImpersonationBanner: {
            title: 'Вы работаете от имени другого пользователя.',
            depersonateButton: 'Прекратить имперсонацию',
            auth0IdLabel: 'auth0Id:',
            teamGuidLabel: 'teamGuid:',
            successToast: 'Вы больше не работаете от имени другого пользователя.',
        },
        MainMenu: {
            betaVersion: 'Бета-версия',
            servers: 'Серверы',
            websites: 'Сайты',
            licenses: 'Лицензии',
            monitoring: 'Мониторинг',
        },
        DotsMenu: {
            platform: 'Plesk 360',
            wpsd: 'WP Guardian',
            monitoring: 'Мониторинг',
            feedback: 'Ваш отзыв',
            services: 'Службы 360',
        },
        GlobalSearch: {
            SearchButton: {
                search: 'Поиск...',
            },
            ClientResultGroup: {
                clients: 'Клиенты',
                clientsShowMore: '{showMore, plural, one {еще # клиент...} few {еще # клиента...} many {еще # клиентов...} other {еще # клиентов...}}',
            },
            DomainResultGroup: {
                domains: 'Сайты',
                domainsShowMore: '{showMore, plural, one {еще # сайт...} few {еще # сайта...} many {еще # сайтов...} other {еще # сайтов...}}',
            },
            ServersResultGroup: {
                servers: 'Серверы',
                serversShowMore: '{showMore, plural, one {еще # сервер...} few {еще # сервера...} many {еще # серверов...} other {еще # серверов...}}',
            },
            search: 'Поиск...',
            noResults: 'Ничего не найдено...',
        },
        UserMenu: {
            team: 'Организация',
            logout: 'Выйти',
            changePassword: 'Изменить пароль',
            viewProfile: 'Личный профиль',
            businessProfile: 'Бизнес-профиль',
            apiTokens: 'Токены API',
        },
    },
    limit: 'Ограничение:',
    upgradePropertiesButton: 'Изменить',

    common: {
        errors: {
            isNotEmpty: 'Необходимое для заполнения поле.',
            required: 'Необходимое для заполнения поле.',
            url: 'Неверный формат URL-адреса.',
            isUrl: 'Неверный формат URL-адреса.',
            email: 'Неверный формат электронной почты.',
            isEmail: 'Неверный формат электронной почты.',
            json: 'Значение должно соответствовать формату JSON.',
            min: '{min, plural, one{Значение должно содержать не менее # симв.} few {Значение должно содержать не менее # симв.} many {Значение должно содержать не менее # симв.} other{Значение должно содержать не менее # симв.}}',
            maxLength: '{max, plural, one{Значение должно содержать не более # симв.} few {Значение должно содержать не более # симв.} many {Значение должно содержать не более # симв.} other{Значение должно содержать не более # симв.}}',
            isHttps: 'Имя хоста сервера должно начинаться с https://.',
            unique: 'Значение должно быть уникальным.',
            isPublicIp: 'Имя хоста сервера должно преобразовываться во внешний IP-адрес.',
            unknown: 'Неизвестная ошибка: {message}',
            externalRedirect: 'Что-то пошло не так.',
        },
    },

    auth0: {
        errors: {
            errorPasswordIsEmpty: 'Необходимо указать пароль.',
            errorPasswordIsWrong: 'Неверный пароль.',
            errorPasswordIsTooWeak: 'Этот пароль слишком слабый.',
            errorPasswordContainsPersonalInfo: 'Пароль не должен содержать личную информацию.',
            errorPasswordTooCommon: 'Этот пароль слишком распространенный.',
            errorPasswordPreviouslyUsed: 'Пароль использовался раньше.',
            errorInvalidOtp: 'Код указан неверно.',
        },
    },

    Keys: {
        headers: {
            confirmSubscription: 'Подтвердить подписку',
            payment: 'Платеж',
        },
        general: {
            buyNow: 'Купить сейчас',
            confirm: 'Подтвердить',
            confirmAndFeedback: 'Подтвердите и отправьте отзыв',
            cancel: 'Отмена',
        },
        tabTitle: 'Все лицензии',
        LinkedEmails: {
            tabTitle: 'Связанные почтовые адреса',
            emailColumn: 'Электронная почта',
            statusColumn: 'Статус',
            totalRows: '{totalRows, plural, one {Всего # адрес} few {Всего # адреса} many {Всего # адресов} other {Всего # адресов}}',
            verifiedStatus: 'Проверен',
            addButton: 'Привязать больше почтовых адресов',
            deleteButton: 'Отвязать адрес электронной почты',
            AddEmails: {
                stepOne: {
                    title: 'Связывание почтовых адресов с привязанными лицензиями',
                    emailsLabel: 'Адреса электронной почты, используемые для покупки лицензий',
                    submitButton: 'Отправить письмо для подтверждения',
                },
                stepTwo: {
                    title: 'Письма для подтверждения отправлены',
                    description: 'Проверьте почтовые ящики и пройдите по ссылкам для подтверждения в письмах. Вам необходимо повторно войти в эту учетную запись, чтобы завершить добавление новых привязанных почтовых адресов. Срок действия этих ссылок истечет через 1 час.',
                    emailColumn: 'Электронная почта',
                    submitButton: 'Выполнено',
                    resendButton: 'Отправить повторно',
                },
            },
            Onboarding: {
                title: 'Привяжите свои почтовые адреса к этой учетной записи, чтобы увидеть больше лицензий',
                description: 'Чтобы начать управлять большим количеством лицензий, введите почтовые адреса, которые вы использовали для их покупки.',
                docsLink: 'Подробнее',
                addEmailsButton: 'Добавить адреса электронной почты',
            },
        },
        Licenses: {
            MyLicenses: {
                buyButtons: {
                    plesk: 'Купить Plesk',
                    monitoring: 'Купить 360 Monitoring',
                },
            },
            Onboarding: {
                DefaultOnboarding: {
                    title: 'Здесь вы можете просматривать свои лицензии и управлять ими',
                    description1: 'Нажмите "Купить новую лицензию", если у вас нет лицензий.',
                    description2: 'Если некоторые лицензии были куплены раньше с другими адресами электронной почты, нажмите "Привязать существующие лицензии", чтобы добавить их.',
                    docsLink: 'Подробнее',
                    buyButton: 'Купить новую лицензию',
                    addEmailsButton: 'Привязать существующие лицензии',
                },
            },
        },
        CB: {
            paymentInfo: 'Интернет-магазин Plesk под управлением {companyName} обработает этот заказ с помощью способа оплаты, привязанного к выбранной подписке',
            confirmSubscription: 'Отправляя этот заказ, вы соглашаетесь оформить подписку на продукт, за который вам будет выставляться счет через регулярные интервалы времени. Дальнейшее использование приобретенного продукта зависит от вашей оплаты подписки. Подробная информация о цене и частоте выставления счета показана в вашей корзине выше.',
            buyInfo: 'Отправляя этот заказ, вы соглашаетесь принять <termsLink>Условия и положения</termsLink> Cleverbridge и подтверждаете, что прочитали и поняли <policyLink>Политику конфиденциальности</policyLink> Cleverbridge и ваше <revocationLink>Право на отмену</revocationLink> как клиента.',
            links: {
                terms: 'Условия и положения',
                contact: 'Контакт',
                revocation: 'Право на отмену',
                security: 'Безопасность',
                legal: 'Правовая информация',
                policy: 'Политика конфиденциальности',
            },
            footer: 'Вы размещаете заказ через нашего онлайн-реселлера {companyName}. Обработку платежа и исполнение заказа выполняет компания {companyName}, расположенная по адресу {companyAddress}.',
            nonUS: {
                companyName: 'Cleverbridge AG',
                companyAddress: 'Gereonstr. 43-65, 50670 Кельн, Германия',
            },
            US: {
                companyName: 'Cleverbridge, Inc.',
                companyAddress: '350 N Clark, Suite 700, Чикаго, IL 60654, США',
            },
        },
        Renew: {
            drawer: {
                title: 'Возобновить подписку',
            },
            form: {
                submitButton: 'Подтвердить обновление',
                confirmText: 'Нажимая эту кнопку, вы подтверждаете обновление подписки на продукт "{product}" WebPros International GmbH',
            },
            buttonTitle: 'Возобновить подписку',
            successMessage: 'Подписка на {product} обновлена. Через некоторое время ее лицензионный ключ будет активирован',
            unavailable: 'Обновить эту подписку не удалось (возможно, она была прекращена). Пожалуйста, купите новый продукт.',
        },
        Adjust: {
            delayedChangesProducts: 'До окончания расчетного периода ({date}) следующие ограничения останутся без изменений',
        },
        Cancel: {
            buttonTitle: 'Отменить подписку',
            successMessage: 'Подписка на {product} отменена. Вы можете продолжать использовать этот продукт до {date}',
            popover: {
                title: 'Отменить подписку на {product}',
                message: 'Счета больше не будут выставляться. Вы можете продолжать использовать эту лицензию до {date} (оплата за текущий расчетный период не будет возвращена).',
            },
        },
        Payment: {
            card: {
                expired: 'Срок действия вашей карты истек',
            },
        },
        Tours: {
            moreDetails: {
                editPaymentMethod: {
                    text: 'Вы можете изменить способ оплаты в настройках вашей подписки',
                    confirm: 'Понятно',
                },
            },
        },
    },

    ConfirmationPopover: {
        cancel: 'Отмена',
    },

    ZendeskChat: {
        confirmationPopover: {
            description1: 'Учетная запись Platform 360 использует стороннее приложение ZendeskChat.',
            description2: 'Продолжая, я выражаю согласие на использование стороннего приложения ZendeskChat. В процессе мои персональные данные (например, IP-адрес) могут передаваться третьим лицам в Европе или за ее пределами. Дополнительная информация: <a>Политика конфиденциальности</a>.',
            confirmButton: 'Запустить ZendeskChat',
        },
        supportFormPopover: {
            description: 'В настоящий момент все агенты заняты. Пожалуйста, подождите или воспользуйтесь нашей формой создания запросов на поддержку.',
            confirmButton: 'Открыть форму',
        },
        ChatButton: {
            title: 'Поддержка',
        },
    },

    keysPageTitle: 'Лицензии',
    goBack: 'Назад на Домашнюю страницу',
    keysPageDescription: 'Управляйте лицензиями, купленными в интернет-магазине Plesk.',
    keysToolbarBuyTitle: 'Купить',
    keysToolbarFilterTitle: 'Фильтр',

    keyAdditionalActionsToolbarTitle: 'Дополнительные действия',
    keyMoreDetails: 'Подробнее',

    keyForeignCart: 'Эта лицензия вам не принадлежит',
    keyForeignDescriptionTitle: 'Вы не можете управлять этой лицензией, потому что она принадлежит другой учетной записи. Такое может произойти по следующим причинам:',
    keyForeignDescriptionHosting: 'Ваша лицензия на Plesk принадлежит вашему хостинг-провайдеру.',
    keyForeignDescriptionWrongEmail: 'Вы купили лицензию, используя другую учетную запись. Для просмотра и управления этой лицензией войдите с адресом электронной почты, соответствующим этой учетной записи.',

    keyTrialDescription: 'Это пробная лицензия. Заказать: {link}?',
    keyTrialDescriptionLink: 'платная лицензия',

    keyTerminatedCart: 'Действие этой лицензии прекращено',
    keyTerminatedDescription: 'Эта лицензия больше не действительна.',
    keyTerminatedDescriptionRetail: 'Вам больше не будут выставляться счета за нее.',

    keyPartnerCart: 'Эта лицензия не была куплена в интернет-магазине Plesk.',
    keyPartnerDescription: 'Предоставить для нее платежную информацию невозможно.',

    keyLicenseDetailsTitle: 'Информация о лицензии',
    keyLicenseDetailsCreationDate: 'Дата создания:',
    keyLicenseDetailsActivationCode: 'Код активации:',
    keyBillingDetailsTitle: 'Платежная информация',
    keyBillingDetailsTrial: 'Это пробная лицензия, которая истекает {date}. <a>Купить лицензию</a>.',
    keyBillingDetailsNotRetail: 'Для этой лицензии невозможно предоставить платежную информацию, так как она не была куплена в интернет-магазине Plesk.',
    keyBillingDetailsTerminated: 'Ваша подписка отменена.',
    keyBillingDetailsSubscriptionId: 'Код подписки:',
    keyBillingDetailsCycle: 'Цикл выставления счетов:',
    keyBillingDetailsForeign: 'Для этой лицензии невозможно предоставить платежную информацию, так как она принадлежит другой учетной записи.',
    keyServerDetailsTitle: 'О сервере',
    keyServerDetailsAddresses: 'IP-адреса сервера:',
    keyServerDetailsOs: 'ОС сервера:',
    keyServerDetailsProductVersion: 'Версия продукта:',
    keyServerDetailsAbsent: 'Похоже, эта лицензия еще не установлена на сервере Plesk.',

    pleskUpgradedMessage: 'Вы повысили лицензию ({id}) с {from} до {to}.',
    propertyUpgradedIncreaseMessage: 'Объем лицензии {keyName} повышен до {to} {name}',
    propertyUpgradedDecreaseMessage: 'Объем лицензии {keyName} снижен до {to} {name}. Изменения вступят в действие в новом периоде выставления счетов',
    keyUpgradedMessage: 'Вы повысили лицензию ({id}) с {from} до {to}. Ваша лицензия {to} ({id}) привязана к лицензии на Plesk {parentId}.',
    keyAddedMessage: 'Вы купили лицензию {addon}. Она является дополнением к лицензии {parent}: {parentId}.',
    refreshSuggestion: 'Если вы не видите изменений, <a>обновите страницу</a>.',

    upsellPageTitle: 'Добавьте к своей лицензии новое расширение',
    yearlyPrices: 'Цены за год',
    monthlyPrices: 'Цены за месяц',
    subscriptionForBillingPeriodNotFound: 'Расчетные периоды лицензии на расширение и подписки не совпадают, или ни одной лицензии на Plesk в этом расчетном периоде нет. Выберите корректный расчетный период или купите лицензию на Plesk в интернет-магазине.',
    subscriptionToUpgradeHeader: 'Подписка, которую нужно повысить',
    selectedSubscription: '{subscriptionId} (валюта: {billingDate}; расчетный период: {billingCycle})',
    toOnlineStoreButton: 'В интернет-магазин',
    why: 'Почему?',
    whyCharge: 'Запрошенная сумма рассчитывается исходя из оставшегося количества дней в текущем расчетном периоде.',
    chargeNow: 'Сейчас вам будет выставлен счет на <b>{grossPrice}</b> (включая НДС {vatPrice}).',
    chargeNowShort: 'Вам будет выставлен счет на',
    inclVatShort: 'включая НДС {vatPrice}',
    delayedCharge: 'Ограничение в {limit} {property} будет действовать до конца периода выставления счетов.',
    nextCharge: '<b>{grossPrice}</b> (включая НДС {vatPrice}), начиная с {nextBillingDate}',
    nextChargeShort: 'Начиная с {nextBillingDate}, вам будут выставляться счета',
    noProductForBillingPeriod: 'Продукты за выбранный расчетный период не найдены.',
    confirmationPopupTitle: 'Повысьте свою текущую подписку',

    upgradePageTitle: 'Повысьте лицензию',
    upgradePageDescription: 'Здесь вы можете повысить свою лицензию.',
    upgradePanelBeforeTitle: 'До повышения',
    upgradePanelOptionsTitle: 'Варианты повышения',
    upgradePanelAfterTitle: 'После повышения',
    upgradePanelOptionsRecommendationsTitle: 'Посмотрите наши рекомендации:',
    upgradePanelOptionsRecommendationsAlt: 'Или {link} ниже.',
    upgradePanelOptionsRecommendationsAltLink: 'просмотрите все доступные варианты',
    upgradeSuggestion: 'Добавьте к своей лицензии другие продукты',
    upgradeSuggestionFreeCost: 'Попробуйте бесплатно!',
    upgradeSuggestionDescription: '(более подробную информацию смотрите в описаниях продуктов)',
    upgradeMultiOfferSuggestion: 'Выберите другой план',
    upgradeConfirm: 'Купить сейчас',
    upgradeAccept: 'Подтвердить',
    upgradeBack: 'Назад к списку продуктов',
    planUpdate: 'Обновление плана',
    adjustPending: 'Настройка для этой лицензии уже устанавливается. Повторите попытку через 30 секунд.',
    waitAMoment: 'Подождите',
    upgradeConfirmPriceRecalculation: 'Вам будет выставлен счет на {chargeInfo} (окончательная цена, рассчитанная на основании количества дней, оставшихся до даты вашей следующей выплаты, {nextBillingDate}). Начиная с {nextBillingDate} вам будет выставляться счет на {renewalInfo} за этот продукт.',
    buyNowNotification: 'Нажмите "Купить сейчас", чтобы купить или повысить лицензию. Вы будете перенаправлены в интернет-магазин Plesk под управлением Cleverbridge AG. Магазин будет использовать тот же метод оплаты, что использовался ранее для покупки или повышения вашей лицензии. Все платежи будут обработаны Cleverbridge AG.',
    paymentDetailsLinkText: 'Посмотреть и изменить детали этой транзакции.',
    upgradeDoneRedirectToPleskTitle: 'Покупка завершена успешно',
    upgradeDoneRedirectToPleskDescription: 'Установка лицензии может занять некоторое время.',
    upgradeDoneRedirectToPleskButton: 'Вернуться в Plesk',
    upgradeDoneRedirectToKeys: 'Просмотреть мои лицензии',
    total: 'Всего',
    vat: 'НДС',
    newTotal: 'НОВАЯ ИТОГОВАЯ СУММА',
    newVat: 'НОВАЯ СУММА НАЛОГА',

    firstLoginWithoutEmailVerification: 'Спасибо за регистрацию в службе Plesk 360. Мы отправили вам письмо со ссылкой для подтверждения. Перейдите по этой ссылке, чтобы подтвердить учетную запись и войти в Plesk 360. Там вы сможете начать управлять своими лицензиями.',
    pleaseVerifyEmailTitle: 'Подтвердите вашу учетную запись в Plesk 360',
    secondLoginWithoutEmailVerification: 'Вы пытаетесь войти в неподтвержденную учетную запись. Проверьте свой почтовый ящик ― вам было отправлено письмо со ссылкой для ее подтверждения. Перейдите по этой ссылке, чтобы подтвердить вашу учетную запись и войти в Plesk 360. После этого вы сможете начать управлять лицензиями в Plesk 360.',
    userBlocked: "Учетная запись приостановлена из-за нарушения пользовательского соглашения. Если вы считаете, что это ошибка, напишите на адрес {emailLink}.",

    errorDefault: 'Что-то пошло не так. Пожалуйста, повторите попытку позже.',
    errorDefaultWithReqId: 'Что-то пошло не так. Пожалуйста, повторите попытку позже. ID запроса: {requestId}.',
    errorTooManyRequests: 'Достигнут лимит вызовов API для этого IP-адреса. Повторите попытку через минуту.',
    errorTokenRenewal: 'Срок действия вашего сеанса истек. Пожалуйста, войдите еще раз.',
    errorNetworkIssue: 'Кажется, у вас проблемы с подключением к сети.',

    errorKAUnavailable: 'Эта служба сейчас недоступна. Пожалуйста, повторите попытку позже.',
    errorKAUnavailableWithReqId: 'Эта служба временно недоступна. Пожалуйста, повторите попытку позже. ID запроса: {requestId}.',

    footerPurposeExtension: {
        text: 'Найдите больше расширений в <a>Каталоге расширений Plesk</a>.',
    },

    filterAll: 'Все лицензии',
    filterActive: 'Только активные',
    renewalCost: 'Стоимость продления:',
    nextBillingDate: 'Дата следующего счета:',
    canceledSubscriptionDate: 'Подписка отменена. Ваша лицензия действительна до',
    paymentHistory: 'история платежей',
    licenseStatus: 'Статус лицензии:',
    upgradeButton: 'Повысить',
    upsellButton: 'Добавить расширение',
    upsellText: 'Новое расширение будет добавлено в Plesk',
    billingPeriod: 'Расчетный период',
    features: 'Дополнительные функции:',
    noFeatures: 'Без дополнительных функций',
    toPlesk: 'Перейти в Plesk',
    expiresNote: '(истекает {date})',
    startingFrom: 'начиная с',
    billingIntervalMonth: '{value} в месяц',
    billingIntervalYear: '{value} в год',
    billingIntervalOnce: '{value} единовременно',
    perOne: 'за единицу',

    paymentMethod: 'Способ оплаты',
    editPaymentMethod: 'Изменить способ оплаты',
    paymentCard: '{paymentType} xxxxxxxxxxxx{cardLastFourDigits} {cardExpirationMonth}/{cardExpirationYear}',

    added: 'добавлен',
    addresses: 'адреса',
    back: 'Назад',

    monthly: 'Ежемесячно',
    on: 'на',
    plesk: 'Plesk',
    product: 'Продукт',
    price: 'Цена',
    products: 'Продукты',
    purchased: 'Куплена',
    select: 'Выбрать',
    sinceNote: '(с {date})',
    show: 'Показать',
    upgrade: 'повышение',
    yearly: 'Ежегодно',

    isEmpty: 'Необходимое для заполнения поле.',
    errorPasswordIsEmpty: 'Необходимо ввести пароль.',
    errorPasswordIsWrong: 'Неверный пароль.',
    errorPasswordIsTooWeak: 'Этот пароль слишком слабый.',
    errorEmailOrPasswordIsIncorrect: 'Неверный адрес электронной почты или пароль.',
    errorIncorrectEmail: 'Неверный адрес электронной почты.',
    errorUserDoesntExist: 'Такого пользователя не существует.',
    errorUserExist: 'Такой пользователь уже существует.',
    errorUserIsNotVerified: 'Такой пользователь существует, но еще не подтвержден. Мы отправили вам новое письмо для подтверждения. Проверьте свой почтовый ящик.',
    errorUnknownError: 'Неизвестная ошибка.',
    errorEmailIsAlreadyLinked: 'Этот адрес электронной почты уже привязан к вашей учетной записи.',
    errorLinkIsOutdated: 'Срок действия ссылки для подтверждения истек.',
    errorBounceOrComplaint: 'Этот почтовый адрес не существует или заблокировал наше сообщение как спам. Пожалуйста, обратитесь в службу поддержки Plesk 360.',
    errorUserEmailAlreadyVerified: 'Этот адрес электронной почты уже подтвержден.',
    errorDoResourceIdExist: 'Эта учетная запись Digital Ocean уже зарегистрирована.',
    errorCopyToClipboard: 'Произошла ошибка копирования, попробуйте скопировать вручную.',

    PrivacyPolicy: {
        privacyPolicyText: 'WebPros International GmbH обработает предоставленные вами данные для обработки вашего запроса в соответствии с <a>«Политикой конфиденциальности»</a>.',
    },

    Auth: {
        errors: {
            userDoesntExist: 'Такого пользователя не существует.',
            bounceOrComplaint: 'Этот почтовый адрес не существует или заблокировал наше сообщение как спам. Пожалуйста, обратитесь в службу поддержки Plesk 360.',
            userIsNotVerified: 'Такой пользователь существует, но еще не подтвержден. Мы отправили вам новое письмо для подтверждения. Проверьте свой почтовый ящик.',
            userExist: 'Такой пользователь уже существует.',
            errorPasswordIsEmpty: 'Необходимо ввести пароль.',
            errorPasswordIsWrong: 'Неверный пароль.',
            errorPasswordIsTooWeak: 'Этот пароль слишком слабый.',
        },
        AuthContainer: {
            tabs: {
                login: 'Вход',
                signUp: 'Регистрация',
            },
        },
        PageIntro: {
            PlatformPageIntro: {
                title: 'Добро пожаловать в Plesk 360',
                text1: 'Удобный мониторинг и управление серверами для создателей сайтов.',
                text2: 'Используйте полностью <b>интегрированный мониторинг серверов и сайтов</b> для отслеживания производительности и предотвращения простоев.',
                text3: 'Получите <b>настраиваемые информационные панели</b>, персонализированные уведомления, ежеминутные проверки, историю использования сети и так далее.',
                text4: 'Управляйте <b>Перечнем серверов</b>, просматривайте все свои лицензии Plesk, а также покупайте или обновляйте подписки.',
                text5: 'Все на одной платформе под одной учетной записью.',
            },
            MonitoringPageIntro: {
                title: 'Добро пожаловать в 360 Monitoring',
                text1: 'Удобный мониторинг и управление серверами для создателей сайтов.',
                text2: 'Полностью <b>интегрированный мониторинг работоспособности и серверов</b> для отслеживания производительности и предотвращения простоев.',
                text3: '<b>Настраиваемые информационные панели</b>, персонализированные оповещения, одноминутные проверки, метрики сервера и ввод-вывод, производительность сети и настраиваемые метрики с помощью плагинов. ',
                text4: 'Мультиоблачность и <b>независимость</b> от платформы.',
            },
        },
        SignUp: {
            signUp: 'Регистрация',
            loginLink: 'Перейдите на страницу входа',
            emailLabel: 'Электронная почта',
            emailDescription: 'Рекомендуем вам использовать почтовый адрес, с помощью которого вы покупали Plesk и расширения',
            passwordLabel: 'Пароль',
            privacyPolicyAgreementTitle: 'Я соглашаюсь с <a>{termsLink}</a>',
            termsPlatform: 'Условиями использования Plesk 360',
            termsMonitoring: 'Условия использования 360 Monitoring',
            privacyPolicyAgreementDescription: 'Для создания и обслуживания моей учетной записи на этой платформе компания WebPros International GmbH и другие компании, входящие в группу компаний WebPros, будут обрабатывать предоставленную информацию в своих внутренних системах в соответствии с <a>«Политикой конфиденциальности»</a>, пока моя учетная запись активна.',
            verifyAccountTitle: 'Подтвердите вашу учетную запись',
            verifyAccountText: 'Мы отправили письмо со ссылкой для подтверждения учетной записи на адрес {email}. Перейдите по этой ссылке, чтобы подтвердить вашу учетную запись и войти в My Plesk.',
            resendDescription: 'Не получили письмо? Проверьте свою папку "Спам" или нажмите "Отправить повторно", чтобы получить новое письмо.',
            resendLabel: 'Отправить повторно',
            retryCountdown: 'Письмо может быть отправлено повторно через {time}',
            SignUpByInvitation: {
                emailDescription: 'Для регистрации будет использован адрес электронной почты, указанный выше',
                invitationSucceed: 'Вы зарегистрировались. Теперь войдите в систему.',
            },
            SignUpForm: {
                NameFormField: {
                    firstNamePlaceholder: 'Имя',
                    lastNamePlaceholder: 'Фамилия',
                    nameLabel: 'Имя',
                },
            },
        },
        Login: {
            signUpButton: 'Регистрация',
            emailLabel: 'Электронная почта',
            passwordLabel: 'Пароль',
            submitButton: 'Войти',
            forgotYourPasswordLink: 'Забыли пароль?',
        },
        ResetPassword: {
            title: 'Сбросить пароль',
            text: 'Введите свой адрес электронной почты, и мы отправим вам письмо со ссылкой для сброса пароля.',
            emailLabel: 'Электронная почта:',
            submitButton: 'Отправить',
            successTitle: 'Письмо сброса пароля отправлено',
            successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your {brand} and set a new password.',
            successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
            resendButton: 'Отправить повторно',
            loginForm: 'Войти сейчас',
            retryCountdown: 'Письмо может быть отправлено повторно через {time}',
        },
    },

    Footer: {
        copyright: '© {year} WebPros International GmbH. Все права защищены. Plesk и логотип Plesk являются товарными знаками WebPros International GmbH.',
        followPlesk: 'Читать Plesk:',
        followCPanel: 'Читать cPanel:',
        company: 'Компания',
        aboutCPanel: 'О cPanel',
        aboutPlesk: 'О Plesk',
        legal: 'Правовая информация',
        cookiehub: 'Настройки файлов cookie',
        privacyPolicy: 'Политика конфиденциальности',
        impressum: 'Реквизиты',
        knowledgeBase: 'База знаний',
        documentation: 'Документация',
        helpCenterCPanel: 'Центр помощи cPanel',
        helpCenterPlesk: 'Центр помощи Plesk',
        contactUs: 'Свяжитесь с нами',
        pleskLifecyclePolicy: 'Политика управления жизненным циклом Plesk',
        community: 'Сообщество',
        blogCPanel: 'Блог cPanel',
        blogPlesk: 'Блог Plesk',
        forumsCPanel: 'Форумы cPanel',
        forumsPlesk: 'Форумы Plesk',
        cPanelUniversity: 'cPanel University',
        pleskUniversity: 'Plesk University',
    },

    Form: {
        requiredLegend: 'Обязательные поля',
        submitButton: 'OK',
        cancelButton: 'Отмена',
    },

    FormField: {
      addMore: 'Добавить еще один',
      remove: 'Удалить',
      clear: 'Очистить',
    },

    FormFieldPassword: {
        improvePassword: 'Чтобы создать надежный пароль:',
        passwordTooShort: 'Сделайте пароль длиннее.',
        lettersLowerCase: 'Используйте как минимум одну букву в нижнем регистре.',
        lettersUpperCase: 'Используйте как минимум одну букву в верхнем регистре.',
        numbers1: 'Используйте как минимум одну цифру.',
        numbers3: 'Используйте как минимум три цифры.',
        specialChar1: 'Используйте как минимум один специальный символ (например: !, @, #, $, % ,^, &, *, ?, _, ~).',
        specialChar2: 'Используйте как минимум два специальных символа (например: !, @, #, $, %, ^, &, *, ?, _, ~).',
        comboUpperAndLower: 'Используйте буквы как в верхнем, так и в нижнем регистре.',
        comboLettersAndNumbers: 'Используйте и цифры, и буквы.',
        comboLettersNumbersSpecial: 'Используйте цифры, буквы и специальные символы.',
        yourPasswordIsStrong: 'Вы можете сделать его еще более надежным, увеличив его длину.',
        passwordStrength: 'Надежность пароля ― %%strength%%.',
        strengthVeryStrong: 'ОЧЕНЬ НАДЕЖНЫЙ',
        strengthStrong: 'НАДЕЖНЫЙ',
        strengthVeryWeak: 'ОЧЕНЬ НЕНАДЕЖНЫЙ',
        strengthWeak: 'НЕНАДЕЖНЫЙ',
        strengthMedium: 'СРЕДНИЙ',
        hidePassword: 'Скрыть пароль',
        showPassword: 'Показать пароль',
        generateButton: 'Сгенерировать',
        generateButtonHint: 'Сгенерировать надежный пароль',
    },

    Pagination: {
        totalShort: 'из %%total%%',
        itemsPerPage: 'Элементов на странице',
        all: 'Все',
        total: 'Страница %%current%% из %%total%%',
    },

    ListEmptyView: {
        filteredTitle: 'Не удалось найти элементы, соответствующие фильтру',
        emptyTitle: 'Похоже, список пуст',
        filteredDescription: 'Уточните запрос или удалите некоторые критерии из фильтра и повторите попытку.',
    },

    Dialog: {
        cancelButton: 'Отмена',
    },
    keyNotUnderOnlineStore: 'Вы не можете повысить эту лицензию в Plesk 360, потому что она не была куплена в интернет-магазине Plesk. Если вы купили эту лицензию у своего хостинг-провайдера, свяжитесь с ним, чтобы узнать о возможностях ее повышения.',
    notOwnLicense: 'Вы не можете повысить эту лицензию в Plesk 360, так как она принадлежит другой учетной записи. Если вы купили эту лицензию у своего хостинг-провайдера, свяжитесь с ним, чтобы узнать о возможностях ее повышения.',
    subscriptionNotFound: 'Не удалось найти информацию о подписке',
    unableToUpgrade: 'Повышение недоступно. Пожалуйста, обратитесь в службу поддержки Plesk',
    unknownError: 'Эта служба сейчас недоступна. Пожалуйста, повторите попытку позже.',
    pendingOperationFound: 'Эту операцию выполнить не удалось, так как предыдущая операция (например, изменение метода оплаты) не завершена. Завершите предыдущую операцию.',

    LinkEmail: {
        title: 'Письмо со ссылкой',
        tryAgain: 'Попробуйте еще раз',
    },

    GaOptOutLink: {
        gaSuccess: 'Отслеживание Google Analytics прекращено.',
    },

    Servers: {
        sync: 'Синхронизировать',
        syncNoSelection: 'Вы не выбрали ни одного сервера для синхронизации. Хотите синхронизировать все серверы?',
        syncAll: 'Синхронизировать все',
        serverNameColumn: 'Имя сервера',
        productColumn: 'Продукт',
        panelColumn: 'Панель управления хостингом',
        stateColumn: 'Статус сервера',
        stateLastSync: 'Последняя синхронизация {duration}',
        stateLastSyncNow: 'Последняя синхронизация только что завершена',
        login: {
            plesk: 'Перейти в Plesk',
            cpanel: 'Перейти в cPanel',
            wp: 'Перейти на страницу WordPress',
        },
        forceSyncScheduledSuccess: '{count, plural, =1{Запланирована синхронизация сервера <b>{title}</b>.} one{Запланирована синхронизация <b>#</b> сервера.} other{Запланирована синхронизация <b>#</b> серверов.}}',
        updateServer: 'Обновить {product}',
        cancel: 'Отмена',
        Toolbar: {
            add: 'Добавить',
            disconnect: 'Отключить',
            sync: 'Синхронизировать',
            groupSearch: 'Поиск',
        },
        Onboarding: {
            title: 'Здесь вы можете добавлять серверы Plesk и управлять ими',
            description: 'Добавьте свои серверы, чтобы одним кликом переходить в их панель управления.',
            docsLink: 'Подробнее',
            addServersButton: 'Подключить серверы',
            feedbackButton: 'Оставить отзыв',
            installPleskButton: 'Установить Plesk на новый сервер',
        },
        FeedbackButton: {
            heading: 'Оставить отзыв',
            subHeading: 'Это поможет нам в дальнейшей разработке.',
        },
        Tour: {
            next: 'Далее',
            previous: 'Назад',
            gotit: 'Понятно',
            step1: {
                title: 'Ознакомьтесь с Перечнем серверов',
                description: 'Инструмент для управления несколькими серверами в Plesk 360. Безопасно добавляйте свои серверы Plesk из вкладки "Серверы" или из Plesk.',
            },
            step2: {
                title: 'Ознакомьтесь с Перечнем серверов',
                description: 'Вкладка "Серверы" также содержит списки всех клиентов и доменов, находящихся на ваших серверах, с возможностью поиска.',
            },
        },
        AddServerWithExternalRedirectDialog: {
            products: {
                plesk: 'Plesk',
                cpanel: 'cPanel',
                wp: 'WordPress',
            },
            title: 'Подтвердите подключение сервера к "Plesk 360"',
            description: 'Ваша панель управления {product}, доступная по адресу <b>{address}</b>, будет подключена к учетной записи <b>{email}</b> в Plesk 360. Перед подтверждением убедитесь, что эта учетная запись принадлежит вам.',
            accessTitle: 'Предоставляемые разрешения',
            accessMessage1 : '<b>Plesk 360</b> получит административный доступ к панели управления Plesk, доступной по адресу <b>{address}</b>.',
            accessMessage2 : '<b>Plesk 360</b> позволит учетной записи <b>{email}</b>:',
            accessItem1: 'выполнять вход в Plesk как администратор или любой другой пользователь без указания учетных данных;',
            accessItem2: 'получать списки клиентов и сайтов, расположенных на сервере;',
            accessItem3: 'активировать внешний мониторинг сервера;',
            accessItem4: 'выполнять некоторые другие операции (в будущем).',
            buttons: {
                confirm: 'Подтвердить',
                confirmAnyway: 'Все равно подтвердить',
                nextStep: 'Следующий шаг',
                deny: 'Запретить',
            },
            enableIpRestrictionTitle: 'Сведения о ключе API',
            fields: {
                enableIpRestriction: 'Включите ограничение IP-адресов по ключам API. Допустимые IP:',
            },
        },
        AddServerControlStatus: {
            info: {
                access: 'К серверу должен быть доступ из Интернета.',
                certificate: 'Сервер должен быть защищен действительным SSL/TLS-сертификатом.',
            },
            success: {
                access: 'Установка Plesk доступна через Интернет.',
            },
            ServerAccessError: {
                urlIsRequired: 'Пожалуйста, введите URL-адрес сервера.',
                urlMaxLength: "{max, plural, one{URL-адрес сервера должен содержать менее # символа.} other{URL-адрес сервера должен содержать менее # символов.}}",

                urlInvalid: {
                    title: 'Невозможно работать с указанным адресом сервера.',
                    description: 'Обычно такое происходит, если вы в адресе написали "http" вместо "https" или допустили опечатку, например в домене верхнего уровня (.com, .org и т.д.).',
                },

                urlPrivateIp: {
                    title: 'К сожалению, мы не можем установить связь с вашим сервером, так как он недоступен.',
                    description: 'Адрес сервера находится на хосте, IP-адрес которого не является внешним.',
                },

                serverAlreadyAdded: 'Этот сервер уже добавлен. <a>Просмотрите данные о сервере.</a>',

                domainNotResolved: {
                    title: 'К сожалению, мы не можем установить связь с вашим сервером, так как он недоступен.',
                    description: 'Plesk 360 не может сопоставить имя домена адреса сервера.',
                },

                domainResolvedPrivateIp: {
                    title: 'К сожалению, мы не можем установить связь с вашим сервером, так как он недоступен.',
                    description: 'Убедитесь, что для этого домена назначен внешний IP-адрес.',
                },

                timeout: {
                    title: 'К сожалению, мы не можем установить связь с вашим сервером, так как он недоступен.',
                    description: 'Проверьте настройки брандмауэра и дайте разрешение для указанного порта {publicIps}.',
                },

                connectionAborted: {
                    title: 'К сожалению, мы не можем установить связь с вашим сервером, так как он недоступен.',
                    description: 'Проверьте настройки брандмауэра и дайте разрешение для указанного порта {publicIps}.',
                },

                connectionRefused: {
                    title: 'Сетевое подключение отклонено.',
                    description: 'Проверьте указанный домен и порт.',
                },

                badProtocol: 'Предоставленный адрес должен быть защищен действующим SSL-сертификатом.',

                notAuthenticated: {
                    title: 'Соединение не авторизовано удаленным сервером.',
                    description: 'Добавьте сервер в Перечень серверов еще раз.',
                },

                notAuthorized: 'Подключение заблокировано.',

                forbiddenByImunify360: 'Access denied by Imunify360 Anti-bot Protection.',

                unsupportedServerVersion: {
                    title: 'Сервер не может быть добавлен. Обратитесь в службу поддержки.',
                    description: 'Установленная версия Plesk устарела и не может быть добавлена.{minSupportedVersion, select, _ {} other { Поддерживается только Plesk Obsidian {minSupportedVersion} и более поздние версии.}} <a>Узнайте, как обновить Plesk.</a>',
                },
                serverMisconfigured: {
                    title: 'Сервер не может быть добавлен. Обратитесь в службу поддержки.',
                    description: 'Сервер настроен неправильно и не может быть добавлен.',
                },
                pleskConfiguration: {
                    title: 'Мы не можем добавить этот сервер. Пожалуйста, свяжитесь со службой поддержки.',
                    description: 'Неподдерживаемая версия Plesk (ниже {pleskMinVersion}) или ошибка конфигурации Plesk.',
                },

                unknownError:  {
                    title: 'Сервер не может быть добавлен. Обратитесь в службу поддержки.',
                    description: 'Неизвестная ошибка: {message}.',
                },
            },
            FirewallLinks: {
                plesk: {
                    linux: 'Как управлять правилами локального брандмауэра с помощью Plesk Firewall в Plesk для Linux.',
                    windows: 'Брандмауэр Plesk (Windows)',
                },
            },
            ServerCertificateError: {
                certExpired: {
                    title: 'Для этой установки Plesk настроен сертификат с истекшим сроком.',
                    description: 'Использовать такую конфигурацию Plesk и подключать ее к Plesk 360 небезопасно. Мы рекомендуем настроить самозаверенный сертификат или сертификат, подписанный центром сертификации. Нажимая «Далее», вы соглашаетесь с тем, что данная конфигурация небезопасна.',
                },
                certInvalid: {
                    title: 'Сертификат этого сервера работает с другим именем домена.',
                    description: 'Сертификат был выпущен не для этого имени домена, либо вы ввели IP-адрес вместо действительного имени домена сервера. Если вы уверены, что указанный URL-адрес верный, ознакомьтесь с информацией в <a>этой статье</a>.',
                },
                certSelfSigned: {
                    title: 'На удаленном сервере настроен самозаверенный сертификат.',
                    description: 'Вы можете добавить установку Plesk в Plesk 360, но мы настоятельно рекомендуем использовать сертификат, подписанный центром сертификации, например Let\'s Encrypt, чтобы защитить Plesk от возможных проблем с безопасностью.',
                },
                certUntrustedCa: {
                    title: 'Plesk 360 не доверяет центру сертификации удаленного сервера.',
                    description: 'Вы можете добавить установку Plesk в Plesk 360, но мы настоятельно рекомендуем использовать сертификат, подписанный центром сертификации, например Let\'s Encrypt, чтобы защитить Plesk от возможных проблем с безопасностью.',
                },
                agreeInvalidCertCheckbox: 'Я соглашаюсь добавить этот сервер без действительного сертификата',
            },
        },
        UnsecureConfirmation: {
            wouldLikeAddUnsecuredServer: 'Я хочу добавить незащищенный сервер {hostname} в свою учетную запись Plesk 360',
            understandSecurityIssues: 'Я понимаю, что мой сервер уязвим и имеет различные проблемы сетевой безопасности',
            understandDataCanLost: 'Я понимаю, что мне следует добавить действующий сертификат как можно скорее, иначе доступ к моей установке Plesk и данным может быть потерян',
            agreeNotResponsibility: 'Нажимая "Все равно подключить", я соглашаюсь с тем, что Plesk 360 не несет ответственности за безопасность этого сервера.',
        },
    },
    DisconnectServersOperation: {
        disconnect: 'Отключить',
        noSelectedServers: 'Выберите один или несколько серверов для отключения.',
        disconnectConfirmation: '{count, plural, =1{Отключить: <b>{title}</b>?} one{Отключить <b>#</b> сервер?} few {Отключить <b>#</b> сервера?} many {Отключить <b>#</b> серверов?} other{Отключить <b>#</b> серверов?}} Это действие нельзя отменить.',
        notifyDisconnectSuccess: '{count, plural, =1{Сервер <b>{title}</b> был отключен.} one{<b>#</b> сервер был отключен.} few {<b>#</b> сервера были отключены.} many {<b>#</b> серверов было отключено.} other{<b>#</b> серверов было отключено.}}',
        notifyDisconnectFail: '{count, plural, =1{Сервер <b>{title}</b> не был отключен.} one{<b>#</b> сервер не был отключен.} few {<b>#</b> сервера не были отключены.} many {<b>#</b> серверов не было отключено.} other{<b>#</b> серверов не было отключено.}}',
    },
    ViewServer: {
        title: 'О сервере',
        inGeneral: 'Обзор',
        domains: 'Сайты',
        clients: 'Клиенты',
        installationLog: 'Журналы установок',
        address: 'Адрес',
        notes: 'Примечания',
        product: 'Продукт',
        version: 'Версия',
        unknownVersion: 'неизвестно',
        showErrorDetail: 'Показать все.',
        allowedInvalidCertificateWarning: 'Подключение к этому серверу может быть небезопасным из-за проблем с SSL/TLS-сертификатом.',
        allowedInvalidCertificateRisksNote: 'Вы решили проигнорировать результат проверки сертификата и принять все сопутствующие риски.',
        allowedInvalidCertificateLinkToDocs: 'Узнать больше о проблемах и рисках.',
        certificateErrorStatusMessage: 'Соединение с сервером прекращено из-за ошибки SSL/TLS-сертификата при подключении к серверу {serverName}',
        certificateErrorFixDescription: 'Пожалуйста, исправьте эту ошибку и повторно проверьте сертификат, чтобы восстановить подключение. Или проигнорируйте результат проверки сертификата и примите <a>все сопутствующие риски</a>.',
        relatedRisks: 'все сопутствующие риски',
        ignoreCertificateErrorsButton: 'Игнорировать проверку сертификата и восстановить',
        ignoreCertificateConfirmation: 'Подтвердите, что понимаете и принимаете все риски, связанные с ошибками сертификата.',
        confirmAndProceed: 'Подтвердить и продолжить',
        recheckCertificateButton: 'Повторно проверить сертификат',
        recheckResultSuccess: 'Подключение успешно восстановлено.',
        recheckResultCertificateError: 'Ошибка проверки сертификата: {errorMessage}',
        recheckResultConnectionError: 'Plesk 360 не может установить подключение к {serverName}.',
        recheckResultConnectionBlocked: 'Подключение к серверу {serverName} заблокировано',
        connectionErrorStatusMessage: 'Подключение к серверу {serverName} не может быть корректно установлено.',
        connectionErrorFixDescription: 'Пожалуйста, исправьте ошибку и нажмите кнопку ниже, чтобы восстановить подключение.',
        updatesButton: 'Обновления {product}',
        login: {
            plesk: 'Перейти в Plesk',
            cpanel: 'Перейти в cPanel',
            wp: 'Перейти на страницу WordPress',
        },
        AccessProblemStatusMessage: {
            causesTitle: 'Настройки сервера не позволяют установить подключение. Возможные причины:',
            caseRestrictionManagement: 'IP-адреса Platform 360 отфильтрованы для доступа администратора (<a>инструкции</a>)',
            casePanelIniApiSection: 'Удаленный доступ с помощью Plesk API отключен в panel.ini (<a>инструкции</a>)',
            caseImunify360: 'Access denied by Imunify360 Anti-bot protection.',
            listPublicIps: 'Убедитесь, что доступ со следующих IP-адресов разрешен: <b>{publicIps}</b>.',
            fixDescription: 'Чтобы удалось подключиться к серверу, измените соответствующие настройки конфигурации. После этого нажмите кнопку ниже для проверки подключения.',
            recheckConnectionButton: 'Повторно проверить подключение',
        },

        recheckConnectionButton: 'Повторно проверить подключение',
        sync: 'Синхронизировать',
        state: 'Статус сервера',
        Domains: {
            name: 'Имя',
            hostingType: 'Тип хостинга',
            virtualHostingType: 'Веб-сайт',
            forwardingHostingType: 'Перенаправление',
            noneHostingType: 'Нет хостинга',
            mainDomainType: 'Основной домен',
            subDomainType: 'Субдомен',
            addonDomainType: 'Дополнительный домен',
            parkedDomainType: 'Псевдоним',
            manage: 'Управлять',
            manageLinkUnavailableHint: 'Для создания прямой ссылки в панель Plesk требуется Plesk RESTful API версии 1.4.0 или выше. Более подробную информацию смотрите в документации.',
            open: 'Открыть',
        },
        Clients: {
            name: 'Контактное имя',
            email: 'Адрес электронной почты',
            login: 'Имя пользователя',
            type: 'Тип',
            status: 'Статус',
            typeAdmin: 'Администратор',
            typeReseller: 'Реселлер',
            typeCustomer: 'Клиент',
            typeCustomerWithOwner: 'Клиент владельца {owner}',
            statusActive: 'Активен',
            statusSuspended: 'Приостановлен',
            manage: 'Управлять',
        },
    },
    InPlaceText: {
        ok: 'Сохранить (Ctrl+Enter)',
        cancel: 'Отменить (Esc)',
        edit: 'Редактировать',
        add: 'Добавить',
    },
    ServerLogin: {
        title: 'Перенаправляем вас в панель управления по адресу {address}',
        progress: 'Это может занять несколько секунд',
        clientNote: 'Помните, что клиенты и реселлеры не видны в режиме Power User.',
        errorTitle: 'При попытке подключения к серверу произошла ошибка',
        errorDescription: 'Войдите прямо на сервер по его адресу с помощью корректных учетных данных или попробуйте еще раз авторизоваться на нем через Plesk 360 позже.',
        btnGroupLogin: 'Войти',
        tryAgain: 'Попробуйте еще раз',
        loginDirectly: 'Перейти в панель',
        btnGroupCancel: 'Отмена',
        cancel: 'Отмена',
    },
    Team: {
        Layout: {
            title: 'Организация',
            members: 'Участники',
            invitations: 'Приглашения',
        },
        settings: 'Настройки',
        Members: {
            email: 'Электронная почта',
            fullName: 'Полное имя',
            mfaStatus: 'Статус MFA',
            tags: 'Особые отметки',
            remove: 'Удалить',
            removeMemberConfirmation: '<b>{name}</b> больше не будет иметь доступ к организации и ее общим ресурсам, серверам, сайтам и лицензиям.',
            SpecialMarks: {
                you: 'Вы',
                owner: 'Владелец',
            },
            Onboarding: {
                title: 'Создайте организацию на нашей платформе, добавив своих коллег',
                description: 'Наша платформа предлагает инструменты для эффективного управления организацией и совместной работы. Участники вашей организации могут работать совместно с вами на подключенных серверах, получая доступ к ним прямо с платформы. Вам больше не нужно использовать небезопасные открытые пароли или сложные схемы доступа.',
                addMembersButton: 'Добавить участников организации',
            },
            MultifactorStatus: {
                enabled: 'MFA включена',
                disabled: 'MFA не включена',
            },
        },
        Invitations: {
            email: 'Электронная почта',
            expireAt: 'Истекает',
            expired: 'Просрочен',
            add: 'Добавить',
            revoke: 'Отменить',
            Onboarding: {
                title: 'Здесь вы можете пригласить участников своей организации',
                description: 'Добавьте своих коллег, чтобы поделиться с ними серверами и сайтами.',
                addButton: 'Пригласить нового участника',
            },
            ListActions: {
                resendInvitationTooltip: 'Пригласить повторно.',
                successMessage: 'Письмо с приглашением отправлено повторно.',
            },
            AddInvitations: {
                title: 'Добавить новых участников в организацию',
                infoMessage: 'Введите рабочие адреса электронной почты участников организации, чтобы пригласить их.',
                infoDescription: 'Предпочтительно использовать рабочие адреса вместо персональных, чтобы защитить личную информацию пользователей.',
                emailsLabel: 'Адреса электронной почты для приглашения',
                submitButton: 'Отправить приглашение',
                successMessage: '{count, plural, =1{Приглашение было отправлено на адрес {email}.} one{Было отправлено # приглашение.} few {Было отправлено # приглашения.} many {Было отправлено # приглашений.} other{Было отправлено # приглашений.}}',
            },
            RevokeInvitationsOperation: {
                revoke: 'Отменить',
                noSelectedInvitations: 'Выберите одно или несколько приглашений для отмены.',
                revokeInvitationConfirmation: '{count, plural, =1{Отозвать: <b>{email}</b>?} one{Отозвать <b>#</b> приглашение?} few {Отозвать <b>#</b> приглашения?} many {Отозвать <b>#</b> приглашений?} other{Отозвать <b>#</b> приглашений?}} Вы не сможете отменить это действие.',
                successRevokeInvitation: '{count, plural, =1{Приглашение на адрес <b>{email}</b> было отозвано.} one{<b>#</b> приглашение было отозвано.} few {<b>#</b> приглашения были отозваны.} many {<b>#</b> приглашений было отозвано.} other{<b>#</b> приглашений было отозвано.}}',
            },
        },
    },

    consents: {
        sendPlatform360Announce: {
            title: 'Получение обновлений и новостей проекта',
            text: 'WebPros International GmbH и другие компании группы WebPros могут хранить и обрабатывать данные, которые я предоставляю, для персонализации маркетингового контента, такого как информационные сообщения и индивидуальные предложения, в соответствии с документом <a>«Политика конфиденциальности»</a>. Индивидуальные предложения могут основываться на дополнительной информации, такой как данные об использовании и поведении (профилирование). Я могу отозвать это согласие в любое время в настройках проекта.',
        },
    },

    components: {
        List: {
            selectAll: 'Выбрать все',
            deselectAll: 'Отменить выделение',
            selectedRows: '{selectedRows, plural, one{выбрано элементов: # из {totalRows, plural, one{#} other{#}}} other{выбрано элементов: # из {totalRows, plural, one{#} other{#}}}}',
            totalRows: '{totalRows, plural, one{всего # элемент} few {всего # элемента} many {всего # элементов} other{всего # элементов}}',
            foundRows: '{foundRows, plural, one{найден # элемент} few {найдено # элемента} many {найдено # элементов} other{найдено # элементов}}',
        },
        DataList: {
            groupSearch: 'Поиск',
        },
        KbInstructionsFixLink: '<a>Прочитайте эту статью базы знаний</a>, чтобы узнать, как это исправить.',
    },
};